import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { theme } from '../../../constants';
import { useTranslation } from 'next-i18next';

interface CardProps {
  direction: string;
  className?: string;
  dark?: boolean;
  content: any;
  userImageSvg?: any;
  outgoingMarginBottom?: string;
}

export const ChatCard = ({
  direction,
  className,
  content,
  dark,
  userImageSvg,
  outgoingMarginBottom,
}: CardProps) => {
  const { t } = useTranslation();
  const incoming = () => (
    <>
      <Grid templateColumns="repeat(12, 1fr)" marginBottom="32px">
        <GridItem colSpan={3}>
          {userImageSvg}
        </GridItem>
        <GridItem colSpan={9}>
          <Box
            minHeight="76px"
            borderRadius="20px"
            borderTopLeftRadius="0px"
            width="100%"
            paddingY="1rem"
            paddingX="2rem"
            boxShadow="0px 3px 15px rgba(0, 0, 0, 0.25);"
            className={className}
            bg={theme.colors.background.white}
          >
            <Text fontSize="13px" fontWeight={700}>
              {t(content)}
            </Text>
          </Box>
        </GridItem>
      </Grid>
    </>
  );
  const outgoing = () => (
    <>
      <Grid
        templateColumns="repeat(12, 1fr)"
        marginBottom={outgoingMarginBottom ? outgoingMarginBottom : '42px'}
      >
        <GridItem colSpan={9}>
          <Box
            minHeight="76px"
            borderRadius="20px"
            borderTopRightRadius="0px"
            width="100%"
            paddingY="1rem"
            paddingX="2rem"
            boxShadow="0px 3px 15px rgba(0, 0, 0, 0.25);"
            className={className}
            bg={dark ? theme.colors.background.white : theme.colors.background.border}
            color={dark ? theme.colors.background.black : theme.colors.background.white}
          >
            <Text fontSize="13px" fontWeight={700}>
              {t(content)}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={3} className="ml-auto">
          {userImageSvg}
        </GridItem>
      </Grid>
    </>
  );

  return (
    <Box width="100%" maxWidth="390px" paddingX="1rem">
      {direction === 'incoming' ? incoming() : outgoing()}
    </Box>
  );
};
