import React from 'react';
import {
  Input as ChakraInput,
  InputGroup,
  Text,
  InputLeftAddon,
  InputLeftElement,
} from '@chakra-ui/react';
import RequiredIcon from '../../../assets/icons/required.svg';
import { theme } from '../../../constants';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

export interface TextInputProps {
  placeholder?: string;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  className?: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  leftText?: string;
  gradientLabel?: boolean;
  register?: () => UseFormRegisterReturn;
  textCenter?: boolean;
  isDark?: boolean;
  isHashtag?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  note?: string;
  errorMessage?: string;
  length?: number;
  maxLength?: number;
  fontSize?: number;
  isPhone?: boolean;
  inputMode?:
    | 'text'
    | 'search'
    | 'none'
    | 'email'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal'
    | undefined;
}

export const TextInput = ({
  className,
  placeholder,
  variant,
  size,
  type,
  label,
  leftText,
  gradientLabel,
  textCenter,
  register,
  isDark,
  isHashtag,
  isDisabled,
  isRequired,
  note,
  errorMessage,
  maxLength,
  fontSize,
  isPhone,
  inputMode,
}: TextInputProps) => {
  const { t } = useTranslation();
  const hasBorder = variant == 'flushed' || variant == 'outline';

  if (isHashtag) {
    return (
      <InputGroup alignContent="center" size="sm">
        <InputLeftElement color={theme.colors.background.gray} children="#" />
        <ChakraInput
          border={'transparent'}
          _focus={{}}
          color="background.dark"
          borderRadius="7px"
          {...(register ? register() : {})}
          fontSize={fontSize ?? 13}
          fontWeight={700}
          bgColor={theme.colors.background.white}
          maxLength={maxLength}
        />
      </InputGroup>
    );
  }

  return (
    <>
      {label && (
        <Text
          display={'flex'}
          alignItems="center"
          as={'label'}
          fontSize={fontSize ?? 13}
          bgGradient={gradientLabel ? theme.gradient.primary : theme.colors.background.dark}
          bgClip={gradientLabel ? 'text' : ''}
          fontWeight={700}
          gap={'8px'}
        >
          {label}
          {isRequired && (
            <Text
              paddingTop="1px"
              fontSize={12}
              fontWeight={700}
              bg={theme.gradient.primary}
              bgClip="text"
            >
              ＊
            </Text>
          )}
        </Text>
      )}
      <InputGroup
        borderBottom={!hasBorder ? `1px solid ${theme.colors.background.dark} !important` : ''}
        marginBottom="2px"
      >
        {!label && !textCenter && (
          <InputLeftAddon
            paddingLeft={0}
            border={'none'}
            background="transparent"
            position="relative"
            bottom="12px"
            children={
              <Text fontSize={13} fontWeight={700}>
                {leftText}
                {isRequired && (
                  <span className="ml-[8px]">
                    <RequiredIcon className="inline" />{' '}
                  </span>
                )}
              </Text>
            }
          />
        )}

        {isPhone && (
          <InputLeftAddon
            paddingLeft={0}
            border={'none'}
            background="transparent"
            children={
              <Text fontSize={13} fontWeight={700}>
                +81
              </Text>
            }
          />
        )}

        <ChakraInput
          _focus={{}}
          paddingStart={0}
          border={
            variant == 'outline' ? `1px solid ${theme.colors.background.dark} !important` : ''
          }
          borderBottom={
            variant == 'flushed' ? `1px solid ${theme.colors.background.dark} !important` : 'none'
          }
          borderBottomColor={
            textCenter && isDark ? `${theme.colors.background.white} !important` : ''
          }
          textAlign={textCenter ? 'center' : label ? undefined : 'right'}
          size={size}
          className={className}
          {...(register ? register() : {})}
          variant={variant}
          placeholder={placeholder}
          _placeholder={{ color: textCenter && isDark && theme.colors.background.white }}
          type={type}
          paddingRight={label ? 0 : 2}
          fontSize={fontSize ?? 13}
          fontWeight={700}
          isDisabled={isDisabled}
          maxLength={maxLength}
          {...(inputMode && { inputMode })}
        />
      </InputGroup>

      {note && (
        <Text fontWeight={500} lineHeight="15px" fontSize="10px" color="background.subtext">
          {t(note)}
        </Text>
      )}
      {errorMessage && (
        <Text fontWeight={500} lineHeight="15px" fontSize="10px" color="background.border">
          {t(errorMessage)}
        </Text>
      )}
    </>
  );
};
