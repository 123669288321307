import { useQuery } from 'react-query';

import { QueryConfig } from '@/lib/react-query';
import { supabase } from '@/lib/supabaseClient';

export const getSession = async () => {
  return supabase.auth.session();
};

type UseSessionOptions = {
  config?: QueryConfig<typeof getSession>;
};

export const useSession = (config: UseSessionOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['session'],
    queryFn: getSession,
  });
};
