import { extendTheme } from '@chakra-ui/react';
// @ts-ignore
import tailwindConfig from '@packages/ui/tailwind.config';
// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig';

resolveConfig(tailwindConfig);

export const theme: any = extendTheme({
  fonts: {
    heading: `'Noto Sans JP', sans-serif;`,
    body: `'Noto Sans JP', sans-serif;`,
  },
  colors: {
    brand: {
      100: tailwindConfig.theme.colors.brand[100],
      900: tailwindConfig.theme.colors.brand[900],
      300: tailwindConfig.theme.colors.brand[300],
    },
    background: {
      dark: tailwindConfig.theme.colors.dark,
      textGray: tailwindConfig.theme.colors.textGray,
      gray: tailwindConfig.theme.colors.gray,
      darkGray: tailwindConfig.theme.colors.darkGray,
      red: tailwindConfig.theme.colors.red,
      blue: tailwindConfig.theme.colors.blue,
      placeholder: tailwindConfig.theme.colors.placeholder,
      white: tailwindConfig.theme.colors.white,
      subtext: tailwindConfig.theme.colors.subtext,
      border: tailwindConfig.theme.colors.border,
      info: tailwindConfig.theme.colors.info,
      transparent: 'transparent',
      footer: tailwindConfig.theme.colors.footer,
      success: tailwindConfig.theme.colors.success,
      successBorder: tailwindConfig.theme.colors.successBorder,
      imageBackground: tailwindConfig.theme.colors.imageBackground,
      checked: tailwindConfig.theme.colors.checked,
      highlight: tailwindConfig.theme.colors.highlight,
    },
  },
  gradient: {
    primary: 'linear-gradient(90deg, #FF988B 0%, #FF6A88 48.96%, #FF97AA 100%)',
    background: 'linear-gradient(90deg, #FF988B 3.66%, #FF6A88 50.09%, #FF97AA 96.34%)',
    instagram: 'linear-gradient(90deg, #B0407D 0%, #D9604D 100%);',
  },
  components: {
    Modal: {
      baseStyle: {
        overlay: {
          background: 'rgba(0, 0, 0, 0.5)',
        },
        dialog: {
          bg: 'linear-gradient(90deg, #FF988B 0%, #FF6A88 48.96%, #FF97AA 100%)',
          borderRadius: '16px',
          width: '85%',
        },
        content: {
          background: tailwindConfig.theme.colors.white,
        },
        closeButton: {
          color: tailwindConfig.theme.colors.white,
          marginTop: '5px',
        },
      },
    },
  },
});
