import { User } from '@supabase/supabase-js';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useQuery } from 'react-query';
import { gqlSdk } from './lib/gqlClient';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { supabase } from './lib/supabaseClient';
import { registerUserTwitter } from './services';
import { getSignedURL } from './services/s3';
import { useRouter } from 'next/router';

enum Role {
  Answerer = 'answerer',
  Questioner = 'questioner',
}
interface IPaymentEnabledStatus {
  hasBankInfo?: boolean | null;
  hasCardInfo?: boolean | null;
  hasIdentityInfo?: boolean | null;
  isIdentitySubmitted?: boolean | null;
  isIdentityVerified?: boolean | null;
}

type ContextProps = {
  isLoading: boolean | null;
  setIsLoading: Dispatch<SetStateAction<boolean | null>>;
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  isAuthenticated: boolean | null;
  setIsAuthenticated: Dispatch<SetStateAction<boolean | null>>;
  isQuestioner: boolean | null;
  setIsQuestioner: Dispatch<SetStateAction<boolean | null>>;
  isAnswerer: boolean | null;
  setIsAnswerer: Dispatch<SetStateAction<boolean | null>>;
  profileImage: string | null;
  paymentEnabledStatus: IPaymentEnabledStatus | null;
  setPaymentEnabledStatus: Dispatch<SetStateAction<IPaymentEnabledStatus | null>>;
  hasNewNotifications: boolean;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isQuestioner, setIsQuestioner] = useState<boolean | null>(null);
  const [isAnswerer, setIsAnswerer] = useState<boolean | null>(null);
  const router = useRouter();

  const ref = useRef({
    isAuthenticated: false,
  });

  const [profileImage, setProfileImage] = useState<string | null>(null);

  const [paymentEnabledStatus, setPaymentEnabledStatus] = useState<IPaymentEnabledStatus | null>(
    null,
  );

  const { isLoading: paymentEnabledStatusLoading } = useQuery(
    'payment-pre-requisites',
    () => gqlSdk.PaymentEnabledStatus({ id: user?.id }),
    {
      enabled: !!isAuthenticated && !!user,
      refetchOnWindowFocus: false,
      onSuccess: async (data) => {
        if (data.users_by_pk) {
          const {
            hasBankInfo,
            hasCardInfo,
            hasIdentityInfo,
            isIdentitySubmitted,
            isIdentityVerified,
          } = data.users_by_pk;
          setPaymentEnabledStatus({
            hasBankInfo,
            hasCardInfo,
            hasIdentityInfo,
            isIdentitySubmitted,
            isIdentityVerified,
          });
        }
      },
    },
  );

  const profileImageCache = useMemo(() => new Map(), []);

  const getProfileImage = useCallback(async (image: string) => {
    const cachedURL = profileImageCache.get(image);
    if (cachedURL) {
      setProfileImage(cachedURL);
    } else {
      const url = await getSignedURL(image);
      profileImageCache.set(image, url);
      setProfileImage(url);
    }
  }, []);

  const authCookie = getCookie('auth-session');
  const socialProviders = ['twitter', 'instagram'];

  const twitterRegistration = useCallback(
    async (userInfo: User | null) => {
      const registrationRes = await registerUserTwitter({
        email: userInfo?.email as string,
        accountName: userInfo?.user_metadata?.full_name,
        provider: userInfo?.app_metadata?.provider as string,
        username: userInfo?.user_metadata?.user_name,
        twitterMetadata: userInfo?.user_metadata,
      });
      await supabase.auth.refreshSession();
      if (registrationRes?.user?.twitterMetadata)
        supabase.auth.update({
          data: {
            twitterMetadata: registrationRes?.user?.twitterMetadata,
          },
        });
      if (registrationRes?.user?.user_metadata)
        supabase.auth.update({
          data: {
            ...registrationRes?.user?.user_metadata,
          },
        });

      setUser(supabase.auth.user());
    },
    [setUser],
  );

  //GET USER INFTO FROM SUPABASE
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (user?.user_metadata?.profileImage !== undefined) {
        getProfileImage(user?.user_metadata?.profileImage);
      }

      if (
        user?.user_metadata.role === Role.Answerer ||
        session?.user?.user_metadata?.role === Role.Answerer
      ) {
        setIsAnswerer(true);
        setIsQuestioner(null);
      }
      if (
        user?.user_metadata.role === Role.Questioner ||
        session?.user?.user_metadata?.role === Role.Questioner
      ) {
        setIsQuestioner(true);
        setIsAnswerer(null);
      }

      if (event === 'SIGNED_IN' && session) {
        const user = session.user;
        setUser(user);
        setCookie('auth-session', user);
        if (router.pathname === '/login') {
          router.replace('/');
        }
        if (socialProviders.includes(session?.user?.app_metadata?.provider || '')) {
          ref.current.isAuthenticated = true;
        } else {
          ref.current.isAuthenticated = false;
        }
        setIsAuthenticated(true);
      }

      if (event === 'SIGNED_OUT') {
        setIsAuthenticated(false);
        setUser(null);
        deleteCookie('auth-session');
        deleteCookie('next-auth.session-token');
      }
    });

    return () => {
      authListener?.unsubscribe();
    };
  }, [getProfileImage]);

  const userInfo = supabase.auth.user();

  useEffect(() => {
    setIsLoading(true);
    if (userInfo?.user_metadata?.profileImage !== undefined)
      getProfileImage(userInfo?.user_metadata?.profileImage);

    if (userInfo?.user_metadata.role === Role.Answerer) {
      setIsAnswerer(true);
      setIsQuestioner(null);
    }

    if (userInfo?.user_metadata.role === Role.Questioner || !userInfo?.user_metadata.role) {
      setIsQuestioner(true);
      setIsAnswerer(null);
    }

    if (userInfo && authCookie) {
      setUser(userInfo);
      setIsAuthenticated(true);
    }

    if (ref.current.isAuthenticated) {
      if (!userInfo?.user_metadata?.role) {
        setIsQuestioner(true);
        setIsAnswerer(null);
      }
      setUser(userInfo);
      setIsAuthenticated(true);
      if (userInfo?.user_metadata.status === 'invited' || !userInfo?.user_metadata?.status) {
        try {
          twitterRegistration(userInfo);
        } catch (err) {}
      }
    }
    setIsLoading(false);
  }, [userInfo]);

  const notificationsData = useQuery(
    'new-notifications',
    () => gqlSdk.hasNewNotifications({ id: userInfo?.id }),
    { refetchOnWindowFocus: true, enabled: !!userInfo },
  );
  const hasNewNotifications = !!notificationsData?.data?.notifications?.length;

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        user,
        isAuthenticated,
        isQuestioner,
        isAnswerer,
        setUser,
        setIsLoading,
        setIsAuthenticated,
        setIsAnswerer,
        setIsQuestioner,
        profileImage,
        paymentEnabledStatus,
        setPaymentEnabledStatus,
        hasNewNotifications,
      }}
    >
      {props?.children}
    </AuthContext.Provider>
  );
};
