import { Flex, SimpleGrid, Avatar as ChakraAvatar } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Text } from '../Text';
import templateImg from '@packages/ui/assets/template.png';
import Image from 'next/image';
import { useReplyTemplate, TTemplate } from '@packages/utils';

export const ReplyTemplate = () => {
  const { t } = useTranslation();
  const { allTemplates, currentReplyTemplate, setCurrentTemplate } = useReplyTemplate();

  return (
    <Flex direction="column" margin="25px 0 29px 0" className="mt-[17px]">
      <Text color="white" size={13} mb="10px" text={t('Decline Templates')} />
      <SimpleGrid gap={17} minChildWidth="65px" width={'full'}>
        {allTemplates.map((key: TTemplate, index: number) => (
          <div
            key={key}
            className="text-center cursor-pointer "
            onClick={() => {
              setCurrentTemplate(key === currentReplyTemplate ? '' : key);
            }}
          >
            <Flex direction="column" className="relative">
              <ChakraAvatar height="62.5px" width="62.5px" className="mb-3">
                <Image
                  src={templateImg.src}
                  layout="fill"
                  objectFit="cover"
                  alt={key}
                  className={`bg-white ${key === currentReplyTemplate ? 'image-border' : ''}`}
                  loading="lazy"
                  style={{
                    borderRadius: '7px',
                  }}
                  unoptimized
                />
              </ChakraAvatar>
              <span className="text-[13px] absolute top-[5em] pl-1 leading-6 font-bold">
                {t(key)}
              </span>
            </Flex>
          </div>
        ))}
      </SimpleGrid>
    </Flex>
  );
};
