import { NextRouter } from 'next/router';

interface ITabsIndex {
  tabName: string;
  index: number;
}

interface INavigationTabs {
  router: NextRouter;
  pathname: string;
  tabsIndex?: ITabsIndex[];
}

/**
 * @description Gets The Index based onf the tabName thats assigned on tabsIndex
 */
const getTabIndex = (router: NextRouter, tabsIndex: ITabsIndex[]): number => {
  if (tabsIndex.length === 0) return 0;
  const check = tabsIndex.filter((tab) => router.query.tab && router.query.tab === tab.tabName);
  return check.length != 0 ? check[0].index : 0;
};

/**
 * @description Gets The TabName based onf the current tabindex thats assigned on tabsIndex
 */
const getTabQuery = (currentIndex: number, tabsIndex: ITabsIndex[]): string => {
  if (tabsIndex.length === 0) return '';
  const check = tabsIndex.filter((tab) => currentIndex === tab.index);
  return check.length != 0 ? check[0].tabName : '';
};

export const useNavigationTabs = ({
  router,
  pathname,
  tabsIndex = [
    {
      tabName: 'questionMarket',
      index: 1,
    },
  ],
}: INavigationTabs) => {
  const tabIndex = getTabIndex(router, tabsIndex);
  const onPathChange = (index: number) => {
    router.push(
      {
        pathname,
        query: { tab: getTabQuery(index, tabsIndex) },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };
  return {
    tabIndex,
    onPathChange,
  };
};
