import { Text as ChakraText, TypographyProps } from '@chakra-ui/react';
import { theme } from '../../../constants';

interface TextProps extends TypographyProps {
  text?: string;
  mt?: string;
  mb?: string;
  size?: string | number;
  className?: string;
  underline?: boolean;
  color?: string;
  weight?: number;
  center?: boolean;
  right?: boolean;
  height?: string;
  width?: string;
  pre?: boolean;
  align?: any;
  letterSpacing?: string;
  noOfLines?: number;
  onClick?: () => void;
}
export const Text = ({
  text,
  mt,
  size,
  className,
  underline,
  color,
  weight,
  center,
  right,
  height,
  pre,
  width,
  mb,
  align,
  letterSpacing,
  noOfLines,
  onClick,
  ...rest
}: TextProps) => {
  return (
    <ChakraText
      className={className}
      marginTop={mt}
      marginBottom={mb}
      color={color || theme.colors.background.dark}
      fontSize={size || 13}
      fontWeight={weight || 700}
      align={center ? 'center' : align ? align : 'left'}
      textDecoration={underline ? 'underline' : 'none'}
      lineHeight={height || '24px'}
      width={width || 'full'}
      letterSpacing={letterSpacing || '0.23px'}
      whiteSpace={pre ? 'pre' : undefined}
      noOfLines={noOfLines}
      onClick={onClick}
      {...rest}
    >
      {text}
    </ChakraText>
  );
};
