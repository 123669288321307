import * as React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import { theme } from '@packages/ui/constants';
import { forwardRef } from 'react';
export interface ButtonProps {
  onClick?: () => void;
  backgroundColor?: string;
  children?: string | React.ReactNode;
  rounded?: boolean;
  width?: string;
  height?: string;
  variant?:
  | 'primary'
  | 'instagram'
  | 'dark'
  | 'blue'
  | 'red'
  | 'gray'
  | 'white'
  | 'transparent'
  | 'background';
  disabled?: boolean;
  size?: 'large' | 'small';
  outlined?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  isLoading?: boolean;
  isDark?: boolean;
  fontSize?: string;
}

const gradientColors = ['primary', 'instagram', 'background'];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  width,
  onClick,
  backgroundColor,
  variant = 'dark',
  disabled,
  rounded,
  size = 'large',
  outlined,
  children,
  height = '50px',
  type,
  className,
  isLoading,
  isDark,
  fontSize = '16px',
}, ref) => {
  const background = {
    ...theme.gradient,
    ...theme.colors.background,
  };

  return (
    <ChakraButton
      type={type}
      className={`!h-0  ${outlined ? (variant === 'white' ? '!border-dark !border' : '!border !border-white') : ''
        } ${className}`}
      width={width}
      height={`${height} !important`}
      {...(gradientColors.includes(variant!)
        ? { bgGradient: background[variant!] }
        : { backgroundColor: background[variant!] })}
      _hover={{ opacity: 0.7 }}
      disabled={disabled || isLoading}
      background={backgroundColor || theme.colors.brand['900']}
      color={
        variant === 'white'
          ? isDark
            ? theme.colors.background.dark
            : theme.colors.brand[300]
          : '#fff'
      }
      onClick={onClick}
      borderRadius={`${rounded ? '29.5px' : '7px'}`}
      isLoading={isLoading}
      fontSize={fontSize}
      ref={ref}
    >
      {children}
    </ChakraButton>
  );
});
