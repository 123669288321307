import { Box, Flex, FormControl, Radio, RadioGroup } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useForm, Controller } from 'react-hook-form';
import { TextArea } from '../../atoms/TextArea';
import { theme } from '../../../constants';
import { Button, Text, IAlert, Alert } from '@packages/ui';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
export interface RatingCardProps {
  answerId?: string;
  rating?: any;
  rateAnswer?: any;
  isQuestionAuthor?: boolean;
  questionId?: string;
}

export const RatingCard = ({ answerId, rating, rateAnswer, isQuestionAuthor, questionId }: RatingCardProps) => {
  const { t } = useTranslation();

  const { register, handleSubmit, reset, control, watch } = useForm({
    defaultValues: {
      rating: 'good',
      appreciation: '',
    },
  });

  const [alert, setAlert] = useState<IAlert | null>(null);

  useEffect(() => {
    if (rating) {
      reset({
        rating: rating?.rating === true ? 'good' : 'bad',
        appreciation: rating?.message.trim(),
      });
    }
  }, [rating]);

  const { mutate, isLoading } = useMutation(rateAnswer, {
    onSuccess: () => {
      setAlert({ status: 'success', message: t('Your review has been submitted') });
    },
    onError: () => {
      setAlert({ status: 'error', message: t('Your review could not be submitted') });
    },
    onSettled: () => {
      // queryClient.invalidateQueries('createQuestion');
    },
  });

  const onSubmit = (data: any) => {
    mutate({ ...data, answerId, questionId });
  };

  if (rating) {
    return (
      <Box bg="white" padding="25px" borderRadius="12px">
        <Flex direction="column" width="full" justifyContent="center">
          <Text text={t('Rating')} center size={15} weight={700} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <Flex width="full" justifyContent="center" gap="50px" marginTop="35px">
                {rating?.rating ? (
                  <Flex direction="column">
                    <Text size={56} text="🤩" />
                    <Text center size="15px" className="mt-8" weight={700} text="GOOD" />
                  </Flex>
                ) : (
                  <Flex direction="column">
                    <Text size={56} text="🥺" />
                    <Text center size="15px" className="mt-8" weight={700} text="BAD" />
                  </Flex>
                )}
              </Flex>
              <Text
                className="mt-[25px]"
                center
                size="13px"
                weight={700}
                text={t('Rating Message')}
              />
              <TextArea
                disabled
                className="mt-[15px] mr-2.5 ml-2.5"
                isGray
                register={() => register('appreciation')}
                height="126px"
                rounded
                value={rating?.message}
              />
            </FormControl>
          </form>
        </Flex>
      </Box>
    );
  }

  return isQuestionAuthor ? (
    <>
      {alert && <Alert message={alert?.message} status={alert?.status} className="mb-[25px]" />}
      <Box bg="white" padding="25px" borderRadius="12px">
        <Flex direction="column" width="full" justifyContent="center">
          <Text center size={15} weight={700} text={t('Is the answer as expected?')} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <Controller
                name="rating"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    className="mt-[25px]"
                    defaultValue={'good'}
                    onChange={(val) => field.onChange(val)}
                    value={field.value}
                    size="lg"
                  >
                    <Flex width="full" justifyContent="center" gap="50px">
                      <RadioButton value="good" label="GOOD" />
                      <RadioButton value="bad" label="BAD" />
                    </Flex>
                  </RadioGroup>
                )}
              />
              <TextArea
                className="mt-14 mr-2.5 ml-2.5"
                length={watch('appreciation')?.length}
                maxLength={140}
                isGray
                label={t('Rating Message')}
                register={() => register('appreciation')}
                height="124px"
                isRequired
                variant="flushed"
              />
            </FormControl>
            <div className="px-2.5">
              <Button
                isLoading={isLoading}
                width="full"
                rounded
                type="submit"
                variant="primary"
                size="large"
                className="mt-7"
                disabled={!watch('appreciation') || isLoading}
              >
                {t('Submit the Review')}
              </Button>
            </div>
          </form>
        </Flex>
      </Box>
    </>
  ) : null;
};

const RadioButton = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="relative">
      <Radio
        _checked={{
          background: `${theme.gradient.primary} !important`,
          border: `4px solid ${theme.colors.background.gray} !important`,
        }}
        _focus={{}}
        css={{
          '&.chakra-radio__control ': {
            position: 'absolute',
            bottom: '-25px',
            right: '29px',
          },
        }}
        value={value}
        _active={{}}
      >
        {value === 'good' ? <Text size={56} text="🤩" /> : <Text size={56} text="🥺" />}
        <Text size="15px" className="mt-8" weight={700} center text={label} />
      </Radio>
    </div>
  );
};
