import React from 'react';
import { Text, TextHeader } from '@packages/ui/components';
import Social from '@packages/ui/assets/icons/social.svg';
import { Flex } from '@chakra-ui/react';

interface IErrorCodeProps {
  color?: string;
  className?: string;
  errorMessage: string;
  errorCode: number;
}

export const ErrorCode = ({ color, className, errorMessage, errorCode }: IErrorCodeProps) => {
  return (
    <Flex
      height="100%"
      className={className}
      direction="column"
      alignItems="center"
      justifyContent="center"
      translateY="-50%"
    >
      <Social />
      <TextHeader text={`${errorCode}`} center />
      <Text center color={color} text={errorMessage} />
    </Flex>
  );
};
