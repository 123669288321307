import { useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Button } from '../Button';
import { useTranslation } from 'next-i18next';
import { theme } from '../../../constants';
import { Text } from '../Text';
import { Box } from '@chakra-ui/react';
import { Alert, IAlert } from '../Alert';
import imageCompression from 'browser-image-compression';

export interface IdentityUploadProps {
  handleImageChange: (image: ImageListType) => void;
  buttonDisabled?: boolean;
  setHasError: (error: boolean) => void;
}

const options = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

const IdentityUpload = ({
  handleImageChange,
  buttonDisabled,
  setHasError,
}: IdentityUploadProps) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ImageListType>([]);
  const [alert, setAlert] = useState<IAlert | null>(null);
  const [error, setError] = useState('');

  const onChange = async (imageList: ImageListType) => {
    if (imageList?.[0]?.file) {
      try {
        const compressedFile = await imageCompression(imageList?.[0].file, options);
        imageList[0].file = compressedFile;
        imageList[0].data_url = await imageCompression.getDataUrlFromFile(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }

    setImages(imageList as never[]);
    handleImageChange(imageList);
  };

  useEffect(() => {
    if (error?.length) {
      setAlert({ status: 'error', message: error });
      setHasError(true);
    } else {
      setAlert(null);
      setHasError(false);
    }
  }, [error]);

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      acceptType={['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG']}
      dataURLKey="identity_document"
    >
      {({ imageList, onImageUpload, onImageUpdate, errors }) => (
        <>
          <>
            {errors?.acceptType
              ? setError(t('Image must be of type jpeg, jpg or png'))
              : errors?.maxFileSize
              ? setError(t('Image size must be less than 2MB'))
              : setError('')}
          </>

          {alert && <Alert status={alert.status} message={alert.message} />}

          <Button
            rounded
            className="mt-[17px]"
            height="35px"
            width="full"
            size="small"
            onClick={images?.length ? () => onImageUpdate(0) : onImageUpload}
            disabled={buttonDisabled}
          >
            {t('Select Image')}
          </Button>

          {imageList.length > 0 ? (
            <img
              src={imageList[0]['identity_document']}
              alt="Identity Document"
              className="mt-[17px] w-full"
            />
          ) : (
            <Box
              borderRadius="12px"
              height="204px"
              marginTop="17px"
              bg={theme.colors.background.imageBackground}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                text={t('Selected image will be displayed')}
                center
                color={theme.colors.background.subtext}
                size={12}
              />
            </Box>
          )}
        </>
      )}
    </ImageUploading>
  );
};

export default IdentityUpload;
