import { Box, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Text, Avatar } from '../../atoms';
import { useTranslation } from 'next-i18next';
import { format } from 'date-fns';
import DefaultImage from '@packages/ui/assets/default-user.png';

export interface QuestionItemProps {
  question: {
    __typename?: 'questions';
    id: any;
    createdAt: any;
    status: string;
    updatedAt: any;
    content: string;
    isPublic: boolean;
    user: {
      __typename?: 'users';
      image?: string | null;
      id: any;
      email: string;
      createdAt: any;
      bio?: string | null;
      accountName: string;
      username?: string | null;
      twitterMetadata?: any;
    };
  };
}

export const QuestionItem = ({ question }: QuestionItemProps) => {
  const { createdAt, user, id, content, isPublic } = question;
  const { image, accountName, username, twitterMetadata } = user;

  const router = useRouter();

  const { t } = useTranslation();

  return (
    <Box
      borderBottom="1px solid"
      borderBottomColor="background.gray"
      marginX="-28px"
      paddingX="28px"
      onClick={() => router.push(`question-detail/${id}`)}
      cursor="pointer"
      paddingY="17px"
    >
      <Flex direction="row" gap="17px" marginBottom="3px">
        <Avatar
          height="52px"
          width="52px"
          src={
            image || 
            twitterMetadata?.picture || 
            DefaultImage?.src}
          alt={username as string}
        />
        <Flex className="w-full" direction="column">
          <Text overflowWrap="anywhere" color="background.dark" size="15px" text={accountName} />
          {username ? (
            <Text
              overflowWrap="anywhere"
              color="background.dark"
              size="10px"
              text={`@${username}`}
            />
          ) : null}
        </Flex>
      </Flex>
      <Flex direction="column">
        <Text noOfLines={3} color="background.dark" text={content} weight={500} />
        <Flex justifyContent="space-between" paddingTop="3px">
          <Text
            color="background.subtext"
            size="10px"
            text={`${format(new Date(createdAt), 'yyyy/MM/dd HH:mm')}`}
          />
          <Text
            className="!text-right"
            color="background.subtext"
            size="10px"
            text={`${t('Viewing Revenue')}: ${isPublic ? 'ON' : 'OFF'}`}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
