import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import { Hydrate, QueryClientProvider } from 'react-query';
import { SessionProvider } from 'next-auth/react';

import { queryClient } from '@/lib/react-query';
import { theme } from '@packages/ui';
import '@packages/ui/css/tw.css';
import '@fontsource/noto-sans-jp/700.css';
import '@fontsource/noto-sans-jp/500.css';

import { appWithTranslation } from 'next-i18next';
import { AuthProvider } from '@/AuthContext';
import Head from 'next/head';
import PrivateRoute from '@/withPrivateRoute';
import { ReplyTemplateProvider } from '@/../../packages/utils';
import { ReactQueryDevtools } from 'react-query/devtools';

const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <AuthProvider>
              <PrivateRoute>
                <SessionProvider session={session}>
                  <ReplyTemplateProvider>
                    <Head>
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
                      />
                    </Head>
                    <Component {...pageProps} />
                  </ReplyTemplateProvider>
                </SessionProvider>
              </PrivateRoute>
            </AuthProvider>
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
};

export default appWithTranslation(App);
