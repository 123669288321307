import { useContext, createContext, useState, Dispatch, SetStateAction } from 'react';

export type TTemplate = 'Paid' | 'Change' | 'NG' | 'Answered' | '';

interface IReplyTemplate {
  allTemplates: TTemplate[];
  currentReplyTemplate: TTemplate;
  setCurrentTemplate: (template: TTemplate) => void;
  selectTemplate: (key: TTemplate) => string;
}

const templates = {
  Paid:
    'Thank you for your question.\nPlease ask your question again for a fee (other than 0 yen) so that I can answer it in detail.',
  Change:
    'I would like to answer your question in detail.\nPlease ask your question again with a little more detail in the XXXX section.',
  NG: 'Thank you for your question.\nI am unable to answer your question due to XXXX.',
  Answered:
    'Thank you for your question.\nI have answered this question in the past. Please see this page.\n{Answerer_Answered_Question_List_Page_URL}',
};

type TReplyTemplateProps = {
  currentSelected: TTemplate | '';
  setCurrentSelected: Dispatch<SetStateAction<TTemplate | ''>>;
};

const ReplyTemplateContext = createContext<Partial<TReplyTemplateProps>>({});

const ReplyTemplateProvider = (props: any) => {
  const [currentSelected, setCurrentSelected] = useState<TTemplate | ''>('');
  return (
    <ReplyTemplateContext.Provider
      value={{
        currentSelected,
        setCurrentSelected,
      }}
    >
      {props?.children}
    </ReplyTemplateContext.Provider>
  );
};

const allTemplates = [...Object.keys(templates)] as TTemplate[];
const selectTemplate = (key: TTemplate | ''): string => (key != '' ? templates[key] : '');

const useReplyTemplate = (): IReplyTemplate => {
  const ctx = useContext(ReplyTemplateContext);
  if (!ctx) throw new Error('Problem Initializing the ReplyTemplateContext');
  const { currentSelected, setCurrentSelected } = ctx;

  const setCurrentTemplate = (template: TTemplate) => {
    if (setCurrentSelected) setCurrentSelected(template);
    else console.error('Problem Defining Template Setter');
  };

  return {
    allTemplates,
    currentReplyTemplate: currentSelected ? currentSelected : '',
    setCurrentTemplate,
    selectTemplate,
  };
};

export { ReplyTemplateProvider, useReplyTemplate };
