import { Avatar, Box, Flex } from '@chakra-ui/react';
import RightAngle from '@packages/ui/assets/icons/right-angle.svg';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'next-i18next';
import { Text } from '../../atoms';
import { theme } from '../../../constants';
import DefaultImage from '@packages/ui/assets/default-user.png';
import Image from 'next/image';
import Link from "next/link";
export interface NotificationItemProps {
  notification: {
    __typename?: 'notifications';
    id: any;
    createdAt: any;
    message: string;
    status: boolean;
    updatedAt: any;
    questionID?: any | null;
    user: {
      __typename?: 'users';
      accountName: string;
      image?: string | null;
      email: string;
      bio?: string | null;
      id: any;
      status?: string | null;
      role: string;
      username?: string | null;
      twitterMetadata?: any;
    };
  };
  href: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const NotificationItem = ({ notification, onClick, href }: NotificationItemProps) => {
  const { message, status, user } = notification;

  const { t } = useTranslation();

  return (
    <Box
      borderBottom="1px solid"
      borderBottomColor="background.gray"
      marginX="-25px"
      onClick={onClick}
      _hover={{ backgroundColor: theme.colors.background.highlight }}
    >
      <Link href={href}>
        <Flex direction="row" paddingY="22px" marginX="25px" gap="17px">
          <Avatar
            height="55px"
            width="55px"
            rounded="full"
          >
            <Image
              src={
                user?.image || 
                user?.twitterMetadata?.picture || 
                DefaultImage?.src}
              layout="fill"
              objectFit="cover"
              loading="lazy"
              alt={user?.accountName}
              style={{background:"white"}}
              className="cursor-pointer rounded-full"
              unoptimized
            />
          </Avatar>
          <Flex justifyContent="space-between" alignItems="center" className="w-full">
            <Text color="background.dark" text={t(message)} weight={!status ? 700 : 500} />
            <Flex marginRight="20px" gap={5}>
              {!status ? (
                <Box
                  height="10px"
                  width="10px"
                  bgGradient={theme.gradient.primary}
                  borderRadius="50%"
                />
              ) : null}
              <RightAngle />
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};
