import OfficialUserImage from '@/public/assets/icons/official-user.svg';
import { Avatar as ChakraAvatar, Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { ImageType } from 'react-images-uploading';
import { theme } from '../../../constants';
import { PreviewImage, Avatar } from '../../atoms';
import DefaultUserImage from '@packages/ui/assets/default-user.png';
import Image from 'next/image';

interface User {
  id: string;
  profileImage?: string;
  accountName: string;
  username?: string;
}

interface Query {
  id?: string;
  question: string;
  amount: number;
  questioner: User;
  answerer?: User;
  hashtags: string[];
  images: ImageType[];
}

export interface QueryPreviewCardProps {
  query: Query;
  hasShadow?: boolean;
  isOfficial?: boolean;
  isRequested?: boolean;
}

export const QueryPreviewCard = ({
  query,
  hasShadow,
  isRequested,
  isOfficial,
}: QueryPreviewCardProps) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(false);
  const { amount, questioner, question, hashtags, images } = query;
  const [showSeeMoreButton, setShowSeeMoreButton] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openPreviewImage = (index: number) => {
    setPreviewImage(true);
    setImageIndex(index);
  };

  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      if (node.clientHeight > 72) {
        setShowSeeMoreButton(true);
      } else {
        setShowSeeMoreButton(false);
      }
    }
  }, []);

  return (
    <Box
      bgColor={theme.colors.white}
      color={theme.colors.background.dark}
      borderRadius="12px"
      padding="25px"
      marginTop="30px"
      boxShadow={hasShadow ? `0px 3px 15px rgba(0, 0, 0, 0.25)` : ''}
    >
      <Flex direction="column">
        {!isOfficial && amount > 0 && (
          <Flex justifyContent="flex-end">
            <div className="mb-3 w-fit">
              <Text
                borderBottom={`3px solid`}
                fontSize={13}
                fontWeight={700}
                className="border-gradient-primary mb-1 text-right"
              >
                {t(isRequested ? 'request.question.amount' : 'asked.for', {
                  amount: new Intl.NumberFormat('ja-JP').format(amount).toLocaleString(),
                })}
              </Text>
            </div>
          </Flex>
        )}
        <Flex gap={18} alignItems="center">
          {isOfficial ? (
            <Box height="55px" width="55px">
              <OfficialUserImage />
            </Box>
          ) : (
            <Avatar
              height="55px"
              width="55px"
              src={questioner.profileImage || DefaultUserImage?.src} alt={questioner?.accountName}
            />
          )}
          <Flex direction="column">
            <Text overflowWrap="anywhere" fontSize={15} fontWeight={700}>
              {questioner.accountName}
            </Text>
            <Flex flexWrap={'wrap'}>
              {hashtags?.map((hashtag: string, idx: number) => {
                if (hashtag) {
                  return (
                    <Text
                      key={idx + 1}
                      maxWidth="100%"
                      wordBreak="break-all"
                      overflowWrap="anywhere"
                      fontWeight={700}
                      fontSize={13}
                      bgGradient={theme.gradient.primary}
                      bgClip="text"
                      className="mr-2"
                    >
                      #{hashtag}
                    </Text>
                  );
                }
              })}
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" className="mt-2">
          <Text
            fontSize={13}
            fontWeight={500}
            whiteSpace="pre-line"
            ref={measuredRef}
            {...(showSeeMoreButton && {
              noOfLines: show ? undefined : 3,
            })}
          >
            {question}
          </Text>
        </Flex>
      </Flex>
      {showSeeMoreButton && (
        <p
          color="background.darkGray"
          className="text-subtext cursor-pointer pb-[15px] !text-right text-[13px] !font-[500]"
          onClick={(e) => {
            e.stopPropagation();
            setShow(!show);
          }}
        >
          {!show ? t('See more') : t('Show less')}
        </p>
      )}
      <Flex gap="9.45px" width="fit-content" margin="0 auto" marginTop="24px">
        {images
          ?.filter((image) => !!image && (image?.[0] || image?.[0]?.data_url || image?.[0]?.file))
          ?.map((image, idx) => (
            <ChakraAvatar
              onClick={() => openPreviewImage(idx)}
              key={idx}
              height="62.5px"
              width="62.5px"
            >
              <Image
                src={typeof image === 'object' ? image?.[0]?.data_url ?? DefaultUserImage?.src : image}
                alt={questioner?.accountName}
                layout="fill"
                objectFit="cover"
                loading="lazy"
                style={{ borderRadius: '7px', background:"white"}}
                unoptimized
              />
            </ChakraAvatar>
          ))}
      </Flex>
      {images?.length > 0 && (
        <PreviewImage
          currIndex={imageIndex}
          open={previewImage}
          onClose={() => setPreviewImage(false)}
          images={images
            ?.filter((img) => img && (img?.[0] || img?.[0]?.file))
            .map((image) =>
              typeof image === 'object' ? image?.[0]?.data_url ?? DefaultUserImage?.src : image,
            )}
        />
      )}
    </Box>
  );
};
