import { Box, Center, Text as ChakraText, useMediaQuery } from '@chakra-ui/react';
import Image from 'next/image';
import { theme } from '../../../constants/theme';
import { useTranslation } from 'next-i18next';
import MobileImage from '@packages/ui/assets/icons/mobile.png';

const HeroSection = () => {
  const { t } = useTranslation();
  const [isSmallerThan385] = useMediaQuery('(max-width: 385px)');
  return (
    <>
      <Center className="flex flex-col" paddingTop="20px">
        <Box
          height="204.75px"
          position="relative"
          width="100%"
          backgroundColor={theme.colors.background.footer}
          overflowY="hidden"
        >
          <Box position="absolute" display="block" top="0" left="-5px">
            <img height="37.85px" width="40.9px" alt="dot 1" src="/assets/icons/dot-1.svg" />
          </Box>
          <Box position="absolute" display="block" bottom="-25px" left="0">
            <img height="108.86px" width="117.62px" alt="dot 2" src="/assets/icons/dot-2.svg" />
          </Box>
          <Box
            position="absolute"
            display="block"
            top="60%"
            right={isSmallerThan385 ? '85px' : '160.22px'}
          >
            <img
              height="59.15px"
              alt="dot 3"
              width="62.08px"
              src="/assets/icons/dot-3.svg"
              style={{
                zIndex: '1',
              }}
            />
          </Box>
          <Box position="absolute" display="block" bottom="-5px" right="30.06px" zIndex={5}>
            <Image
              height={isSmallerThan385 ? '120px' : '180px'}
              width={isSmallerThan385 ? '100px' : '159px'}
              alt="Mobile"
              src={MobileImage}
              style={{
                zIndex: '5',
              }}
              priority
              unoptimized
            />
          </Box>
          <Box position="absolute" display="block" top="-0.6px" right="0">
            <img
              height="84.21px"
              width="97.25px"
              src="/assets/icons/dot-4.svg"
              alt="dot 4"
              style={{
                zIndex: '1',
              }}
            />
          </Box>
          <Box position="absolute" display="block" bottom="-10px" right="16.41px">
            <img
              height="42.96px"
              width="45.08px"
              src="/assets/icons/dot-5.svg"
              alt="dot 5"
              style={{
                zIndex: '1',
              }}
            />
          </Box>
          <Box position="absolute" display="block" top="30.7px" left="34px" zIndex={1}>
            <img
              id="top-main-dot"
              height="128.38px"
              width="134.72px"
              alt="main dot"
              src="/assets/icons/dot-main.svg"
              style={{
                zIndex: '1',
              }}
            />
          </Box>
          <Box
            position="absolute"
            display="block"
            top="30.7px"
            left="34px"
            overflow="hidden"
            zIndex={1}
          >
            <img height="140.07px" width="140.07px" alt="dotted" src="/assets/icons/dotted.svg" />
          </Box>
          <Box
            position="absolute"
            display="block"
            top="50px"
            left="40.13px"
            zIndex={10}
            maxWidth="127px"
          >
            <ChakraText
              width="127px"
              height="56px"
              textAlign="center"
              whiteSpace="pre"
              fontSize="17.58px"
              fontWeight={700}
              letterSpacing="0.52px"
              lineHeight={'27.92px'}
              textShadow={`-0.52px 1px 0px white`}
              style={{
                WebkitTextStroke: `0.52px ${theme.colors.background.border}`,
                WebkitTextFillColor: `transparent`,
              }}
            >
              {t(`憧れのあの人に\n直接質問できる`)}
            </ChakraText>
            <img
              height="38px"
              width="125px"
              alt="top-logo"
              src="/assets/icons/top-logo.svg"
              style={{
                zIndex: '5',
              }}
            />
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default HeroSection;
