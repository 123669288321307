import { Divider, Flex, Text as ChakraText, useMediaQuery } from '@chakra-ui/react';
import ShareIcon from '@packages/ui/assets/icons/share.svg';
import { theme } from '@packages/ui/constants/theme';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Text, Avatar } from '../../atoms';
import DefaultImage from '@packages/ui/assets/default-user.png';
export interface IUserProfileStatProps {
  count: number;
  label: string;
}

export interface IProfileCardProps {
  id?: string;
  name: string;
  username: string;
  profileImage: string;
  count_details: {
    answers: number;
    good: number;
  };
  bio?: string;
}
const UserProfileStats = ({ count, label }: IUserProfileStatProps) => (
  <div>
    <Text center size="22px" text={count.toString()} />
    <Text size="10px" center text={label} weight={500} />
  </div>
);

export const ProfileCard = ({
  id,
  name,
  username,
  count_details,
  bio,
  profileImage,
}: IProfileCardProps) => {
  const [showMore, setShowMore] = useState(false);
  const [showSeeMoreButton, setShowSeeMoreButton] = useState(false);
  const { t } = useTranslation();
  const [isLessThan350] = useMediaQuery('(max-width: 350px)');

  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const handleShareButtonClick = async () => {
    const shareData = {
      title: 'ASQ',
      url: `/influencers/${username}`,
    };

    await navigator.share(shareData);
  };

  useEffect(() => {}, []);

  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      if (node.clientHeight > 72) {
        setShowSeeMoreButton(true);
      } else {
        setShowSeeMoreButton(false);
      }
    }
  }, []);

  return (
    <section id="profile">
      <Flex gap={isLessThan350 ? '12px' : '23px'} alignItems="center">
        <Avatar
          height="80px"
          width="80px"
          alt={name || username}
          src={profileImage || DefaultImage?.src}
        />

        <Flex
          className="w-full"
          justifyContent="space-between"
          alignItems="center"
          {...(isLessThan350 && {
            gap: '12px',
          })}
        >
          <div>
            <Text overflowWrap="anywhere" size="17px" text={name} />
            {username && <Text overflowWrap="anywhere" weight={500} text={`@${username}`} />}
          </div>
          <Flex direction="column" className="mr-[3px]" alignItems="center" cursor="pointer">
            <ShareIcon onClick={handleShareButtonClick} />
            <ChakraText fontWeight={500} fontSize="10px" className="pt-1" wordBreak="keep-all">
              {t('Share')}
            </ChakraText>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="center" margin="15px auto">
        <UserProfileStats key="answer" label={t('Answers')} count={count_details?.answers || 0} />
        <Divider
          orientation="vertical"
          borderColor={theme.colors.background.dark}
          opacity={1}
          height="19px"
          alignSelf="center"
          margin="0 28px"
        />
        <UserProfileStats key="good" label={t('GOOD')} count={count_details?.good || 0} />
      </Flex>
      {bio ? (
        <div>
          <ChakraText
            ref={measuredRef}
            marginBottom="15px"
            fontWeight={500}
            fontSize={13}
            {...(showSeeMoreButton && {
              noOfLines: showMore ? undefined : 3,
            })}
            className="whitespace-pre-wrap"
          >
            {bio}
          </ChakraText>
          {showSeeMoreButton && (
            <p
              color="background.darkGray"
              className="text-subtext cursor-pointer pb-[15px] !text-right text-[13px] !font-[500]"
              onClick={handleShowMore}
            >
              {showMore ? t('Show less') : t('See more')}
            </p>
          )}
        </div>
      ) : null}
    </section>
  );
};
