import { Box, Center, Flex, Link } from '@chakra-ui/react';
import { theme } from '../../../constants';
import { Text } from '../../atoms';
import { useTranslation } from 'next-i18next';

export const WalletCard = ({ balance }: { balance: Number }) => {
  const { t } = useTranslation();

  return (
    <Box bgGradient={theme.gradient.background} borderRadius="7px" padding="16px">
      <Flex alignItems="center" justifyContent="center" gap="19px">
        <Text align="right" text={t('Wallet Balance')} color="white" className="max-w-max" />
        <Text
          size={17}
          align="left"
          text={`¥${balance.toLocaleString()}`}
          color="white"
          className="max-w-max"
        />
      </Flex>
      <Center marginTop="10px">
        <Link
          href="/withdrawal-request"
          borderRadius="29.5px"
          border="1px solid #FFEEF3"
          fontSize={14}
          padding="6px 17px"
          fontWeight={700}
          _hover={{ textDecoration: 'none' }}
          color="white"
        >
          {t('Apply for Withdrawal')}
        </Link>
      </Center>
    </Box>
  );
};

export default WalletCard;
