import { Avatar, Box, Flex, useDisclosure } from '@chakra-ui/react';
import DarkThemeLogo from '@packages/ui/assets/dark-theme-logo.svg';
import DarkThemeMenu from '@packages/ui/assets/icons/dark-theme-menu.svg';
import Notification from '@packages/ui/assets/icons/dark-theme-notification.svg';
import Menu from '@packages/ui/assets/icons/menu.svg';
import DarkThemeNotification from '@packages/ui/assets/icons/notification.svg';
import Logo from '@packages/ui/assets/logo.svg';
import { useTranslation } from 'next-i18next';
import { theme } from '../../../constants';
import { ButtonLink } from '../../atoms';
import { HamburgerMenu } from '../../organisms';
import { useQuery } from 'react-query';
import DefaultImage from '@packages/ui/assets/default-user.png';
import Link from 'next/link';
import Image from 'next/image';
export interface IHeaderProps {
  isDarkTheme?: boolean;
  handleNotification?: () => void;
  newNotification?: boolean;
  isAuthenticated?: boolean | null;
  isQuestioner?: boolean | null;
  signOut?: () => void;
  retrieveBalance?: () => void;
  isEmailLogin: boolean;
  profileImage?: string;
  isInfluencerPage?: boolean;
}

export const Header = ({
  isDarkTheme,
  handleNotification,
  newNotification,
  isAuthenticated,
  isQuestioner,
  signOut,
  retrieveBalance,
  isEmailLogin,
  profileImage,
  isInfluencerPage,
}: IHeaderProps) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMenuClick = () => {
    onOpen();
  };

  // retrieve user balance
  const { data: balance } = useQuery(
    ['retrieve-balance'],
    () => retrieveBalance && retrieveBalance(),
    {
      enabled: !!isAuthenticated && !!retrieveBalance,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Box>
      <Flex
        paddingX="25px"
        width="full"
        position="fixed"
        top="0"
        left="0"
        zIndex={100}
        background={isDarkTheme ? 'background.dark' : 'background.white'}
        justifyContent="space-between"
        alignItems="center"
        height="68px"
      >
        <Link href={'/'}>
          <a aria-label={'Question Market 憧れのあの人に直接質問できる'}>
            {isDarkTheme ? <DarkThemeLogo /> : <Logo />}
          </a>
        </Link>
        {!isAuthenticated ? (
          <ButtonLink
            className="flex-end"
            variant="primary"
            rounded
            height="34px"
            width="136px"
            href={isInfluencerPage ? 'https://line.me/R/ti/p/@946wxpjp' : '/register'}
            isExternal={isInfluencerPage}
          >
            {t('Sign Up')}
          </ButtonLink>
        ) : (
          <>
            <Flex
              marginLeft={!isAuthenticated ? '' : 'auto'}
              gap="38px"
              alignItems="center"
              background={isDarkTheme ? 'background.dark' : 'background.white'}
            >
              {isAuthenticated ? (
                <>
                  <div className="mr-[27px] cursor-pointer">
                    {isDarkTheme ? (
                      <Box position="relative" className="align-center flex">
                        <Link href="/notifications">
                          <a aria-label={'Question Market 憧れのあの人に直接質問できる'}>
                            <DarkThemeNotification onClick={handleNotification} />
                            {newNotification && (
                              <Box
                                height="10px"
                                width="10px"
                                bgGradient={theme.gradient.primary}
                                borderRadius="50%"
                                position="absolute"
                                right="10%"
                                top="18%"
                              />
                            )}
                          </a>
                        </Link>
                      </Box>
                    ) : (
                      <Box position="relative" className="align-center flex">
                        <Link href="/notifications">
                          <a aria-label={'Question Market 憧れのあの人に直接質問できる'}>
                            <Notification />
                            {newNotification && (
                              <Box
                                height="10px"
                                width="10px"
                                bgGradient={theme.gradient.primary}
                                borderRadius="50%"
                                position="absolute"
                                right="10%"
                                top="18%"
                              />
                            )}
                          </a>
                        </Link>
                      </Box>
                    )}
                  </div>
                </>
              ) : null}
            </Flex>
            <Link href={'/profile-settings'}>
              <Avatar height="30px" width="30px" rounded="full" className="mr-[27px]">
                <Image
                  src={profileImage || DefaultImage?.src}
                  layout="fill"
                  objectFit="cover"
                  loading="lazy"
                  style={{background: 'white'}}
                  alt={'Question Market User'}
                  className="cursor-pointer rounded-full"
                  unoptimized
                />
              </Avatar>
            </Link>
          </>
        )}
        <div className="cursor-pointer">
          {isDarkTheme ? (
            <div onClick={handleMenuClick}>
              <DarkThemeMenu onClick={handleMenuClick} />
            </div>
          ) : (
            <div onClick={handleMenuClick}>
              <Menu />
            </div>
          )}
        </div>
        <HamburgerMenu
          isOpen={isOpen}
          onClose={onClose}
          amount={balance?.['total'] ?? 0}
          isAuthenticated={isAuthenticated}
          isQuestioner={isQuestioner}
          signOut={signOut}
          isEmailLogin={isEmailLogin}
        />
      </Flex>
    </Box>
  );
};
