import { Alert as ChakraAlert, Box, Flex } from '@chakra-ui/react';
import { theme } from '../../../constants';
import AlertIcon from '@packages/ui/assets/icons/alert.svg';
import { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';

interface IVAlert {
  message?: string;
}

export const IdentityAlert = forwardRef<HTMLDivElement, IVAlert>(({ message }, ref) => {
  const { t } = useTranslation();
  return (
    <ChakraAlert
      width="full"
      ref={ref}
      marginTop="35px"
      padding={'7px 10px'}
      minHeight="29px"
      status="error"
      borderRadius="3px"
      background={theme.colors.background.info}
      border="1px"
      borderColor={theme.colors.background.border}
    >
      <Flex gap="10px">
        <div className="whitespace-nowrap">
          <AlertIcon />
        </div>
        <Box
          fontSize="10px"
          fontWeight={500}
          lineHeight="15px"
          letterSpacing="0.23px"
          color={theme.colors.background.white}
        >
          <p>
            {t(
              'Identification could not be confirmed due to incomplete identification documents or registration information.',
            )}
          </p>
          <br />
          <p>{t('Reason:')}</p>
          <p>{message}</p>
          <br />
          <p>{t('Please check your registered information and submit your documents again.')}</p>
        </Box>

        <br />
      </Flex>
    </ChakraAlert>
  );
});
