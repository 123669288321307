import { useEffect, useState } from 'react';
import Lightbox from 'react-spring-lightbox';

interface PreviewImageProps {
  open: boolean;
  onClose: () => void;
  images: string[];
  currIndex: number;
}

export const PreviewImage = ({ open, onClose, images, currIndex }: PreviewImageProps) => {
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [imgs, setImgs] = useState<any>([]);

  const gotoPrevious = () => currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length && setCurrentIndex(currentImageIndex + 1);

  useEffect(() => {
    const imgs = images?.map((img) => ({ src: img, alt: 'Image', loading: 'lazy' }));
    setImgs(imgs);
  }, [images]);

  useEffect(() => {
    setCurrentIndex(currIndex);
  }, [currIndex]);

  return (
    <Lightbox
      isOpen={open}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={imgs}
      currentIndex={currentImageIndex}
      style={{ background: 'black' }}
      onClose={onClose}
      renderHeader={() => (
        <div className="flex w-full justify-end p-4 text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 cursor-pointer"
            onClick={onClose}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      )}
    />
  );
};
