import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  aal_level: any;
  bigint: any;
  factor_status: any;
  factor_type: any;
  inet: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "_prisma_migrations" */
export type _Prisma_Migrations = {
  __typename?: '_prisma_migrations';
  applied_steps_count: Scalars['Int'];
  checksum: Scalars['String'];
  finished_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  logs?: Maybe<Scalars['String']>;
  migration_name: Scalars['String'];
  rolled_back_at?: Maybe<Scalars['timestamptz']>;
  started_at: Scalars['timestamptz'];
};

/** aggregated selection of "_prisma_migrations" */
export type _Prisma_Migrations_Aggregate = {
  __typename?: '_prisma_migrations_aggregate';
  aggregate?: Maybe<_Prisma_Migrations_Aggregate_Fields>;
  nodes: Array<_Prisma_Migrations>;
};

/** aggregate fields of "_prisma_migrations" */
export type _Prisma_Migrations_Aggregate_Fields = {
  __typename?: '_prisma_migrations_aggregate_fields';
  avg?: Maybe<_Prisma_Migrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<_Prisma_Migrations_Max_Fields>;
  min?: Maybe<_Prisma_Migrations_Min_Fields>;
  stddev?: Maybe<_Prisma_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<_Prisma_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<_Prisma_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<_Prisma_Migrations_Sum_Fields>;
  var_pop?: Maybe<_Prisma_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<_Prisma_Migrations_Var_Samp_Fields>;
  variance?: Maybe<_Prisma_Migrations_Variance_Fields>;
};


/** aggregate fields of "_prisma_migrations" */
export type _Prisma_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<_Prisma_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type _Prisma_Migrations_Avg_Fields = {
  __typename?: '_prisma_migrations_avg_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "_prisma_migrations". All fields are combined with a logical 'AND'. */
export type _Prisma_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<_Prisma_Migrations_Bool_Exp>>;
  _not?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<_Prisma_Migrations_Bool_Exp>>;
  applied_steps_count?: InputMaybe<Int_Comparison_Exp>;
  checksum?: InputMaybe<String_Comparison_Exp>;
  finished_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  logs?: InputMaybe<String_Comparison_Exp>;
  migration_name?: InputMaybe<String_Comparison_Exp>;
  rolled_back_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "_prisma_migrations" */
export enum _Prisma_Migrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrismaMigrationsPkey = '_prisma_migrations_pkey'
}

/** input type for incrementing numeric columns in table "_prisma_migrations" */
export type _Prisma_Migrations_Inc_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "_prisma_migrations" */
export type _Prisma_Migrations_Insert_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']>;
  checksum?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  logs?: InputMaybe<Scalars['String']>;
  migration_name?: InputMaybe<Scalars['String']>;
  rolled_back_at?: InputMaybe<Scalars['timestamptz']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type _Prisma_Migrations_Max_Fields = {
  __typename?: '_prisma_migrations_max_fields';
  applied_steps_count?: Maybe<Scalars['Int']>;
  checksum?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  logs?: Maybe<Scalars['String']>;
  migration_name?: Maybe<Scalars['String']>;
  rolled_back_at?: Maybe<Scalars['timestamptz']>;
  started_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type _Prisma_Migrations_Min_Fields = {
  __typename?: '_prisma_migrations_min_fields';
  applied_steps_count?: Maybe<Scalars['Int']>;
  checksum?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  logs?: Maybe<Scalars['String']>;
  migration_name?: Maybe<Scalars['String']>;
  rolled_back_at?: Maybe<Scalars['timestamptz']>;
  started_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "_prisma_migrations" */
export type _Prisma_Migrations_Mutation_Response = {
  __typename?: '_prisma_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<_Prisma_Migrations>;
};

/** on_conflict condition type for table "_prisma_migrations" */
export type _Prisma_Migrations_On_Conflict = {
  constraint: _Prisma_Migrations_Constraint;
  update_columns?: Array<_Prisma_Migrations_Update_Column>;
  where?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "_prisma_migrations". */
export type _Prisma_Migrations_Order_By = {
  applied_steps_count?: InputMaybe<Order_By>;
  checksum?: InputMaybe<Order_By>;
  finished_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logs?: InputMaybe<Order_By>;
  migration_name?: InputMaybe<Order_By>;
  rolled_back_at?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: _prisma_migrations */
export type _Prisma_Migrations_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "_prisma_migrations" */
export enum _Prisma_Migrations_Select_Column {
  /** column name */
  AppliedStepsCount = 'applied_steps_count',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Logs = 'logs',
  /** column name */
  MigrationName = 'migration_name',
  /** column name */
  RolledBackAt = 'rolled_back_at',
  /** column name */
  StartedAt = 'started_at'
}

/** input type for updating data in table "_prisma_migrations" */
export type _Prisma_Migrations_Set_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']>;
  checksum?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  logs?: InputMaybe<Scalars['String']>;
  migration_name?: InputMaybe<Scalars['String']>;
  rolled_back_at?: InputMaybe<Scalars['timestamptz']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type _Prisma_Migrations_Stddev_Fields = {
  __typename?: '_prisma_migrations_stddev_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type _Prisma_Migrations_Stddev_Pop_Fields = {
  __typename?: '_prisma_migrations_stddev_pop_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type _Prisma_Migrations_Stddev_Samp_Fields = {
  __typename?: '_prisma_migrations_stddev_samp_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "_prisma_migrations" */
export type _Prisma_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: _Prisma_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _Prisma_Migrations_Stream_Cursor_Value_Input = {
  applied_steps_count?: InputMaybe<Scalars['Int']>;
  checksum?: InputMaybe<Scalars['String']>;
  finished_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  logs?: InputMaybe<Scalars['String']>;
  migration_name?: InputMaybe<Scalars['String']>;
  rolled_back_at?: InputMaybe<Scalars['timestamptz']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type _Prisma_Migrations_Sum_Fields = {
  __typename?: '_prisma_migrations_sum_fields';
  applied_steps_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "_prisma_migrations" */
export enum _Prisma_Migrations_Update_Column {
  /** column name */
  AppliedStepsCount = 'applied_steps_count',
  /** column name */
  Checksum = 'checksum',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  Logs = 'logs',
  /** column name */
  MigrationName = 'migration_name',
  /** column name */
  RolledBackAt = 'rolled_back_at',
  /** column name */
  StartedAt = 'started_at'
}

export type _Prisma_Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<_Prisma_Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<_Prisma_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: _Prisma_Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type _Prisma_Migrations_Var_Pop_Fields = {
  __typename?: '_prisma_migrations_var_pop_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type _Prisma_Migrations_Var_Samp_Fields = {
  __typename?: '_prisma_migrations_var_samp_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type _Prisma_Migrations_Variance_Fields = {
  __typename?: '_prisma_migrations_variance_fields';
  applied_steps_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "aal_level". All fields are combined with logical 'AND'. */
export type Aal_Level_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['aal_level']>;
  _gt?: InputMaybe<Scalars['aal_level']>;
  _gte?: InputMaybe<Scalars['aal_level']>;
  _in?: InputMaybe<Array<Scalars['aal_level']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['aal_level']>;
  _lte?: InputMaybe<Scalars['aal_level']>;
  _neq?: InputMaybe<Scalars['aal_level']>;
  _nin?: InputMaybe<Array<Scalars['aal_level']>>;
};

/** columns and relationships of "answers" */
export type Answers = {
  __typename?: 'answers';
  answererID: Scalars['uuid'];
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  images?: Maybe<Scalars['jsonb']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  officialQuestionID?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  official_question?: Maybe<Official_Questions>;
  /** An object relationship */
  question?: Maybe<Questions>;
  questionID?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  ratings: Array<Ratings>;
  /** An aggregate relationship */
  ratings_aggregate: Ratings_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "answers" */
export type AnswersImagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "answers" */
export type AnswersRatingsArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


/** columns and relationships of "answers" */
export type AnswersRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};

/** aggregated selection of "answers" */
export type Answers_Aggregate = {
  __typename?: 'answers_aggregate';
  aggregate?: Maybe<Answers_Aggregate_Fields>;
  nodes: Array<Answers>;
};

export type Answers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Answers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Answers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Answers_Aggregate_Bool_Exp_Count>;
};

export type Answers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Answers_Select_Column_Answers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Answers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Answers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Answers_Select_Column_Answers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Answers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "answers" */
export type Answers_Aggregate_Fields = {
  __typename?: 'answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Answers_Max_Fields>;
  min?: Maybe<Answers_Min_Fields>;
};


/** aggregate fields of "answers" */
export type Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "answers" */
export type Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Answers_Max_Order_By>;
  min?: InputMaybe<Answers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Answers_Append_Input = {
  images?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "answers" */
export type Answers_Arr_Rel_Insert_Input = {
  data: Array<Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "answers". All fields are combined with a logical 'AND'. */
export type Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Answers_Bool_Exp>>;
  _not?: InputMaybe<Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Answers_Bool_Exp>>;
  answererID?: InputMaybe<Uuid_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  images?: InputMaybe<Jsonb_Comparison_Exp>;
  isPublic?: InputMaybe<Boolean_Comparison_Exp>;
  officialQuestionID?: InputMaybe<Uuid_Comparison_Exp>;
  official_question?: InputMaybe<Official_Questions_Bool_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionID?: InputMaybe<Uuid_Comparison_Exp>;
  ratings?: InputMaybe<Ratings_Bool_Exp>;
  ratings_aggregate?: InputMaybe<Ratings_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "answers" */
export enum Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnswersPkey = 'answers_pkey',
  /** unique or primary key constraint on columns "questionID" */
  AnswersQuestionIdKey = 'answers_questionID_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Answers_Delete_At_Path_Input = {
  images?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Answers_Delete_Elem_Input = {
  images?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Answers_Delete_Key_Input = {
  images?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "answers" */
export type Answers_Insert_Input = {
  answererID?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  officialQuestionID?: InputMaybe<Scalars['uuid']>;
  official_question?: InputMaybe<Official_Questions_Obj_Rel_Insert_Input>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionID?: InputMaybe<Scalars['uuid']>;
  ratings?: InputMaybe<Ratings_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Answers_Max_Fields = {
  __typename?: 'answers_max_fields';
  answererID?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  officialQuestionID?: Maybe<Scalars['uuid']>;
  questionID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "answers" */
export type Answers_Max_Order_By = {
  answererID?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  officialQuestionID?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Answers_Min_Fields = {
  __typename?: 'answers_min_fields';
  answererID?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  officialQuestionID?: Maybe<Scalars['uuid']>;
  questionID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "answers" */
export type Answers_Min_Order_By = {
  answererID?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  officialQuestionID?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "answers" */
export type Answers_Mutation_Response = {
  __typename?: 'answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Answers>;
};

/** input type for inserting object relation for remote table "answers" */
export type Answers_Obj_Rel_Insert_Input = {
  data: Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};

/** on_conflict condition type for table "answers" */
export type Answers_On_Conflict = {
  constraint: Answers_Constraint;
  update_columns?: Array<Answers_Update_Column>;
  where?: InputMaybe<Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "answers". */
export type Answers_Order_By = {
  answererID?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  images?: InputMaybe<Order_By>;
  isPublic?: InputMaybe<Order_By>;
  officialQuestionID?: InputMaybe<Order_By>;
  official_question?: InputMaybe<Official_Questions_Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionID?: InputMaybe<Order_By>;
  ratings_aggregate?: InputMaybe<Ratings_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: answers */
export type Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Answers_Prepend_Input = {
  images?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "answers" */
export enum Answers_Select_Column {
  /** column name */
  AnswererId = 'answererID',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  OfficialQuestionId = 'officialQuestionID',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "answers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "answers" */
export enum Answers_Select_Column_Answers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic'
}

/** select "answers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "answers" */
export enum Answers_Select_Column_Answers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic'
}

/** input type for updating data in table "answers" */
export type Answers_Set_Input = {
  answererID?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  officialQuestionID?: InputMaybe<Scalars['uuid']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "answers" */
export type Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Answers_Stream_Cursor_Value_Input = {
  answererID?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  officialQuestionID?: InputMaybe<Scalars['uuid']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "answers" */
export enum Answers_Update_Column {
  /** column name */
  AnswererId = 'answererID',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  OfficialQuestionId = 'officialQuestionID',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Answers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Answers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Answers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Answers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Answers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Answers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Answers_Bool_Exp;
};

/** Auth: Audit trail for user actions. */
export type Auth_Audit_Log_Entries = {
  __typename?: 'auth_audit_log_entries';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  instance_id?: Maybe<Scalars['uuid']>;
  ip_address: Scalars['String'];
  payload?: Maybe<Scalars['json']>;
};


/** Auth: Audit trail for user actions. */
export type Auth_Audit_Log_EntriesPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_Aggregate = {
  __typename?: 'auth_audit_log_entries_aggregate';
  aggregate?: Maybe<Auth_Audit_Log_Entries_Aggregate_Fields>;
  nodes: Array<Auth_Audit_Log_Entries>;
};

/** aggregate fields of "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_Aggregate_Fields = {
  __typename?: 'auth_audit_log_entries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Audit_Log_Entries_Max_Fields>;
  min?: Maybe<Auth_Audit_Log_Entries_Min_Fields>;
};


/** aggregate fields of "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Audit_Log_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.audit_log_entries". All fields are combined with a logical 'AND'. */
export type Auth_Audit_Log_Entries_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Audit_Log_Entries_Bool_Exp>>;
  _not?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Audit_Log_Entries_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instance_id?: InputMaybe<Uuid_Comparison_Exp>;
  ip_address?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.audit_log_entries" */
export enum Auth_Audit_Log_Entries_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuditLogEntriesPkey = 'audit_log_entries_pkey'
}

/** input type for inserting data into table "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  ip_address?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Auth_Audit_Log_Entries_Max_Fields = {
  __typename?: 'auth_audit_log_entries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instance_id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Audit_Log_Entries_Min_Fields = {
  __typename?: 'auth_audit_log_entries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instance_id?: Maybe<Scalars['uuid']>;
  ip_address?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_Mutation_Response = {
  __typename?: 'auth_audit_log_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Audit_Log_Entries>;
};

/** on_conflict condition type for table "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_On_Conflict = {
  constraint: Auth_Audit_Log_Entries_Constraint;
  update_columns?: Array<Auth_Audit_Log_Entries_Update_Column>;
  where?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.audit_log_entries". */
export type Auth_Audit_Log_Entries_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  ip_address?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.audit_log_entries */
export type Auth_Audit_Log_Entries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.audit_log_entries" */
export enum Auth_Audit_Log_Entries_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  Payload = 'payload'
}

/** input type for updating data in table "auth.audit_log_entries" */
export type Auth_Audit_Log_Entries_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  ip_address?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['json']>;
};

/** Streaming cursor of the table "auth_audit_log_entries" */
export type Auth_Audit_Log_Entries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Audit_Log_Entries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Audit_Log_Entries_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  ip_address?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['json']>;
};

/** update columns of table "auth.audit_log_entries" */
export enum Auth_Audit_Log_Entries_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  Payload = 'payload'
}

export type Auth_Audit_Log_Entries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Audit_Log_Entries_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Audit_Log_Entries_Bool_Exp;
};

/** Auth: Stores identities associated to a user. */
export type Auth_Identities = {
  __typename?: 'auth_identities';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Auth: Email is a generated column that references the optional email property in the identity_data */
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  identity_data: Scalars['jsonb'];
  last_sign_in_at?: Maybe<Scalars['timestamptz']>;
  provider: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Auth_Users;
  user_id: Scalars['uuid'];
};


/** Auth: Stores identities associated to a user. */
export type Auth_IdentitiesIdentity_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "auth.identities" */
export type Auth_Identities_Aggregate = {
  __typename?: 'auth_identities_aggregate';
  aggregate?: Maybe<Auth_Identities_Aggregate_Fields>;
  nodes: Array<Auth_Identities>;
};

export type Auth_Identities_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Identities_Aggregate_Bool_Exp_Count>;
};

export type Auth_Identities_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Identities_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.identities" */
export type Auth_Identities_Aggregate_Fields = {
  __typename?: 'auth_identities_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Identities_Max_Fields>;
  min?: Maybe<Auth_Identities_Min_Fields>;
};


/** aggregate fields of "auth.identities" */
export type Auth_Identities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.identities" */
export type Auth_Identities_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Identities_Max_Order_By>;
  min?: InputMaybe<Auth_Identities_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Identities_Append_Input = {
  identity_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.identities" */
export type Auth_Identities_Arr_Rel_Insert_Input = {
  data: Array<Auth_Identities_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Identities_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.identities". All fields are combined with a logical 'AND'. */
export type Auth_Identities_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Identities_Bool_Exp>>;
  _not?: InputMaybe<Auth_Identities_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Identities_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  identity_data?: InputMaybe<Jsonb_Comparison_Exp>;
  last_sign_in_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Auth_Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.identities" */
export enum Auth_Identities_Constraint {
  /** unique or primary key constraint on columns "provider", "id" */
  IdentitiesPkey = 'identities_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Identities_Delete_At_Path_Input = {
  identity_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Identities_Delete_Elem_Input = {
  identity_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Identities_Delete_Key_Input = {
  identity_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.identities" */
export type Auth_Identities_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  identity_data?: InputMaybe<Scalars['jsonb']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamptz']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Identities_Max_Fields = {
  __typename?: 'auth_identities_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Auth: Email is a generated column that references the optional email property in the identity_data */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamptz']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.identities" */
export type Auth_Identities_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** Auth: Email is a generated column that references the optional email property in the identity_data */
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Identities_Min_Fields = {
  __typename?: 'auth_identities_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Auth: Email is a generated column that references the optional email property in the identity_data */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_sign_in_at?: Maybe<Scalars['timestamptz']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.identities" */
export type Auth_Identities_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  /** Auth: Email is a generated column that references the optional email property in the identity_data */
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.identities" */
export type Auth_Identities_Mutation_Response = {
  __typename?: 'auth_identities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Identities>;
};

/** on_conflict condition type for table "auth.identities" */
export type Auth_Identities_On_Conflict = {
  constraint: Auth_Identities_Constraint;
  update_columns?: Array<Auth_Identities_Update_Column>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.identities". */
export type Auth_Identities_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_data?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Auth_Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.identities */
export type Auth_Identities_Pk_Columns_Input = {
  id: Scalars['String'];
  provider: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Identities_Prepend_Input = {
  identity_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "auth.identities" */
export enum Auth_Identities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityData = 'identity_data',
  /** column name */
  LastSignInAt = 'last_sign_in_at',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.identities" */
export type Auth_Identities_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  identity_data?: InputMaybe<Scalars['jsonb']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamptz']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_identities" */
export type Auth_Identities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Identities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Identities_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Auth: Email is a generated column that references the optional email property in the identity_data */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  identity_data?: InputMaybe<Scalars['jsonb']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamptz']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.identities" */
export enum Auth_Identities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityData = 'identity_data',
  /** column name */
  LastSignInAt = 'last_sign_in_at',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Auth_Identities_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Identities_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Identities_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Identities_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Identities_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Identities_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Identities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Identities_Bool_Exp;
};

/** Auth: Manages users across multiple sites. */
export type Auth_Instances = {
  __typename?: 'auth_instances';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  raw_base_config?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "auth.instances" */
export type Auth_Instances_Aggregate = {
  __typename?: 'auth_instances_aggregate';
  aggregate?: Maybe<Auth_Instances_Aggregate_Fields>;
  nodes: Array<Auth_Instances>;
};

/** aggregate fields of "auth.instances" */
export type Auth_Instances_Aggregate_Fields = {
  __typename?: 'auth_instances_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Instances_Max_Fields>;
  min?: Maybe<Auth_Instances_Min_Fields>;
};


/** aggregate fields of "auth.instances" */
export type Auth_Instances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Instances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.instances". All fields are combined with a logical 'AND'. */
export type Auth_Instances_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Instances_Bool_Exp>>;
  _not?: InputMaybe<Auth_Instances_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Instances_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  raw_base_config?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.instances" */
export enum Auth_Instances_Constraint {
  /** unique or primary key constraint on columns "id" */
  InstancesPkey = 'instances_pkey'
}

/** input type for inserting data into table "auth.instances" */
export type Auth_Instances_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  raw_base_config?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Instances_Max_Fields = {
  __typename?: 'auth_instances_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  raw_base_config?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Auth_Instances_Min_Fields = {
  __typename?: 'auth_instances_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  raw_base_config?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.instances" */
export type Auth_Instances_Mutation_Response = {
  __typename?: 'auth_instances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Instances>;
};

/** on_conflict condition type for table "auth.instances" */
export type Auth_Instances_On_Conflict = {
  constraint: Auth_Instances_Constraint;
  update_columns?: Array<Auth_Instances_Update_Column>;
  where?: InputMaybe<Auth_Instances_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.instances". */
export type Auth_Instances_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  raw_base_config?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.instances */
export type Auth_Instances_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.instances" */
export enum Auth_Instances_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RawBaseConfig = 'raw_base_config',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "auth.instances" */
export type Auth_Instances_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  raw_base_config?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_instances" */
export type Auth_Instances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Instances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Instances_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  raw_base_config?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.instances" */
export enum Auth_Instances_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RawBaseConfig = 'raw_base_config',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid'
}

export type Auth_Instances_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Instances_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Instances_Bool_Exp;
};

/** auth: stores authenticator method reference claims for multi factor authentication */
export type Auth_Mfa_Amr_Claims = {
  __typename?: 'auth_mfa_amr_claims';
  authentication_method: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  session: Auth_Sessions;
  session_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Aggregate = {
  __typename?: 'auth_mfa_amr_claims_aggregate';
  aggregate?: Maybe<Auth_Mfa_Amr_Claims_Aggregate_Fields>;
  nodes: Array<Auth_Mfa_Amr_Claims>;
};

export type Auth_Mfa_Amr_Claims_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Mfa_Amr_Claims_Aggregate_Bool_Exp_Count>;
};

export type Auth_Mfa_Amr_Claims_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Aggregate_Fields = {
  __typename?: 'auth_mfa_amr_claims_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Mfa_Amr_Claims_Max_Fields>;
  min?: Maybe<Auth_Mfa_Amr_Claims_Min_Fields>;
};


/** aggregate fields of "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Mfa_Amr_Claims_Max_Order_By>;
  min?: InputMaybe<Auth_Mfa_Amr_Claims_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Arr_Rel_Insert_Input = {
  data: Array<Auth_Mfa_Amr_Claims_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Mfa_Amr_Claims_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.mfa_amr_claims". All fields are combined with a logical 'AND'. */
export type Auth_Mfa_Amr_Claims_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Bool_Exp>>;
  _not?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Bool_Exp>>;
  authentication_method?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  session?: InputMaybe<Auth_Sessions_Bool_Exp>;
  session_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.mfa_amr_claims" */
export enum Auth_Mfa_Amr_Claims_Constraint {
  /** unique or primary key constraint on columns "id" */
  AmrIdPk = 'amr_id_pk',
  /** unique or primary key constraint on columns "session_id", "authentication_method" */
  MfaAmrClaimsSessionIdAuthenticationMethodPkey = 'mfa_amr_claims_session_id_authentication_method_pkey'
}

/** input type for inserting data into table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Insert_Input = {
  authentication_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  session?: InputMaybe<Auth_Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Mfa_Amr_Claims_Max_Fields = {
  __typename?: 'auth_mfa_amr_claims_max_fields';
  authentication_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Max_Order_By = {
  authentication_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Mfa_Amr_Claims_Min_Fields = {
  __typename?: 'auth_mfa_amr_claims_min_fields';
  authentication_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Min_Order_By = {
  authentication_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Mutation_Response = {
  __typename?: 'auth_mfa_amr_claims_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Mfa_Amr_Claims>;
};

/** on_conflict condition type for table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_On_Conflict = {
  constraint: Auth_Mfa_Amr_Claims_Constraint;
  update_columns?: Array<Auth_Mfa_Amr_Claims_Update_Column>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.mfa_amr_claims". */
export type Auth_Mfa_Amr_Claims_Order_By = {
  authentication_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  session?: InputMaybe<Auth_Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.mfa_amr_claims */
export type Auth_Mfa_Amr_Claims_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.mfa_amr_claims" */
export enum Auth_Mfa_Amr_Claims_Select_Column {
  /** column name */
  AuthenticationMethod = 'authentication_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Set_Input = {
  authentication_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  session_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_mfa_amr_claims" */
export type Auth_Mfa_Amr_Claims_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Mfa_Amr_Claims_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Mfa_Amr_Claims_Stream_Cursor_Value_Input = {
  authentication_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  session_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.mfa_amr_claims" */
export enum Auth_Mfa_Amr_Claims_Update_Column {
  /** column name */
  AuthenticationMethod = 'authentication_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Mfa_Amr_Claims_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Mfa_Amr_Claims_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Mfa_Amr_Claims_Bool_Exp;
};

/** auth: stores metadata about challenge requests made */
export type Auth_Mfa_Challenges = {
  __typename?: 'auth_mfa_challenges';
  created_at: Scalars['timestamptz'];
  factor_id: Scalars['uuid'];
  id: Scalars['uuid'];
  ip_address: Scalars['inet'];
  /** An object relationship */
  mfa_factor: Auth_Mfa_Factors;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Aggregate = {
  __typename?: 'auth_mfa_challenges_aggregate';
  aggregate?: Maybe<Auth_Mfa_Challenges_Aggregate_Fields>;
  nodes: Array<Auth_Mfa_Challenges>;
};

export type Auth_Mfa_Challenges_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Mfa_Challenges_Aggregate_Bool_Exp_Count>;
};

export type Auth_Mfa_Challenges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Aggregate_Fields = {
  __typename?: 'auth_mfa_challenges_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Mfa_Challenges_Max_Fields>;
  min?: Maybe<Auth_Mfa_Challenges_Min_Fields>;
};


/** aggregate fields of "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Mfa_Challenges_Max_Order_By>;
  min?: InputMaybe<Auth_Mfa_Challenges_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Arr_Rel_Insert_Input = {
  data: Array<Auth_Mfa_Challenges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Mfa_Challenges_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.mfa_challenges". All fields are combined with a logical 'AND'. */
export type Auth_Mfa_Challenges_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Mfa_Challenges_Bool_Exp>>;
  _not?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Mfa_Challenges_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ip_address?: InputMaybe<Inet_Comparison_Exp>;
  mfa_factor?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
  verified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.mfa_challenges" */
export enum Auth_Mfa_Challenges_Constraint {
  /** unique or primary key constraint on columns "id" */
  MfaChallengesPkey = 'mfa_challenges_pkey'
}

/** input type for inserting data into table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip_address?: InputMaybe<Scalars['inet']>;
  mfa_factor?: InputMaybe<Auth_Mfa_Factors_Obj_Rel_Insert_Input>;
  verified_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Mfa_Challenges_Max_Fields = {
  __typename?: 'auth_mfa_challenges_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Mfa_Challenges_Min_Fields = {
  __typename?: 'auth_mfa_challenges_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  verified_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Mutation_Response = {
  __typename?: 'auth_mfa_challenges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Mfa_Challenges>;
};

/** on_conflict condition type for table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_On_Conflict = {
  constraint: Auth_Mfa_Challenges_Constraint;
  update_columns?: Array<Auth_Mfa_Challenges_Update_Column>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.mfa_challenges". */
export type Auth_Mfa_Challenges_Order_By = {
  created_at?: InputMaybe<Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip_address?: InputMaybe<Order_By>;
  mfa_factor?: InputMaybe<Auth_Mfa_Factors_Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.mfa_challenges */
export type Auth_Mfa_Challenges_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.mfa_challenges" */
export enum Auth_Mfa_Challenges_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FactorId = 'factor_id',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  VerifiedAt = 'verified_at'
}

/** input type for updating data in table "auth.mfa_challenges" */
export type Auth_Mfa_Challenges_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip_address?: InputMaybe<Scalars['inet']>;
  verified_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_mfa_challenges" */
export type Auth_Mfa_Challenges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Mfa_Challenges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Mfa_Challenges_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip_address?: InputMaybe<Scalars['inet']>;
  verified_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.mfa_challenges" */
export enum Auth_Mfa_Challenges_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FactorId = 'factor_id',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  VerifiedAt = 'verified_at'
}

export type Auth_Mfa_Challenges_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Mfa_Challenges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Mfa_Challenges_Bool_Exp;
};

/** auth: stores metadata about factors */
export type Auth_Mfa_Factors = {
  __typename?: 'auth_mfa_factors';
  created_at: Scalars['timestamptz'];
  factor_type: Scalars['factor_type'];
  friendly_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  mfa_challenges: Array<Auth_Mfa_Challenges>;
  /** An aggregate relationship */
  mfa_challenges_aggregate: Auth_Mfa_Challenges_Aggregate;
  secret?: Maybe<Scalars['String']>;
  status: Scalars['factor_status'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Auth_Users;
  user_id: Scalars['uuid'];
};


/** auth: stores metadata about factors */
export type Auth_Mfa_FactorsMfa_ChallengesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Challenges_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};


/** auth: stores metadata about factors */
export type Auth_Mfa_FactorsMfa_Challenges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Challenges_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};

/** aggregated selection of "auth.mfa_factors" */
export type Auth_Mfa_Factors_Aggregate = {
  __typename?: 'auth_mfa_factors_aggregate';
  aggregate?: Maybe<Auth_Mfa_Factors_Aggregate_Fields>;
  nodes: Array<Auth_Mfa_Factors>;
};

export type Auth_Mfa_Factors_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Mfa_Factors_Aggregate_Bool_Exp_Count>;
};

export type Auth_Mfa_Factors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.mfa_factors" */
export type Auth_Mfa_Factors_Aggregate_Fields = {
  __typename?: 'auth_mfa_factors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Mfa_Factors_Max_Fields>;
  min?: Maybe<Auth_Mfa_Factors_Min_Fields>;
};


/** aggregate fields of "auth.mfa_factors" */
export type Auth_Mfa_Factors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Mfa_Factors_Max_Order_By>;
  min?: InputMaybe<Auth_Mfa_Factors_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Arr_Rel_Insert_Input = {
  data: Array<Auth_Mfa_Factors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Mfa_Factors_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.mfa_factors". All fields are combined with a logical 'AND'. */
export type Auth_Mfa_Factors_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Mfa_Factors_Bool_Exp>>;
  _not?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Mfa_Factors_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  factor_type?: InputMaybe<Factor_Type_Comparison_Exp>;
  friendly_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mfa_challenges?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
  mfa_challenges_aggregate?: InputMaybe<Auth_Mfa_Challenges_Aggregate_Bool_Exp>;
  secret?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Factor_Status_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Auth_Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.mfa_factors" */
export enum Auth_Mfa_Factors_Constraint {
  /** unique or primary key constraint on columns "id" */
  MfaFactorsPkey = 'mfa_factors_pkey',
  /** unique or primary key constraint on columns "user_id", "friendly_name" */
  MfaFactorsUserFriendlyNameUnique = 'mfa_factors_user_friendly_name_unique'
}

/** input type for inserting data into table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_type?: InputMaybe<Scalars['factor_type']>;
  friendly_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mfa_challenges?: InputMaybe<Auth_Mfa_Challenges_Arr_Rel_Insert_Input>;
  secret?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['factor_status']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Mfa_Factors_Max_Fields = {
  __typename?: 'auth_mfa_factors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_type?: Maybe<Scalars['factor_type']>;
  friendly_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  secret?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['factor_status']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  factor_type?: InputMaybe<Order_By>;
  friendly_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  secret?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Mfa_Factors_Min_Fields = {
  __typename?: 'auth_mfa_factors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_type?: Maybe<Scalars['factor_type']>;
  friendly_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  secret?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['factor_status']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  factor_type?: InputMaybe<Order_By>;
  friendly_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  secret?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Mutation_Response = {
  __typename?: 'auth_mfa_factors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Mfa_Factors>;
};

/** input type for inserting object relation for remote table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Obj_Rel_Insert_Input = {
  data: Auth_Mfa_Factors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Mfa_Factors_On_Conflict>;
};

/** on_conflict condition type for table "auth.mfa_factors" */
export type Auth_Mfa_Factors_On_Conflict = {
  constraint: Auth_Mfa_Factors_Constraint;
  update_columns?: Array<Auth_Mfa_Factors_Update_Column>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.mfa_factors". */
export type Auth_Mfa_Factors_Order_By = {
  created_at?: InputMaybe<Order_By>;
  factor_type?: InputMaybe<Order_By>;
  friendly_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mfa_challenges_aggregate?: InputMaybe<Auth_Mfa_Challenges_Aggregate_Order_By>;
  secret?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Auth_Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.mfa_factors */
export type Auth_Mfa_Factors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.mfa_factors" */
export enum Auth_Mfa_Factors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FactorType = 'factor_type',
  /** column name */
  FriendlyName = 'friendly_name',
  /** column name */
  Id = 'id',
  /** column name */
  Secret = 'secret',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.mfa_factors" */
export type Auth_Mfa_Factors_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_type?: InputMaybe<Scalars['factor_type']>;
  friendly_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  secret?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['factor_status']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_mfa_factors" */
export type Auth_Mfa_Factors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Mfa_Factors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Mfa_Factors_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_type?: InputMaybe<Scalars['factor_type']>;
  friendly_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  secret?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['factor_status']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.mfa_factors" */
export enum Auth_Mfa_Factors_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FactorType = 'factor_type',
  /** column name */
  FriendlyName = 'friendly_name',
  /** column name */
  Id = 'id',
  /** column name */
  Secret = 'secret',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Auth_Mfa_Factors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Mfa_Factors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Mfa_Factors_Bool_Exp;
};

/** Auth: Store of tokens used to refresh JWT tokens once they expire. */
export type Auth_Refresh_Tokens = {
  __typename?: 'auth_refresh_tokens';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  instance_id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['String']>;
  revoked?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  session?: Maybe<Auth_Sessions>;
  session_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Refresh_Tokens>;
};

export type Auth_Refresh_Tokens_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp_Count>;
};

export type Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_And = {
  arguments: Auth_Refresh_Tokens_Select_Column_Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Auth_Refresh_Tokens_Select_Column_Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Auth_Refresh_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  avg?: Maybe<Auth_Refresh_Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Fields>;
  stddev?: Maybe<Auth_Refresh_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Refresh_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Refresh_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Refresh_Tokens_Sum_Fields>;
  var_pop?: Maybe<Auth_Refresh_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Refresh_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Auth_Refresh_Tokens_Variance_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Order_By = {
  avg?: InputMaybe<Auth_Refresh_Tokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: InputMaybe<Auth_Refresh_Tokens_Min_Order_By>;
  stddev?: InputMaybe<Auth_Refresh_Tokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Auth_Refresh_Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Auth_Refresh_Tokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Auth_Refresh_Tokens_Sum_Order_By>;
  var_pop?: InputMaybe<Auth_Refresh_Tokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Auth_Refresh_Tokens_Var_Samp_Order_By>;
  variance?: InputMaybe<Auth_Refresh_Tokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Refresh_Tokens_Avg_Fields = {
  __typename?: 'auth_refresh_tokens_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Refresh_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  instance_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent?: InputMaybe<String_Comparison_Exp>;
  revoked?: InputMaybe<Boolean_Comparison_Exp>;
  session?: InputMaybe<Auth_Sessions_Bool_Exp>;
  session_id?: InputMaybe<Uuid_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey',
  /** unique or primary key constraint on columns "token" */
  RefreshTokensTokenUnique = 'refresh_tokens_token_unique'
}

/** input type for incrementing numeric columns in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  parent?: InputMaybe<Scalars['String']>;
  revoked?: InputMaybe<Scalars['Boolean']>;
  session?: InputMaybe<Auth_Sessions_Obj_Rel_Insert_Input>;
  session_id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Refresh_Tokens_Max_Fields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  instance_id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Refresh_Tokens_Min_Fields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  instance_id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Mutation_Response = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Refresh_Tokens>;
};

/** on_conflict condition type for table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_On_Conflict = {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns?: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type Auth_Refresh_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  revoked?: InputMaybe<Order_By>;
  session?: InputMaybe<Auth_Sessions_Order_By>;
  session_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_tokens */
export type Auth_Refresh_Tokens_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  Parent = 'parent',
  /** column name */
  Revoked = 'revoked',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "auth_refresh_tokens_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column_Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Revoked = 'revoked'
}

/** select "auth_refresh_tokens_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column_Auth_Refresh_Tokens_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Revoked = 'revoked'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  parent?: InputMaybe<Scalars['String']>;
  revoked?: InputMaybe<Scalars['Boolean']>;
  session_id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Auth_Refresh_Tokens_Stddev_Fields = {
  __typename?: 'auth_refresh_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Refresh_Tokens_Stddev_Pop_Fields = {
  __typename?: 'auth_refresh_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Refresh_Tokens_Stddev_Samp_Fields = {
  __typename?: 'auth_refresh_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "auth_refresh_tokens" */
export type Auth_Refresh_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Refresh_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Refresh_Tokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  parent?: InputMaybe<Scalars['String']>;
  revoked?: InputMaybe<Scalars['Boolean']>;
  session_id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Auth_Refresh_Tokens_Sum_Fields = {
  __typename?: 'auth_refresh_tokens_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  Parent = 'parent',
  /** column name */
  Revoked = 'revoked',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Auth_Refresh_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Refresh_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Refresh_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Refresh_Tokens_Var_Pop_Fields = {
  __typename?: 'auth_refresh_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Refresh_Tokens_Var_Samp_Fields = {
  __typename?: 'auth_refresh_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Refresh_Tokens_Variance_Fields = {
  __typename?: 'auth_refresh_tokens_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Auth: Manages SAML Identity Provider connections. */
export type Auth_Saml_Providers = {
  __typename?: 'auth_saml_providers';
  attribute_mapping?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id: Scalars['String'];
  id: Scalars['uuid'];
  metadata_url?: Maybe<Scalars['String']>;
  metadata_xml: Scalars['String'];
  /** An object relationship */
  sso_provider: Auth_Sso_Providers;
  sso_provider_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Auth: Manages SAML Identity Provider connections. */
export type Auth_Saml_ProvidersAttribute_MappingArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "auth.saml_providers" */
export type Auth_Saml_Providers_Aggregate = {
  __typename?: 'auth_saml_providers_aggregate';
  aggregate?: Maybe<Auth_Saml_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Saml_Providers>;
};

export type Auth_Saml_Providers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Saml_Providers_Aggregate_Bool_Exp_Count>;
};

export type Auth_Saml_Providers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.saml_providers" */
export type Auth_Saml_Providers_Aggregate_Fields = {
  __typename?: 'auth_saml_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Saml_Providers_Max_Fields>;
  min?: Maybe<Auth_Saml_Providers_Min_Fields>;
};


/** aggregate fields of "auth.saml_providers" */
export type Auth_Saml_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.saml_providers" */
export type Auth_Saml_Providers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Saml_Providers_Max_Order_By>;
  min?: InputMaybe<Auth_Saml_Providers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Saml_Providers_Append_Input = {
  attribute_mapping?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.saml_providers" */
export type Auth_Saml_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Saml_Providers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Saml_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.saml_providers". All fields are combined with a logical 'AND'. */
export type Auth_Saml_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Saml_Providers_Bool_Exp>>;
  _not?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Saml_Providers_Bool_Exp>>;
  attribute_mapping?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata_url?: InputMaybe<String_Comparison_Exp>;
  metadata_xml?: InputMaybe<String_Comparison_Exp>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
  sso_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.saml_providers" */
export enum Auth_Saml_Providers_Constraint {
  /** unique or primary key constraint on columns "entity_id" */
  SamlProvidersEntityIdKey = 'saml_providers_entity_id_key',
  /** unique or primary key constraint on columns "id" */
  SamlProvidersPkey = 'saml_providers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Saml_Providers_Delete_At_Path_Input = {
  attribute_mapping?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Saml_Providers_Delete_Elem_Input = {
  attribute_mapping?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Saml_Providers_Delete_Key_Input = {
  attribute_mapping?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.saml_providers" */
export type Auth_Saml_Providers_Insert_Input = {
  attribute_mapping?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata_url?: InputMaybe<Scalars['String']>;
  metadata_xml?: InputMaybe<Scalars['String']>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Obj_Rel_Insert_Input>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Saml_Providers_Max_Fields = {
  __typename?: 'auth_saml_providers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata_url?: Maybe<Scalars['String']>;
  metadata_xml?: Maybe<Scalars['String']>;
  sso_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.saml_providers" */
export type Auth_Saml_Providers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_url?: InputMaybe<Order_By>;
  metadata_xml?: InputMaybe<Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Saml_Providers_Min_Fields = {
  __typename?: 'auth_saml_providers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entity_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata_url?: Maybe<Scalars['String']>;
  metadata_xml?: Maybe<Scalars['String']>;
  sso_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.saml_providers" */
export type Auth_Saml_Providers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_url?: InputMaybe<Order_By>;
  metadata_xml?: InputMaybe<Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.saml_providers" */
export type Auth_Saml_Providers_Mutation_Response = {
  __typename?: 'auth_saml_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Saml_Providers>;
};

/** on_conflict condition type for table "auth.saml_providers" */
export type Auth_Saml_Providers_On_Conflict = {
  constraint: Auth_Saml_Providers_Constraint;
  update_columns?: Array<Auth_Saml_Providers_Update_Column>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.saml_providers". */
export type Auth_Saml_Providers_Order_By = {
  attribute_mapping?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_url?: InputMaybe<Order_By>;
  metadata_xml?: InputMaybe<Order_By>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.saml_providers */
export type Auth_Saml_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Saml_Providers_Prepend_Input = {
  attribute_mapping?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "auth.saml_providers" */
export enum Auth_Saml_Providers_Select_Column {
  /** column name */
  AttributeMapping = 'attribute_mapping',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataUrl = 'metadata_url',
  /** column name */
  MetadataXml = 'metadata_xml',
  /** column name */
  SsoProviderId = 'sso_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.saml_providers" */
export type Auth_Saml_Providers_Set_Input = {
  attribute_mapping?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata_url?: InputMaybe<Scalars['String']>;
  metadata_xml?: InputMaybe<Scalars['String']>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_saml_providers" */
export type Auth_Saml_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Saml_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Saml_Providers_Stream_Cursor_Value_Input = {
  attribute_mapping?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entity_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata_url?: InputMaybe<Scalars['String']>;
  metadata_xml?: InputMaybe<Scalars['String']>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.saml_providers" */
export enum Auth_Saml_Providers_Update_Column {
  /** column name */
  AttributeMapping = 'attribute_mapping',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataUrl = 'metadata_url',
  /** column name */
  MetadataXml = 'metadata_xml',
  /** column name */
  SsoProviderId = 'sso_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Saml_Providers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Saml_Providers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Saml_Providers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Saml_Providers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Saml_Providers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Saml_Providers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Saml_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Saml_Providers_Bool_Exp;
};

/** Auth: Contains SAML Relay State information for each Service Provider initiated login. */
export type Auth_Saml_Relay_States = {
  __typename?: 'auth_saml_relay_states';
  created_at?: Maybe<Scalars['timestamptz']>;
  for_email?: Maybe<Scalars['String']>;
  from_ip_address?: Maybe<Scalars['inet']>;
  id: Scalars['uuid'];
  redirect_to?: Maybe<Scalars['String']>;
  request_id: Scalars['String'];
  /** An object relationship */
  sso_provider: Auth_Sso_Providers;
  sso_provider_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Aggregate = {
  __typename?: 'auth_saml_relay_states_aggregate';
  aggregate?: Maybe<Auth_Saml_Relay_States_Aggregate_Fields>;
  nodes: Array<Auth_Saml_Relay_States>;
};

export type Auth_Saml_Relay_States_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Saml_Relay_States_Aggregate_Bool_Exp_Count>;
};

export type Auth_Saml_Relay_States_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Aggregate_Fields = {
  __typename?: 'auth_saml_relay_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Saml_Relay_States_Max_Fields>;
  min?: Maybe<Auth_Saml_Relay_States_Min_Fields>;
};


/** aggregate fields of "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Saml_Relay_States_Max_Order_By>;
  min?: InputMaybe<Auth_Saml_Relay_States_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Arr_Rel_Insert_Input = {
  data: Array<Auth_Saml_Relay_States_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Saml_Relay_States_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.saml_relay_states". All fields are combined with a logical 'AND'. */
export type Auth_Saml_Relay_States_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Saml_Relay_States_Bool_Exp>>;
  _not?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Saml_Relay_States_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  for_email?: InputMaybe<String_Comparison_Exp>;
  from_ip_address?: InputMaybe<Inet_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  redirect_to?: InputMaybe<String_Comparison_Exp>;
  request_id?: InputMaybe<String_Comparison_Exp>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
  sso_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.saml_relay_states" */
export enum Auth_Saml_Relay_States_Constraint {
  /** unique or primary key constraint on columns "id" */
  SamlRelayStatesPkey = 'saml_relay_states_pkey'
}

/** input type for inserting data into table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  for_email?: InputMaybe<Scalars['String']>;
  from_ip_address?: InputMaybe<Scalars['inet']>;
  id?: InputMaybe<Scalars['uuid']>;
  redirect_to?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['String']>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Obj_Rel_Insert_Input>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Saml_Relay_States_Max_Fields = {
  __typename?: 'auth_saml_relay_states_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  for_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  redirect_to?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  sso_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  for_email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  redirect_to?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Saml_Relay_States_Min_Fields = {
  __typename?: 'auth_saml_relay_states_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  for_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  redirect_to?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  sso_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  for_email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  redirect_to?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Mutation_Response = {
  __typename?: 'auth_saml_relay_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Saml_Relay_States>;
};

/** on_conflict condition type for table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_On_Conflict = {
  constraint: Auth_Saml_Relay_States_Constraint;
  update_columns?: Array<Auth_Saml_Relay_States_Update_Column>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.saml_relay_states". */
export type Auth_Saml_Relay_States_Order_By = {
  created_at?: InputMaybe<Order_By>;
  for_email?: InputMaybe<Order_By>;
  from_ip_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  redirect_to?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.saml_relay_states */
export type Auth_Saml_Relay_States_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.saml_relay_states" */
export enum Auth_Saml_Relay_States_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForEmail = 'for_email',
  /** column name */
  FromIpAddress = 'from_ip_address',
  /** column name */
  Id = 'id',
  /** column name */
  RedirectTo = 'redirect_to',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  SsoProviderId = 'sso_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.saml_relay_states" */
export type Auth_Saml_Relay_States_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  for_email?: InputMaybe<Scalars['String']>;
  from_ip_address?: InputMaybe<Scalars['inet']>;
  id?: InputMaybe<Scalars['uuid']>;
  redirect_to?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['String']>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_saml_relay_states" */
export type Auth_Saml_Relay_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Saml_Relay_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Saml_Relay_States_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  for_email?: InputMaybe<Scalars['String']>;
  from_ip_address?: InputMaybe<Scalars['inet']>;
  id?: InputMaybe<Scalars['uuid']>;
  redirect_to?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['String']>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.saml_relay_states" */
export enum Auth_Saml_Relay_States_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForEmail = 'for_email',
  /** column name */
  FromIpAddress = 'from_ip_address',
  /** column name */
  Id = 'id',
  /** column name */
  RedirectTo = 'redirect_to',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  SsoProviderId = 'sso_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Saml_Relay_States_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Saml_Relay_States_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Saml_Relay_States_Bool_Exp;
};

/** Auth: Manages updates to the auth system. */
export type Auth_Schema_Migrations = {
  __typename?: 'auth_schema_migrations';
  version: Scalars['String'];
};

/** aggregated selection of "auth.schema_migrations" */
export type Auth_Schema_Migrations_Aggregate = {
  __typename?: 'auth_schema_migrations_aggregate';
  aggregate?: Maybe<Auth_Schema_Migrations_Aggregate_Fields>;
  nodes: Array<Auth_Schema_Migrations>;
};

/** aggregate fields of "auth.schema_migrations" */
export type Auth_Schema_Migrations_Aggregate_Fields = {
  __typename?: 'auth_schema_migrations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Schema_Migrations_Max_Fields>;
  min?: Maybe<Auth_Schema_Migrations_Min_Fields>;
};


/** aggregate fields of "auth.schema_migrations" */
export type Auth_Schema_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Schema_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.schema_migrations". All fields are combined with a logical 'AND'. */
export type Auth_Schema_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Schema_Migrations_Bool_Exp>>;
  _not?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Schema_Migrations_Bool_Exp>>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.schema_migrations" */
export enum Auth_Schema_Migrations_Constraint {
  /** unique or primary key constraint on columns "version" */
  SchemaMigrationsPkey = 'schema_migrations_pkey'
}

/** input type for inserting data into table "auth.schema_migrations" */
export type Auth_Schema_Migrations_Insert_Input = {
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Schema_Migrations_Max_Fields = {
  __typename?: 'auth_schema_migrations_max_fields';
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Schema_Migrations_Min_Fields = {
  __typename?: 'auth_schema_migrations_min_fields';
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.schema_migrations" */
export type Auth_Schema_Migrations_Mutation_Response = {
  __typename?: 'auth_schema_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Schema_Migrations>;
};

/** on_conflict condition type for table "auth.schema_migrations" */
export type Auth_Schema_Migrations_On_Conflict = {
  constraint: Auth_Schema_Migrations_Constraint;
  update_columns?: Array<Auth_Schema_Migrations_Update_Column>;
  where?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.schema_migrations". */
export type Auth_Schema_Migrations_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.schema_migrations */
export type Auth_Schema_Migrations_Pk_Columns_Input = {
  version: Scalars['String'];
};

/** select columns of table "auth.schema_migrations" */
export enum Auth_Schema_Migrations_Select_Column {
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "auth.schema_migrations" */
export type Auth_Schema_Migrations_Set_Input = {
  version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "auth_schema_migrations" */
export type Auth_Schema_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Schema_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Schema_Migrations_Stream_Cursor_Value_Input = {
  version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.schema_migrations" */
export enum Auth_Schema_Migrations_Update_Column {
  /** column name */
  Version = 'version'
}

export type Auth_Schema_Migrations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Schema_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Schema_Migrations_Bool_Exp;
};

/** Auth: Stores session data associated to a user. */
export type Auth_Sessions = {
  __typename?: 'auth_sessions';
  aal?: Maybe<Scalars['aal_level']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An array relationship */
  mfa_amr_claims: Array<Auth_Mfa_Amr_Claims>;
  /** An aggregate relationship */
  mfa_amr_claims_aggregate: Auth_Mfa_Amr_Claims_Aggregate;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** An aggregate relationship */
  refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Auth_Users;
  user_id: Scalars['uuid'];
};


/** Auth: Stores session data associated to a user. */
export type Auth_SessionsMfa_Amr_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


/** Auth: Stores session data associated to a user. */
export type Auth_SessionsMfa_Amr_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


/** Auth: Stores session data associated to a user. */
export type Auth_SessionsRefresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** Auth: Stores session data associated to a user. */
export type Auth_SessionsRefresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** aggregated selection of "auth.sessions" */
export type Auth_Sessions_Aggregate = {
  __typename?: 'auth_sessions_aggregate';
  aggregate?: Maybe<Auth_Sessions_Aggregate_Fields>;
  nodes: Array<Auth_Sessions>;
};

export type Auth_Sessions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Sessions_Aggregate_Bool_Exp_Count>;
};

export type Auth_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.sessions" */
export type Auth_Sessions_Aggregate_Fields = {
  __typename?: 'auth_sessions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Sessions_Max_Fields>;
  min?: Maybe<Auth_Sessions_Min_Fields>;
};


/** aggregate fields of "auth.sessions" */
export type Auth_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.sessions" */
export type Auth_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Sessions_Max_Order_By>;
  min?: InputMaybe<Auth_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.sessions" */
export type Auth_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Auth_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.sessions". All fields are combined with a logical 'AND'. */
export type Auth_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Auth_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Sessions_Bool_Exp>>;
  aal?: InputMaybe<Aal_Level_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mfa_amr_claims?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
  mfa_amr_claims_aggregate?: InputMaybe<Auth_Mfa_Amr_Claims_Aggregate_Bool_Exp>;
  not_after?: InputMaybe<Timestamptz_Comparison_Exp>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Auth_Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.sessions" */
export enum Auth_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SessionsPkey = 'sessions_pkey'
}

/** input type for inserting data into table "auth.sessions" */
export type Auth_Sessions_Insert_Input = {
  aal?: InputMaybe<Scalars['aal_level']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  mfa_amr_claims?: InputMaybe<Auth_Mfa_Amr_Claims_Arr_Rel_Insert_Input>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: InputMaybe<Scalars['timestamptz']>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Auth_Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Sessions_Max_Fields = {
  __typename?: 'auth_sessions_max_fields';
  aal?: Maybe<Scalars['aal_level']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.sessions" */
export type Auth_Sessions_Max_Order_By = {
  aal?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Sessions_Min_Fields = {
  __typename?: 'auth_sessions_min_fields';
  aal?: Maybe<Scalars['aal_level']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  factor_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.sessions" */
export type Auth_Sessions_Min_Order_By = {
  aal?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.sessions" */
export type Auth_Sessions_Mutation_Response = {
  __typename?: 'auth_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Sessions>;
};

/** input type for inserting object relation for remote table "auth.sessions" */
export type Auth_Sessions_Obj_Rel_Insert_Input = {
  data: Auth_Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Sessions_On_Conflict>;
};

/** on_conflict condition type for table "auth.sessions" */
export type Auth_Sessions_On_Conflict = {
  constraint: Auth_Sessions_Constraint;
  update_columns?: Array<Auth_Sessions_Update_Column>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.sessions". */
export type Auth_Sessions_Order_By = {
  aal?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  factor_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mfa_amr_claims_aggregate?: InputMaybe<Auth_Mfa_Amr_Claims_Aggregate_Order_By>;
  not_after?: InputMaybe<Order_By>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Auth_Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.sessions */
export type Auth_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.sessions" */
export enum Auth_Sessions_Select_Column {
  /** column name */
  Aal = 'aal',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FactorId = 'factor_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotAfter = 'not_after',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.sessions" */
export type Auth_Sessions_Set_Input = {
  aal?: InputMaybe<Scalars['aal_level']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_sessions" */
export type Auth_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Sessions_Stream_Cursor_Value_Input = {
  aal?: InputMaybe<Scalars['aal_level']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  factor_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Auth: Not after is a nullable column that contains a timestamp after which the session should be regarded as expired. */
  not_after?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.sessions" */
export enum Auth_Sessions_Update_Column {
  /** column name */
  Aal = 'aal',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FactorId = 'factor_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotAfter = 'not_after',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Auth_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Sessions_Bool_Exp;
};

/** Auth: Manages SSO email address domain mapping to an SSO Identity Provider. */
export type Auth_Sso_Domains = {
  __typename?: 'auth_sso_domains';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  sso_provider: Auth_Sso_Providers;
  sso_provider_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "auth.sso_domains" */
export type Auth_Sso_Domains_Aggregate = {
  __typename?: 'auth_sso_domains_aggregate';
  aggregate?: Maybe<Auth_Sso_Domains_Aggregate_Fields>;
  nodes: Array<Auth_Sso_Domains>;
};

export type Auth_Sso_Domains_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Sso_Domains_Aggregate_Bool_Exp_Count>;
};

export type Auth_Sso_Domains_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.sso_domains" */
export type Auth_Sso_Domains_Aggregate_Fields = {
  __typename?: 'auth_sso_domains_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Sso_Domains_Max_Fields>;
  min?: Maybe<Auth_Sso_Domains_Min_Fields>;
};


/** aggregate fields of "auth.sso_domains" */
export type Auth_Sso_Domains_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.sso_domains" */
export type Auth_Sso_Domains_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Sso_Domains_Max_Order_By>;
  min?: InputMaybe<Auth_Sso_Domains_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.sso_domains" */
export type Auth_Sso_Domains_Arr_Rel_Insert_Input = {
  data: Array<Auth_Sso_Domains_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Sso_Domains_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.sso_domains". All fields are combined with a logical 'AND'. */
export type Auth_Sso_Domains_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Sso_Domains_Bool_Exp>>;
  _not?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Sso_Domains_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
  sso_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.sso_domains" */
export enum Auth_Sso_Domains_Constraint {
  /** unique or primary key constraint on columns  */
  SsoDomainsDomainIdx = 'sso_domains_domain_idx',
  /** unique or primary key constraint on columns "id" */
  SsoDomainsPkey = 'sso_domains_pkey'
}

/** input type for inserting data into table "auth.sso_domains" */
export type Auth_Sso_Domains_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Obj_Rel_Insert_Input>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Sso_Domains_Max_Fields = {
  __typename?: 'auth_sso_domains_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sso_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.sso_domains" */
export type Auth_Sso_Domains_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Sso_Domains_Min_Fields = {
  __typename?: 'auth_sso_domains_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sso_provider_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.sso_domains" */
export type Auth_Sso_Domains_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.sso_domains" */
export type Auth_Sso_Domains_Mutation_Response = {
  __typename?: 'auth_sso_domains_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Sso_Domains>;
};

/** on_conflict condition type for table "auth.sso_domains" */
export type Auth_Sso_Domains_On_Conflict = {
  constraint: Auth_Sso_Domains_Constraint;
  update_columns?: Array<Auth_Sso_Domains_Update_Column>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.sso_domains". */
export type Auth_Sso_Domains_Order_By = {
  created_at?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sso_provider?: InputMaybe<Auth_Sso_Providers_Order_By>;
  sso_provider_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.sso_domains */
export type Auth_Sso_Domains_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.sso_domains" */
export enum Auth_Sso_Domains_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  SsoProviderId = 'sso_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.sso_domains" */
export type Auth_Sso_Domains_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_sso_domains" */
export type Auth_Sso_Domains_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Sso_Domains_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Sso_Domains_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sso_provider_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.sso_domains" */
export enum Auth_Sso_Domains_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  SsoProviderId = 'sso_provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Sso_Domains_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Sso_Domains_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Sso_Domains_Bool_Exp;
};

/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_Providers = {
  __typename?: 'auth_sso_providers';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** Auth: Uniquely identifies a SSO provider according to a user-chosen resource ID (case insensitive), useful in infrastructure as code. */
  resource_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  saml_providers: Array<Auth_Saml_Providers>;
  /** An aggregate relationship */
  saml_providers_aggregate: Auth_Saml_Providers_Aggregate;
  /** An array relationship */
  saml_relay_states: Array<Auth_Saml_Relay_States>;
  /** An aggregate relationship */
  saml_relay_states_aggregate: Auth_Saml_Relay_States_Aggregate;
  /** An array relationship */
  sso_domains: Array<Auth_Sso_Domains>;
  /** An aggregate relationship */
  sso_domains_aggregate: Auth_Sso_Domains_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_ProvidersSaml_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Providers_Order_By>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_ProvidersSaml_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Providers_Order_By>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_ProvidersSaml_Relay_StatesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Relay_States_Order_By>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_ProvidersSaml_Relay_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Relay_States_Order_By>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_ProvidersSso_DomainsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Domains_Order_By>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};


/** Auth: Manages SSO identity provider information; see saml_providers for SAML. */
export type Auth_Sso_ProvidersSso_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Domains_Order_By>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};

/** aggregated selection of "auth.sso_providers" */
export type Auth_Sso_Providers_Aggregate = {
  __typename?: 'auth_sso_providers_aggregate';
  aggregate?: Maybe<Auth_Sso_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Sso_Providers>;
};

/** aggregate fields of "auth.sso_providers" */
export type Auth_Sso_Providers_Aggregate_Fields = {
  __typename?: 'auth_sso_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Auth_Sso_Providers_Max_Fields>;
  min?: Maybe<Auth_Sso_Providers_Min_Fields>;
};


/** aggregate fields of "auth.sso_providers" */
export type Auth_Sso_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Sso_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.sso_providers". All fields are combined with a logical 'AND'. */
export type Auth_Sso_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Sso_Providers_Bool_Exp>>;
  _not?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Sso_Providers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  resource_id?: InputMaybe<String_Comparison_Exp>;
  saml_providers?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
  saml_providers_aggregate?: InputMaybe<Auth_Saml_Providers_Aggregate_Bool_Exp>;
  saml_relay_states?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
  saml_relay_states_aggregate?: InputMaybe<Auth_Saml_Relay_States_Aggregate_Bool_Exp>;
  sso_domains?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
  sso_domains_aggregate?: InputMaybe<Auth_Sso_Domains_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.sso_providers" */
export enum Auth_Sso_Providers_Constraint {
  /** unique or primary key constraint on columns "id" */
  SsoProvidersPkey = 'sso_providers_pkey',
  /** unique or primary key constraint on columns  */
  SsoProvidersResourceIdIdx = 'sso_providers_resource_id_idx'
}

/** input type for inserting data into table "auth.sso_providers" */
export type Auth_Sso_Providers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Auth: Uniquely identifies a SSO provider according to a user-chosen resource ID (case insensitive), useful in infrastructure as code. */
  resource_id?: InputMaybe<Scalars['String']>;
  saml_providers?: InputMaybe<Auth_Saml_Providers_Arr_Rel_Insert_Input>;
  saml_relay_states?: InputMaybe<Auth_Saml_Relay_States_Arr_Rel_Insert_Input>;
  sso_domains?: InputMaybe<Auth_Sso_Domains_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Sso_Providers_Max_Fields = {
  __typename?: 'auth_sso_providers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** Auth: Uniquely identifies a SSO provider according to a user-chosen resource ID (case insensitive), useful in infrastructure as code. */
  resource_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Auth_Sso_Providers_Min_Fields = {
  __typename?: 'auth_sso_providers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** Auth: Uniquely identifies a SSO provider according to a user-chosen resource ID (case insensitive), useful in infrastructure as code. */
  resource_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "auth.sso_providers" */
export type Auth_Sso_Providers_Mutation_Response = {
  __typename?: 'auth_sso_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Sso_Providers>;
};

/** input type for inserting object relation for remote table "auth.sso_providers" */
export type Auth_Sso_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Sso_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Sso_Providers_On_Conflict>;
};

/** on_conflict condition type for table "auth.sso_providers" */
export type Auth_Sso_Providers_On_Conflict = {
  constraint: Auth_Sso_Providers_Constraint;
  update_columns?: Array<Auth_Sso_Providers_Update_Column>;
  where?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.sso_providers". */
export type Auth_Sso_Providers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  saml_providers_aggregate?: InputMaybe<Auth_Saml_Providers_Aggregate_Order_By>;
  saml_relay_states_aggregate?: InputMaybe<Auth_Saml_Relay_States_Aggregate_Order_By>;
  sso_domains_aggregate?: InputMaybe<Auth_Sso_Domains_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.sso_providers */
export type Auth_Sso_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.sso_providers" */
export enum Auth_Sso_Providers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.sso_providers" */
export type Auth_Sso_Providers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Auth: Uniquely identifies a SSO provider according to a user-chosen resource ID (case insensitive), useful in infrastructure as code. */
  resource_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "auth_sso_providers" */
export type Auth_Sso_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Sso_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Sso_Providers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Auth: Uniquely identifies a SSO provider according to a user-chosen resource ID (case insensitive), useful in infrastructure as code. */
  resource_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.sso_providers" */
export enum Auth_Sso_Providers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Sso_Providers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Sso_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Sso_Providers_Bool_Exp;
};

/** Auth: Stores user login data within a secure schema. */
export type Auth_Users = {
  __typename?: 'auth_users';
  aud?: Maybe<Scalars['String']>;
  banned_until?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_change?: Maybe<Scalars['String']>;
  email_change_confirm_status?: Maybe<Scalars['smallint']>;
  email_change_sent_at?: Maybe<Scalars['timestamptz']>;
  email_change_token_current?: Maybe<Scalars['String']>;
  email_change_token_new?: Maybe<Scalars['String']>;
  email_confirmed_at?: Maybe<Scalars['timestamptz']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  identities: Array<Auth_Identities>;
  /** An aggregate relationship */
  identities_aggregate: Auth_Identities_Aggregate;
  instance_id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  /** Auth: Set this column to true when the account comes from SSO. These accounts can have duplicate emails. */
  is_sso_user: Scalars['Boolean'];
  is_super_admin?: Maybe<Scalars['Boolean']>;
  last_sign_in_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  mfa_factors: Array<Auth_Mfa_Factors>;
  /** An aggregate relationship */
  mfa_factors_aggregate: Auth_Mfa_Factors_Aggregate;
  phone?: Maybe<Scalars['String']>;
  phone_change?: Maybe<Scalars['String']>;
  phone_change_sent_at?: Maybe<Scalars['timestamptz']>;
  phone_change_token?: Maybe<Scalars['String']>;
  phone_confirmed_at?: Maybe<Scalars['timestamptz']>;
  raw_app_meta_data?: Maybe<Scalars['jsonb']>;
  raw_user_meta_data?: Maybe<Scalars['jsonb']>;
  reauthentication_sent_at?: Maybe<Scalars['timestamptz']>;
  reauthentication_token?: Maybe<Scalars['String']>;
  recovery_sent_at?: Maybe<Scalars['timestamptz']>;
  recovery_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  /** An array relationship */
  sessions: Array<Auth_Sessions>;
  /** An aggregate relationship */
  sessions_aggregate: Auth_Sessions_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersIdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Identities_Order_By>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersIdentities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Identities_Order_By>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersMfa_FactorsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Factors_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersMfa_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Factors_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersRaw_App_Meta_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersRaw_User_Meta_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersSessionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sessions_Order_By>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};


/** Auth: Stores user login data within a secure schema. */
export type Auth_UsersSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sessions_Order_By>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};

/** aggregated selection of "auth.users" */
export type Auth_Users_Aggregate = {
  __typename?: 'auth_users_aggregate';
  aggregate?: Maybe<Auth_Users_Aggregate_Fields>;
  nodes: Array<Auth_Users>;
};

/** aggregate fields of "auth.users" */
export type Auth_Users_Aggregate_Fields = {
  __typename?: 'auth_users_aggregate_fields';
  avg?: Maybe<Auth_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Users_Max_Fields>;
  min?: Maybe<Auth_Users_Min_Fields>;
  stddev?: Maybe<Auth_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Users_Sum_Fields>;
  var_pop?: Maybe<Auth_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Users_Var_Samp_Fields>;
  variance?: Maybe<Auth_Users_Variance_Fields>;
};


/** aggregate fields of "auth.users" */
export type Auth_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Users_Append_Input = {
  raw_app_meta_data?: InputMaybe<Scalars['jsonb']>;
  raw_user_meta_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Auth_Users_Avg_Fields = {
  __typename?: 'auth_users_avg_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Auth_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Users_Bool_Exp>>;
  _not?: InputMaybe<Auth_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Users_Bool_Exp>>;
  aud?: InputMaybe<String_Comparison_Exp>;
  banned_until?: InputMaybe<Timestamptz_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_change?: InputMaybe<String_Comparison_Exp>;
  email_change_confirm_status?: InputMaybe<Smallint_Comparison_Exp>;
  email_change_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_change_token_current?: InputMaybe<String_Comparison_Exp>;
  email_change_token_new?: InputMaybe<String_Comparison_Exp>;
  email_confirmed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identities?: InputMaybe<Auth_Identities_Bool_Exp>;
  identities_aggregate?: InputMaybe<Auth_Identities_Aggregate_Bool_Exp>;
  instance_id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_sso_user?: InputMaybe<Boolean_Comparison_Exp>;
  is_super_admin?: InputMaybe<Boolean_Comparison_Exp>;
  last_sign_in_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  mfa_factors?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
  mfa_factors_aggregate?: InputMaybe<Auth_Mfa_Factors_Aggregate_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phone_change?: InputMaybe<String_Comparison_Exp>;
  phone_change_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  phone_change_token?: InputMaybe<String_Comparison_Exp>;
  phone_confirmed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  raw_app_meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  raw_user_meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  reauthentication_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  reauthentication_token?: InputMaybe<String_Comparison_Exp>;
  recovery_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  recovery_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  sessions?: InputMaybe<Auth_Sessions_Bool_Exp>;
  sessions_aggregate?: InputMaybe<Auth_Sessions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.users" */
export enum Auth_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  ConfirmationTokenIdx = 'confirmation_token_idx',
  /** unique or primary key constraint on columns "email_change_token_current" */
  EmailChangeTokenCurrentIdx = 'email_change_token_current_idx',
  /** unique or primary key constraint on columns "email_change_token_new" */
  EmailChangeTokenNewIdx = 'email_change_token_new_idx',
  /** unique or primary key constraint on columns "reauthentication_token" */
  ReauthenticationTokenIdx = 'reauthentication_token_idx',
  /** unique or primary key constraint on columns "recovery_token" */
  RecoveryTokenIdx = 'recovery_token_idx',
  /** unique or primary key constraint on columns "email" */
  UsersEmailPartialKey = 'users_email_partial_key',
  /** unique or primary key constraint on columns "phone" */
  UsersPhoneKey = 'users_phone_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Users_Delete_At_Path_Input = {
  raw_app_meta_data?: InputMaybe<Array<Scalars['String']>>;
  raw_user_meta_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Users_Delete_Elem_Input = {
  raw_app_meta_data?: InputMaybe<Scalars['Int']>;
  raw_user_meta_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Users_Delete_Key_Input = {
  raw_app_meta_data?: InputMaybe<Scalars['String']>;
  raw_user_meta_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "auth.users" */
export type Auth_Users_Inc_Input = {
  email_change_confirm_status?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "auth.users" */
export type Auth_Users_Insert_Input = {
  aud?: InputMaybe<Scalars['String']>;
  banned_until?: InputMaybe<Scalars['timestamptz']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamptz']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_change?: InputMaybe<Scalars['String']>;
  email_change_confirm_status?: InputMaybe<Scalars['smallint']>;
  email_change_sent_at?: InputMaybe<Scalars['timestamptz']>;
  email_change_token_current?: InputMaybe<Scalars['String']>;
  email_change_token_new?: InputMaybe<Scalars['String']>;
  email_confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identities?: InputMaybe<Auth_Identities_Arr_Rel_Insert_Input>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  /** Auth: Set this column to true when the account comes from SSO. These accounts can have duplicate emails. */
  is_sso_user?: InputMaybe<Scalars['Boolean']>;
  is_super_admin?: InputMaybe<Scalars['Boolean']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamptz']>;
  mfa_factors?: InputMaybe<Auth_Mfa_Factors_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  phone_change?: InputMaybe<Scalars['String']>;
  phone_change_sent_at?: InputMaybe<Scalars['timestamptz']>;
  phone_change_token?: InputMaybe<Scalars['String']>;
  phone_confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  raw_app_meta_data?: InputMaybe<Scalars['jsonb']>;
  raw_user_meta_data?: InputMaybe<Scalars['jsonb']>;
  reauthentication_sent_at?: InputMaybe<Scalars['timestamptz']>;
  reauthentication_token?: InputMaybe<Scalars['String']>;
  recovery_sent_at?: InputMaybe<Scalars['timestamptz']>;
  recovery_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<Auth_Sessions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Users_Max_Fields = {
  __typename?: 'auth_users_max_fields';
  aud?: Maybe<Scalars['String']>;
  banned_until?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_change?: Maybe<Scalars['String']>;
  email_change_confirm_status?: Maybe<Scalars['smallint']>;
  email_change_sent_at?: Maybe<Scalars['timestamptz']>;
  email_change_token_current?: Maybe<Scalars['String']>;
  email_change_token_new?: Maybe<Scalars['String']>;
  email_confirmed_at?: Maybe<Scalars['timestamptz']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instance_id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  last_sign_in_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  phone_change?: Maybe<Scalars['String']>;
  phone_change_sent_at?: Maybe<Scalars['timestamptz']>;
  phone_change_token?: Maybe<Scalars['String']>;
  phone_confirmed_at?: Maybe<Scalars['timestamptz']>;
  reauthentication_sent_at?: Maybe<Scalars['timestamptz']>;
  reauthentication_token?: Maybe<Scalars['String']>;
  recovery_sent_at?: Maybe<Scalars['timestamptz']>;
  recovery_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Auth_Users_Min_Fields = {
  __typename?: 'auth_users_min_fields';
  aud?: Maybe<Scalars['String']>;
  banned_until?: Maybe<Scalars['timestamptz']>;
  confirmation_sent_at?: Maybe<Scalars['timestamptz']>;
  confirmation_token?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_change?: Maybe<Scalars['String']>;
  email_change_confirm_status?: Maybe<Scalars['smallint']>;
  email_change_sent_at?: Maybe<Scalars['timestamptz']>;
  email_change_token_current?: Maybe<Scalars['String']>;
  email_change_token_new?: Maybe<Scalars['String']>;
  email_confirmed_at?: Maybe<Scalars['timestamptz']>;
  encrypted_password?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instance_id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  last_sign_in_at?: Maybe<Scalars['timestamptz']>;
  phone?: Maybe<Scalars['String']>;
  phone_change?: Maybe<Scalars['String']>;
  phone_change_sent_at?: Maybe<Scalars['timestamptz']>;
  phone_change_token?: Maybe<Scalars['String']>;
  phone_confirmed_at?: Maybe<Scalars['timestamptz']>;
  reauthentication_sent_at?: Maybe<Scalars['timestamptz']>;
  reauthentication_token?: Maybe<Scalars['String']>;
  recovery_sent_at?: Maybe<Scalars['timestamptz']>;
  recovery_token?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "auth.users" */
export type Auth_Users_Mutation_Response = {
  __typename?: 'auth_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Users>;
};

/** input type for inserting object relation for remote table "auth.users" */
export type Auth_Users_Obj_Rel_Insert_Input = {
  data: Auth_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};

/** on_conflict condition type for table "auth.users" */
export type Auth_Users_On_Conflict = {
  constraint: Auth_Users_Constraint;
  update_columns?: Array<Auth_Users_Update_Column>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Auth_Users_Order_By = {
  aud?: InputMaybe<Order_By>;
  banned_until?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_change?: InputMaybe<Order_By>;
  email_change_confirm_status?: InputMaybe<Order_By>;
  email_change_sent_at?: InputMaybe<Order_By>;
  email_change_token_current?: InputMaybe<Order_By>;
  email_change_token_new?: InputMaybe<Order_By>;
  email_confirmed_at?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identities_aggregate?: InputMaybe<Auth_Identities_Aggregate_Order_By>;
  instance_id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  is_sso_user?: InputMaybe<Order_By>;
  is_super_admin?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  mfa_factors_aggregate?: InputMaybe<Auth_Mfa_Factors_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_change?: InputMaybe<Order_By>;
  phone_change_sent_at?: InputMaybe<Order_By>;
  phone_change_token?: InputMaybe<Order_By>;
  phone_confirmed_at?: InputMaybe<Order_By>;
  raw_app_meta_data?: InputMaybe<Order_By>;
  raw_user_meta_data?: InputMaybe<Order_By>;
  reauthentication_sent_at?: InputMaybe<Order_By>;
  reauthentication_token?: InputMaybe<Order_By>;
  recovery_sent_at?: InputMaybe<Order_By>;
  recovery_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  sessions_aggregate?: InputMaybe<Auth_Sessions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.users */
export type Auth_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Users_Prepend_Input = {
  raw_app_meta_data?: InputMaybe<Scalars['jsonb']>;
  raw_user_meta_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "auth.users" */
export enum Auth_Users_Select_Column {
  /** column name */
  Aud = 'aud',
  /** column name */
  BannedUntil = 'banned_until',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailChange = 'email_change',
  /** column name */
  EmailChangeConfirmStatus = 'email_change_confirm_status',
  /** column name */
  EmailChangeSentAt = 'email_change_sent_at',
  /** column name */
  EmailChangeTokenCurrent = 'email_change_token_current',
  /** column name */
  EmailChangeTokenNew = 'email_change_token_new',
  /** column name */
  EmailConfirmedAt = 'email_confirmed_at',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  IsSsoUser = 'is_sso_user',
  /** column name */
  IsSuperAdmin = 'is_super_admin',
  /** column name */
  LastSignInAt = 'last_sign_in_at',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneChange = 'phone_change',
  /** column name */
  PhoneChangeSentAt = 'phone_change_sent_at',
  /** column name */
  PhoneChangeToken = 'phone_change_token',
  /** column name */
  PhoneConfirmedAt = 'phone_confirmed_at',
  /** column name */
  RawAppMetaData = 'raw_app_meta_data',
  /** column name */
  RawUserMetaData = 'raw_user_meta_data',
  /** column name */
  ReauthenticationSentAt = 'reauthentication_sent_at',
  /** column name */
  ReauthenticationToken = 'reauthentication_token',
  /** column name */
  RecoverySentAt = 'recovery_sent_at',
  /** column name */
  RecoveryToken = 'recovery_token',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.users" */
export type Auth_Users_Set_Input = {
  aud?: InputMaybe<Scalars['String']>;
  banned_until?: InputMaybe<Scalars['timestamptz']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamptz']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_change?: InputMaybe<Scalars['String']>;
  email_change_confirm_status?: InputMaybe<Scalars['smallint']>;
  email_change_sent_at?: InputMaybe<Scalars['timestamptz']>;
  email_change_token_current?: InputMaybe<Scalars['String']>;
  email_change_token_new?: InputMaybe<Scalars['String']>;
  email_confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  /** Auth: Set this column to true when the account comes from SSO. These accounts can have duplicate emails. */
  is_sso_user?: InputMaybe<Scalars['Boolean']>;
  is_super_admin?: InputMaybe<Scalars['Boolean']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamptz']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_change?: InputMaybe<Scalars['String']>;
  phone_change_sent_at?: InputMaybe<Scalars['timestamptz']>;
  phone_change_token?: InputMaybe<Scalars['String']>;
  phone_confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  raw_app_meta_data?: InputMaybe<Scalars['jsonb']>;
  raw_user_meta_data?: InputMaybe<Scalars['jsonb']>;
  reauthentication_sent_at?: InputMaybe<Scalars['timestamptz']>;
  reauthentication_token?: InputMaybe<Scalars['String']>;
  recovery_sent_at?: InputMaybe<Scalars['timestamptz']>;
  recovery_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auth_Users_Stddev_Fields = {
  __typename?: 'auth_users_stddev_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Users_Stddev_Pop_Fields = {
  __typename?: 'auth_users_stddev_pop_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Users_Stddev_Samp_Fields = {
  __typename?: 'auth_users_stddev_samp_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "auth_users" */
export type Auth_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Users_Stream_Cursor_Value_Input = {
  aud?: InputMaybe<Scalars['String']>;
  banned_until?: InputMaybe<Scalars['timestamptz']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamptz']>;
  confirmation_token?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_change?: InputMaybe<Scalars['String']>;
  email_change_confirm_status?: InputMaybe<Scalars['smallint']>;
  email_change_sent_at?: InputMaybe<Scalars['timestamptz']>;
  email_change_token_current?: InputMaybe<Scalars['String']>;
  email_change_token_new?: InputMaybe<Scalars['String']>;
  email_confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_password?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  /** Auth: Set this column to true when the account comes from SSO. These accounts can have duplicate emails. */
  is_sso_user?: InputMaybe<Scalars['Boolean']>;
  is_super_admin?: InputMaybe<Scalars['Boolean']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamptz']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_change?: InputMaybe<Scalars['String']>;
  phone_change_sent_at?: InputMaybe<Scalars['timestamptz']>;
  phone_change_token?: InputMaybe<Scalars['String']>;
  phone_confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  raw_app_meta_data?: InputMaybe<Scalars['jsonb']>;
  raw_user_meta_data?: InputMaybe<Scalars['jsonb']>;
  reauthentication_sent_at?: InputMaybe<Scalars['timestamptz']>;
  reauthentication_token?: InputMaybe<Scalars['String']>;
  recovery_sent_at?: InputMaybe<Scalars['timestamptz']>;
  recovery_token?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Auth_Users_Sum_Fields = {
  __typename?: 'auth_users_sum_fields';
  email_change_confirm_status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "auth.users" */
export enum Auth_Users_Update_Column {
  /** column name */
  Aud = 'aud',
  /** column name */
  BannedUntil = 'banned_until',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailChange = 'email_change',
  /** column name */
  EmailChangeConfirmStatus = 'email_change_confirm_status',
  /** column name */
  EmailChangeSentAt = 'email_change_sent_at',
  /** column name */
  EmailChangeTokenCurrent = 'email_change_token_current',
  /** column name */
  EmailChangeTokenNew = 'email_change_token_new',
  /** column name */
  EmailConfirmedAt = 'email_confirmed_at',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  IsSsoUser = 'is_sso_user',
  /** column name */
  IsSuperAdmin = 'is_super_admin',
  /** column name */
  LastSignInAt = 'last_sign_in_at',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneChange = 'phone_change',
  /** column name */
  PhoneChangeSentAt = 'phone_change_sent_at',
  /** column name */
  PhoneChangeToken = 'phone_change_token',
  /** column name */
  PhoneConfirmedAt = 'phone_confirmed_at',
  /** column name */
  RawAppMetaData = 'raw_app_meta_data',
  /** column name */
  RawUserMetaData = 'raw_user_meta_data',
  /** column name */
  ReauthenticationSentAt = 'reauthentication_sent_at',
  /** column name */
  ReauthenticationToken = 'reauthentication_token',
  /** column name */
  RecoverySentAt = 'recovery_sent_at',
  /** column name */
  RecoveryToken = 'recovery_token',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Auth_Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Auth_Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Auth_Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Auth_Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Auth_Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Users_Var_Pop_Fields = {
  __typename?: 'auth_users_var_pop_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Auth_Users_Var_Samp_Fields = {
  __typename?: 'auth_users_var_samp_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Auth_Users_Variance_Fields = {
  __typename?: 'auth_users_variance_fields';
  email_change_confirm_status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename?: 'contacts';
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  subject: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: 'contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Contacts_Bool_Exp>>;
  _not?: InputMaybe<Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Contacts_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = 'contacts_pkey'
}

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  subject?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: 'contacts_max_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: 'contacts_min_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: 'contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contacts>;
};

/** on_conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns?: Array<Contacts_Update_Column>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Contacts_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  subject?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contacts" */
export type Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contacts_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  subject?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contacts_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "factor_status". All fields are combined with logical 'AND'. */
export type Factor_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['factor_status']>;
  _gt?: InputMaybe<Scalars['factor_status']>;
  _gte?: InputMaybe<Scalars['factor_status']>;
  _in?: InputMaybe<Array<Scalars['factor_status']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['factor_status']>;
  _lte?: InputMaybe<Scalars['factor_status']>;
  _neq?: InputMaybe<Scalars['factor_status']>;
  _nin?: InputMaybe<Array<Scalars['factor_status']>>;
};

/** Boolean expression to compare columns of type "factor_type". All fields are combined with logical 'AND'. */
export type Factor_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['factor_type']>;
  _gt?: InputMaybe<Scalars['factor_type']>;
  _gte?: InputMaybe<Scalars['factor_type']>;
  _in?: InputMaybe<Array<Scalars['factor_type']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['factor_type']>;
  _lte?: InputMaybe<Scalars['factor_type']>;
  _neq?: InputMaybe<Scalars['factor_type']>;
  _nin?: InputMaybe<Array<Scalars['factor_type']>>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['inet']>;
  _gt?: InputMaybe<Scalars['inet']>;
  _gte?: InputMaybe<Scalars['inet']>;
  _in?: InputMaybe<Array<Scalars['inet']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['inet']>;
  _lte?: InputMaybe<Scalars['inet']>;
  _neq?: InputMaybe<Scalars['inet']>;
  _nin?: InputMaybe<Array<Scalars['inet']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "_prisma_migrations" */
  delete__prisma_migrations?: Maybe<_Prisma_Migrations_Mutation_Response>;
  /** delete single row from the table: "_prisma_migrations" */
  delete__prisma_migrations_by_pk?: Maybe<_Prisma_Migrations>;
  /** delete data from the table: "answers" */
  delete_answers?: Maybe<Answers_Mutation_Response>;
  /** delete single row from the table: "answers" */
  delete_answers_by_pk?: Maybe<Answers>;
  /** delete data from the table: "auth.audit_log_entries" */
  delete_auth_audit_log_entries?: Maybe<Auth_Audit_Log_Entries_Mutation_Response>;
  /** delete single row from the table: "auth.audit_log_entries" */
  delete_auth_audit_log_entries_by_pk?: Maybe<Auth_Audit_Log_Entries>;
  /** delete data from the table: "auth.identities" */
  delete_auth_identities?: Maybe<Auth_Identities_Mutation_Response>;
  /** delete single row from the table: "auth.identities" */
  delete_auth_identities_by_pk?: Maybe<Auth_Identities>;
  /** delete data from the table: "auth.instances" */
  delete_auth_instances?: Maybe<Auth_Instances_Mutation_Response>;
  /** delete single row from the table: "auth.instances" */
  delete_auth_instances_by_pk?: Maybe<Auth_Instances>;
  /** delete data from the table: "auth.mfa_amr_claims" */
  delete_auth_mfa_amr_claims?: Maybe<Auth_Mfa_Amr_Claims_Mutation_Response>;
  /** delete single row from the table: "auth.mfa_amr_claims" */
  delete_auth_mfa_amr_claims_by_pk?: Maybe<Auth_Mfa_Amr_Claims>;
  /** delete data from the table: "auth.mfa_challenges" */
  delete_auth_mfa_challenges?: Maybe<Auth_Mfa_Challenges_Mutation_Response>;
  /** delete single row from the table: "auth.mfa_challenges" */
  delete_auth_mfa_challenges_by_pk?: Maybe<Auth_Mfa_Challenges>;
  /** delete data from the table: "auth.mfa_factors" */
  delete_auth_mfa_factors?: Maybe<Auth_Mfa_Factors_Mutation_Response>;
  /** delete single row from the table: "auth.mfa_factors" */
  delete_auth_mfa_factors_by_pk?: Maybe<Auth_Mfa_Factors>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** delete data from the table: "auth.saml_providers" */
  delete_auth_saml_providers?: Maybe<Auth_Saml_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.saml_providers" */
  delete_auth_saml_providers_by_pk?: Maybe<Auth_Saml_Providers>;
  /** delete data from the table: "auth.saml_relay_states" */
  delete_auth_saml_relay_states?: Maybe<Auth_Saml_Relay_States_Mutation_Response>;
  /** delete single row from the table: "auth.saml_relay_states" */
  delete_auth_saml_relay_states_by_pk?: Maybe<Auth_Saml_Relay_States>;
  /** delete data from the table: "auth.schema_migrations" */
  delete_auth_schema_migrations?: Maybe<Auth_Schema_Migrations_Mutation_Response>;
  /** delete single row from the table: "auth.schema_migrations" */
  delete_auth_schema_migrations_by_pk?: Maybe<Auth_Schema_Migrations>;
  /** delete data from the table: "auth.sessions" */
  delete_auth_sessions?: Maybe<Auth_Sessions_Mutation_Response>;
  /** delete single row from the table: "auth.sessions" */
  delete_auth_sessions_by_pk?: Maybe<Auth_Sessions>;
  /** delete data from the table: "auth.sso_domains" */
  delete_auth_sso_domains?: Maybe<Auth_Sso_Domains_Mutation_Response>;
  /** delete single row from the table: "auth.sso_domains" */
  delete_auth_sso_domains_by_pk?: Maybe<Auth_Sso_Domains>;
  /** delete data from the table: "auth.sso_providers" */
  delete_auth_sso_providers?: Maybe<Auth_Sso_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.sso_providers" */
  delete_auth_sso_providers_by_pk?: Maybe<Auth_Sso_Providers>;
  /** delete data from the table: "auth.users" */
  delete_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** delete single row from the table: "auth.users" */
  delete_auth_users_by_pk?: Maybe<Auth_Users>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "official_questions" */
  delete_official_questions?: Maybe<Official_Questions_Mutation_Response>;
  /** delete single row from the table: "official_questions" */
  delete_official_questions_by_pk?: Maybe<Official_Questions>;
  /** delete data from the table: "payments" */
  delete_payments?: Maybe<Payments_Mutation_Response>;
  /** delete single row from the table: "payments" */
  delete_payments_by_pk?: Maybe<Payments>;
  /** delete data from the table: "purchased_questions" */
  delete_purchased_questions?: Maybe<Purchased_Questions_Mutation_Response>;
  /** delete single row from the table: "purchased_questions" */
  delete_purchased_questions_by_pk?: Maybe<Purchased_Questions>;
  /** delete data from the table: "question_statuses" */
  delete_question_statuses?: Maybe<Question_Statuses_Mutation_Response>;
  /** delete single row from the table: "question_statuses" */
  delete_question_statuses_by_pk?: Maybe<Question_Statuses>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "ratings" */
  delete_ratings?: Maybe<Ratings_Mutation_Response>;
  /** delete single row from the table: "ratings" */
  delete_ratings_by_pk?: Maybe<Ratings>;
  /** delete data from the table: "reports" */
  delete_reports?: Maybe<Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "_prisma_migrations" */
  insert__prisma_migrations?: Maybe<_Prisma_Migrations_Mutation_Response>;
  /** insert a single row into the table: "_prisma_migrations" */
  insert__prisma_migrations_one?: Maybe<_Prisma_Migrations>;
  /** insert data into the table: "answers" */
  insert_answers?: Maybe<Answers_Mutation_Response>;
  /** insert a single row into the table: "answers" */
  insert_answers_one?: Maybe<Answers>;
  /** insert data into the table: "auth.audit_log_entries" */
  insert_auth_audit_log_entries?: Maybe<Auth_Audit_Log_Entries_Mutation_Response>;
  /** insert a single row into the table: "auth.audit_log_entries" */
  insert_auth_audit_log_entries_one?: Maybe<Auth_Audit_Log_Entries>;
  /** insert data into the table: "auth.identities" */
  insert_auth_identities?: Maybe<Auth_Identities_Mutation_Response>;
  /** insert a single row into the table: "auth.identities" */
  insert_auth_identities_one?: Maybe<Auth_Identities>;
  /** insert data into the table: "auth.instances" */
  insert_auth_instances?: Maybe<Auth_Instances_Mutation_Response>;
  /** insert a single row into the table: "auth.instances" */
  insert_auth_instances_one?: Maybe<Auth_Instances>;
  /** insert data into the table: "auth.mfa_amr_claims" */
  insert_auth_mfa_amr_claims?: Maybe<Auth_Mfa_Amr_Claims_Mutation_Response>;
  /** insert a single row into the table: "auth.mfa_amr_claims" */
  insert_auth_mfa_amr_claims_one?: Maybe<Auth_Mfa_Amr_Claims>;
  /** insert data into the table: "auth.mfa_challenges" */
  insert_auth_mfa_challenges?: Maybe<Auth_Mfa_Challenges_Mutation_Response>;
  /** insert a single row into the table: "auth.mfa_challenges" */
  insert_auth_mfa_challenges_one?: Maybe<Auth_Mfa_Challenges>;
  /** insert data into the table: "auth.mfa_factors" */
  insert_auth_mfa_factors?: Maybe<Auth_Mfa_Factors_Mutation_Response>;
  /** insert a single row into the table: "auth.mfa_factors" */
  insert_auth_mfa_factors_one?: Maybe<Auth_Mfa_Factors>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<Auth_Refresh_Tokens>;
  /** insert data into the table: "auth.saml_providers" */
  insert_auth_saml_providers?: Maybe<Auth_Saml_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.saml_providers" */
  insert_auth_saml_providers_one?: Maybe<Auth_Saml_Providers>;
  /** insert data into the table: "auth.saml_relay_states" */
  insert_auth_saml_relay_states?: Maybe<Auth_Saml_Relay_States_Mutation_Response>;
  /** insert a single row into the table: "auth.saml_relay_states" */
  insert_auth_saml_relay_states_one?: Maybe<Auth_Saml_Relay_States>;
  /** insert data into the table: "auth.schema_migrations" */
  insert_auth_schema_migrations?: Maybe<Auth_Schema_Migrations_Mutation_Response>;
  /** insert a single row into the table: "auth.schema_migrations" */
  insert_auth_schema_migrations_one?: Maybe<Auth_Schema_Migrations>;
  /** insert data into the table: "auth.sessions" */
  insert_auth_sessions?: Maybe<Auth_Sessions_Mutation_Response>;
  /** insert a single row into the table: "auth.sessions" */
  insert_auth_sessions_one?: Maybe<Auth_Sessions>;
  /** insert data into the table: "auth.sso_domains" */
  insert_auth_sso_domains?: Maybe<Auth_Sso_Domains_Mutation_Response>;
  /** insert a single row into the table: "auth.sso_domains" */
  insert_auth_sso_domains_one?: Maybe<Auth_Sso_Domains>;
  /** insert data into the table: "auth.sso_providers" */
  insert_auth_sso_providers?: Maybe<Auth_Sso_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.sso_providers" */
  insert_auth_sso_providers_one?: Maybe<Auth_Sso_Providers>;
  /** insert data into the table: "auth.users" */
  insert_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** insert a single row into the table: "auth.users" */
  insert_auth_users_one?: Maybe<Auth_Users>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "official_questions" */
  insert_official_questions?: Maybe<Official_Questions_Mutation_Response>;
  /** insert a single row into the table: "official_questions" */
  insert_official_questions_one?: Maybe<Official_Questions>;
  /** insert data into the table: "payments" */
  insert_payments?: Maybe<Payments_Mutation_Response>;
  /** insert a single row into the table: "payments" */
  insert_payments_one?: Maybe<Payments>;
  /** insert data into the table: "purchased_questions" */
  insert_purchased_questions?: Maybe<Purchased_Questions_Mutation_Response>;
  /** insert a single row into the table: "purchased_questions" */
  insert_purchased_questions_one?: Maybe<Purchased_Questions>;
  /** insert data into the table: "question_statuses" */
  insert_question_statuses?: Maybe<Question_Statuses_Mutation_Response>;
  /** insert a single row into the table: "question_statuses" */
  insert_question_statuses_one?: Maybe<Question_Statuses>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "ratings" */
  insert_ratings?: Maybe<Ratings_Mutation_Response>;
  /** insert a single row into the table: "ratings" */
  insert_ratings_one?: Maybe<Ratings>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "_prisma_migrations" */
  update__prisma_migrations?: Maybe<_Prisma_Migrations_Mutation_Response>;
  /** update single row of the table: "_prisma_migrations" */
  update__prisma_migrations_by_pk?: Maybe<_Prisma_Migrations>;
  /** update multiples rows of table: "_prisma_migrations" */
  update__prisma_migrations_many?: Maybe<Array<Maybe<_Prisma_Migrations_Mutation_Response>>>;
  /** update data of the table: "answers" */
  update_answers?: Maybe<Answers_Mutation_Response>;
  /** update single row of the table: "answers" */
  update_answers_by_pk?: Maybe<Answers>;
  /** update multiples rows of table: "answers" */
  update_answers_many?: Maybe<Array<Maybe<Answers_Mutation_Response>>>;
  /** update data of the table: "auth.audit_log_entries" */
  update_auth_audit_log_entries?: Maybe<Auth_Audit_Log_Entries_Mutation_Response>;
  /** update single row of the table: "auth.audit_log_entries" */
  update_auth_audit_log_entries_by_pk?: Maybe<Auth_Audit_Log_Entries>;
  /** update multiples rows of table: "auth.audit_log_entries" */
  update_auth_audit_log_entries_many?: Maybe<Array<Maybe<Auth_Audit_Log_Entries_Mutation_Response>>>;
  /** update data of the table: "auth.identities" */
  update_auth_identities?: Maybe<Auth_Identities_Mutation_Response>;
  /** update single row of the table: "auth.identities" */
  update_auth_identities_by_pk?: Maybe<Auth_Identities>;
  /** update multiples rows of table: "auth.identities" */
  update_auth_identities_many?: Maybe<Array<Maybe<Auth_Identities_Mutation_Response>>>;
  /** update data of the table: "auth.instances" */
  update_auth_instances?: Maybe<Auth_Instances_Mutation_Response>;
  /** update single row of the table: "auth.instances" */
  update_auth_instances_by_pk?: Maybe<Auth_Instances>;
  /** update multiples rows of table: "auth.instances" */
  update_auth_instances_many?: Maybe<Array<Maybe<Auth_Instances_Mutation_Response>>>;
  /** update data of the table: "auth.mfa_amr_claims" */
  update_auth_mfa_amr_claims?: Maybe<Auth_Mfa_Amr_Claims_Mutation_Response>;
  /** update single row of the table: "auth.mfa_amr_claims" */
  update_auth_mfa_amr_claims_by_pk?: Maybe<Auth_Mfa_Amr_Claims>;
  /** update multiples rows of table: "auth.mfa_amr_claims" */
  update_auth_mfa_amr_claims_many?: Maybe<Array<Maybe<Auth_Mfa_Amr_Claims_Mutation_Response>>>;
  /** update data of the table: "auth.mfa_challenges" */
  update_auth_mfa_challenges?: Maybe<Auth_Mfa_Challenges_Mutation_Response>;
  /** update single row of the table: "auth.mfa_challenges" */
  update_auth_mfa_challenges_by_pk?: Maybe<Auth_Mfa_Challenges>;
  /** update multiples rows of table: "auth.mfa_challenges" */
  update_auth_mfa_challenges_many?: Maybe<Array<Maybe<Auth_Mfa_Challenges_Mutation_Response>>>;
  /** update data of the table: "auth.mfa_factors" */
  update_auth_mfa_factors?: Maybe<Auth_Mfa_Factors_Mutation_Response>;
  /** update single row of the table: "auth.mfa_factors" */
  update_auth_mfa_factors_by_pk?: Maybe<Auth_Mfa_Factors>;
  /** update multiples rows of table: "auth.mfa_factors" */
  update_auth_mfa_factors_many?: Maybe<Array<Maybe<Auth_Mfa_Factors_Mutation_Response>>>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_many?: Maybe<Array<Maybe<Auth_Refresh_Tokens_Mutation_Response>>>;
  /** update data of the table: "auth.saml_providers" */
  update_auth_saml_providers?: Maybe<Auth_Saml_Providers_Mutation_Response>;
  /** update single row of the table: "auth.saml_providers" */
  update_auth_saml_providers_by_pk?: Maybe<Auth_Saml_Providers>;
  /** update multiples rows of table: "auth.saml_providers" */
  update_auth_saml_providers_many?: Maybe<Array<Maybe<Auth_Saml_Providers_Mutation_Response>>>;
  /** update data of the table: "auth.saml_relay_states" */
  update_auth_saml_relay_states?: Maybe<Auth_Saml_Relay_States_Mutation_Response>;
  /** update single row of the table: "auth.saml_relay_states" */
  update_auth_saml_relay_states_by_pk?: Maybe<Auth_Saml_Relay_States>;
  /** update multiples rows of table: "auth.saml_relay_states" */
  update_auth_saml_relay_states_many?: Maybe<Array<Maybe<Auth_Saml_Relay_States_Mutation_Response>>>;
  /** update data of the table: "auth.schema_migrations" */
  update_auth_schema_migrations?: Maybe<Auth_Schema_Migrations_Mutation_Response>;
  /** update single row of the table: "auth.schema_migrations" */
  update_auth_schema_migrations_by_pk?: Maybe<Auth_Schema_Migrations>;
  /** update multiples rows of table: "auth.schema_migrations" */
  update_auth_schema_migrations_many?: Maybe<Array<Maybe<Auth_Schema_Migrations_Mutation_Response>>>;
  /** update data of the table: "auth.sessions" */
  update_auth_sessions?: Maybe<Auth_Sessions_Mutation_Response>;
  /** update single row of the table: "auth.sessions" */
  update_auth_sessions_by_pk?: Maybe<Auth_Sessions>;
  /** update multiples rows of table: "auth.sessions" */
  update_auth_sessions_many?: Maybe<Array<Maybe<Auth_Sessions_Mutation_Response>>>;
  /** update data of the table: "auth.sso_domains" */
  update_auth_sso_domains?: Maybe<Auth_Sso_Domains_Mutation_Response>;
  /** update single row of the table: "auth.sso_domains" */
  update_auth_sso_domains_by_pk?: Maybe<Auth_Sso_Domains>;
  /** update multiples rows of table: "auth.sso_domains" */
  update_auth_sso_domains_many?: Maybe<Array<Maybe<Auth_Sso_Domains_Mutation_Response>>>;
  /** update data of the table: "auth.sso_providers" */
  update_auth_sso_providers?: Maybe<Auth_Sso_Providers_Mutation_Response>;
  /** update single row of the table: "auth.sso_providers" */
  update_auth_sso_providers_by_pk?: Maybe<Auth_Sso_Providers>;
  /** update multiples rows of table: "auth.sso_providers" */
  update_auth_sso_providers_many?: Maybe<Array<Maybe<Auth_Sso_Providers_Mutation_Response>>>;
  /** update data of the table: "auth.users" */
  update_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** update single row of the table: "auth.users" */
  update_auth_users_by_pk?: Maybe<Auth_Users>;
  /** update multiples rows of table: "auth.users" */
  update_auth_users_many?: Maybe<Array<Maybe<Auth_Users_Mutation_Response>>>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update multiples rows of table: "contacts" */
  update_contacts_many?: Maybe<Array<Maybe<Contacts_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "official_questions" */
  update_official_questions?: Maybe<Official_Questions_Mutation_Response>;
  /** update single row of the table: "official_questions" */
  update_official_questions_by_pk?: Maybe<Official_Questions>;
  /** update multiples rows of table: "official_questions" */
  update_official_questions_many?: Maybe<Array<Maybe<Official_Questions_Mutation_Response>>>;
  /** update data of the table: "payments" */
  update_payments?: Maybe<Payments_Mutation_Response>;
  /** update single row of the table: "payments" */
  update_payments_by_pk?: Maybe<Payments>;
  /** update multiples rows of table: "payments" */
  update_payments_many?: Maybe<Array<Maybe<Payments_Mutation_Response>>>;
  /** update data of the table: "purchased_questions" */
  update_purchased_questions?: Maybe<Purchased_Questions_Mutation_Response>;
  /** update single row of the table: "purchased_questions" */
  update_purchased_questions_by_pk?: Maybe<Purchased_Questions>;
  /** update multiples rows of table: "purchased_questions" */
  update_purchased_questions_many?: Maybe<Array<Maybe<Purchased_Questions_Mutation_Response>>>;
  /** update data of the table: "question_statuses" */
  update_question_statuses?: Maybe<Question_Statuses_Mutation_Response>;
  /** update single row of the table: "question_statuses" */
  update_question_statuses_by_pk?: Maybe<Question_Statuses>;
  /** update multiples rows of table: "question_statuses" */
  update_question_statuses_many?: Maybe<Array<Maybe<Question_Statuses_Mutation_Response>>>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<Questions_Mutation_Response>>>;
  /** update data of the table: "ratings" */
  update_ratings?: Maybe<Ratings_Mutation_Response>;
  /** update single row of the table: "ratings" */
  update_ratings_by_pk?: Maybe<Ratings>;
  /** update multiples rows of table: "ratings" */
  update_ratings_many?: Maybe<Array<Maybe<Ratings_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<Reports_Mutation_Response>>>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update multiples rows of table: "user_roles" */
  update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete__Prisma_MigrationsArgs = {
  where: _Prisma_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete__Prisma_Migrations_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AnswersArgs = {
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Audit_Log_EntriesArgs = {
  where: Auth_Audit_Log_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Audit_Log_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_IdentitiesArgs = {
  where: Auth_Identities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Identities_By_PkArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_InstancesArgs = {
  where: Auth_Instances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Instances_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Mfa_Amr_ClaimsArgs = {
  where: Auth_Mfa_Amr_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Mfa_Amr_Claims_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Mfa_ChallengesArgs = {
  where: Auth_Mfa_Challenges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Mfa_Challenges_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Mfa_FactorsArgs = {
  where: Auth_Mfa_Factors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Mfa_Factors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_TokensArgs = {
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_Tokens_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Saml_ProvidersArgs = {
  where: Auth_Saml_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Saml_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Saml_Relay_StatesArgs = {
  where: Auth_Saml_Relay_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Saml_Relay_States_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Schema_MigrationsArgs = {
  where: Auth_Schema_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Schema_Migrations_By_PkArgs = {
  version: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_SessionsArgs = {
  where: Auth_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Sso_DomainsArgs = {
  where: Auth_Sso_Domains_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Sso_Domains_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Sso_ProvidersArgs = {
  where: Auth_Sso_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Sso_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_UsersArgs = {
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Official_QuestionsArgs = {
  where: Official_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Official_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentsArgs = {
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Purchased_QuestionsArgs = {
  where: Purchased_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Purchased_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Question_StatusesArgs = {
  where: Question_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_Statuses_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RatingsArgs = {
  where: Ratings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ratings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReportsArgs = {
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reports_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert__Prisma_MigrationsArgs = {
  objects: Array<_Prisma_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<_Prisma_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert__Prisma_Migrations_OneArgs = {
  object: _Prisma_Migrations_Insert_Input;
  on_conflict?: InputMaybe<_Prisma_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AnswersArgs = {
  objects: Array<Answers_Insert_Input>;
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Answers_OneArgs = {
  object: Answers_Insert_Input;
  on_conflict?: InputMaybe<Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Audit_Log_EntriesArgs = {
  objects: Array<Auth_Audit_Log_Entries_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Audit_Log_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Audit_Log_Entries_OneArgs = {
  object: Auth_Audit_Log_Entries_Insert_Input;
  on_conflict?: InputMaybe<Auth_Audit_Log_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_IdentitiesArgs = {
  objects: Array<Auth_Identities_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Identities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Identities_OneArgs = {
  object: Auth_Identities_Insert_Input;
  on_conflict?: InputMaybe<Auth_Identities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_InstancesArgs = {
  objects: Array<Auth_Instances_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Instances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Instances_OneArgs = {
  object: Auth_Instances_Insert_Input;
  on_conflict?: InputMaybe<Auth_Instances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Mfa_Amr_ClaimsArgs = {
  objects: Array<Auth_Mfa_Amr_Claims_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Mfa_Amr_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Mfa_Amr_Claims_OneArgs = {
  object: Auth_Mfa_Amr_Claims_Insert_Input;
  on_conflict?: InputMaybe<Auth_Mfa_Amr_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Mfa_ChallengesArgs = {
  objects: Array<Auth_Mfa_Challenges_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Mfa_Challenges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Mfa_Challenges_OneArgs = {
  object: Auth_Mfa_Challenges_Insert_Input;
  on_conflict?: InputMaybe<Auth_Mfa_Challenges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Mfa_FactorsArgs = {
  objects: Array<Auth_Mfa_Factors_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Mfa_Factors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Mfa_Factors_OneArgs = {
  object: Auth_Mfa_Factors_Insert_Input;
  on_conflict?: InputMaybe<Auth_Mfa_Factors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_TokensArgs = {
  objects: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_Tokens_OneArgs = {
  object: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Saml_ProvidersArgs = {
  objects: Array<Auth_Saml_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Saml_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Saml_Providers_OneArgs = {
  object: Auth_Saml_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Saml_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Saml_Relay_StatesArgs = {
  objects: Array<Auth_Saml_Relay_States_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Saml_Relay_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Saml_Relay_States_OneArgs = {
  object: Auth_Saml_Relay_States_Insert_Input;
  on_conflict?: InputMaybe<Auth_Saml_Relay_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Schema_MigrationsArgs = {
  objects: Array<Auth_Schema_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Schema_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Schema_Migrations_OneArgs = {
  object: Auth_Schema_Migrations_Insert_Input;
  on_conflict?: InputMaybe<Auth_Schema_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_SessionsArgs = {
  objects: Array<Auth_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Sessions_OneArgs = {
  object: Auth_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Auth_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Sso_DomainsArgs = {
  objects: Array<Auth_Sso_Domains_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Sso_Domains_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Sso_Domains_OneArgs = {
  object: Auth_Sso_Domains_Insert_Input;
  on_conflict?: InputMaybe<Auth_Sso_Domains_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Sso_ProvidersArgs = {
  objects: Array<Auth_Sso_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Sso_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Sso_Providers_OneArgs = {
  object: Auth_Sso_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Sso_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_UsersArgs = {
  objects: Array<Auth_Users_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Users_OneArgs = {
  object: Auth_Users_Insert_Input;
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: InputMaybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Official_QuestionsArgs = {
  objects: Array<Official_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Official_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Official_Questions_OneArgs = {
  object: Official_Questions_Insert_Input;
  on_conflict?: InputMaybe<Official_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentsArgs = {
  objects: Array<Payments_Insert_Input>;
  on_conflict?: InputMaybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payments_OneArgs = {
  object: Payments_Insert_Input;
  on_conflict?: InputMaybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchased_QuestionsArgs = {
  objects: Array<Purchased_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Purchased_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchased_Questions_OneArgs = {
  object: Purchased_Questions_Insert_Input;
  on_conflict?: InputMaybe<Purchased_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_StatusesArgs = {
  objects: Array<Question_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Question_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_Statuses_OneArgs = {
  object: Question_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Question_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RatingsArgs = {
  objects: Array<Ratings_Insert_Input>;
  on_conflict?: InputMaybe<Ratings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ratings_OneArgs = {
  object: Ratings_Insert_Input;
  on_conflict?: InputMaybe<Ratings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: Array<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate__Prisma_MigrationsArgs = {
  _inc?: InputMaybe<_Prisma_Migrations_Inc_Input>;
  _set?: InputMaybe<_Prisma_Migrations_Set_Input>;
  where: _Prisma_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate__Prisma_Migrations_By_PkArgs = {
  _inc?: InputMaybe<_Prisma_Migrations_Inc_Input>;
  _set?: InputMaybe<_Prisma_Migrations_Set_Input>;
  pk_columns: _Prisma_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate__Prisma_Migrations_ManyArgs = {
  updates: Array<_Prisma_Migrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AnswersArgs = {
  _append?: InputMaybe<Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Answers_Prepend_Input>;
  _set?: InputMaybe<Answers_Set_Input>;
  where: Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_By_PkArgs = {
  _append?: InputMaybe<Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Answers_Prepend_Input>;
  _set?: InputMaybe<Answers_Set_Input>;
  pk_columns: Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Answers_ManyArgs = {
  updates: Array<Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Audit_Log_EntriesArgs = {
  _set?: InputMaybe<Auth_Audit_Log_Entries_Set_Input>;
  where: Auth_Audit_Log_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Audit_Log_Entries_By_PkArgs = {
  _set?: InputMaybe<Auth_Audit_Log_Entries_Set_Input>;
  pk_columns: Auth_Audit_Log_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Audit_Log_Entries_ManyArgs = {
  updates: Array<Auth_Audit_Log_Entries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_IdentitiesArgs = {
  _append?: InputMaybe<Auth_Identities_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Identities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Identities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Identities_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Identities_Prepend_Input>;
  _set?: InputMaybe<Auth_Identities_Set_Input>;
  where: Auth_Identities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Identities_By_PkArgs = {
  _append?: InputMaybe<Auth_Identities_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Identities_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Identities_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Identities_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Identities_Prepend_Input>;
  _set?: InputMaybe<Auth_Identities_Set_Input>;
  pk_columns: Auth_Identities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Identities_ManyArgs = {
  updates: Array<Auth_Identities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_InstancesArgs = {
  _set?: InputMaybe<Auth_Instances_Set_Input>;
  where: Auth_Instances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Instances_By_PkArgs = {
  _set?: InputMaybe<Auth_Instances_Set_Input>;
  pk_columns: Auth_Instances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Instances_ManyArgs = {
  updates: Array<Auth_Instances_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Amr_ClaimsArgs = {
  _set?: InputMaybe<Auth_Mfa_Amr_Claims_Set_Input>;
  where: Auth_Mfa_Amr_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Amr_Claims_By_PkArgs = {
  _set?: InputMaybe<Auth_Mfa_Amr_Claims_Set_Input>;
  pk_columns: Auth_Mfa_Amr_Claims_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Amr_Claims_ManyArgs = {
  updates: Array<Auth_Mfa_Amr_Claims_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_ChallengesArgs = {
  _set?: InputMaybe<Auth_Mfa_Challenges_Set_Input>;
  where: Auth_Mfa_Challenges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Challenges_By_PkArgs = {
  _set?: InputMaybe<Auth_Mfa_Challenges_Set_Input>;
  pk_columns: Auth_Mfa_Challenges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Challenges_ManyArgs = {
  updates: Array<Auth_Mfa_Challenges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_FactorsArgs = {
  _set?: InputMaybe<Auth_Mfa_Factors_Set_Input>;
  where: Auth_Mfa_Factors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Factors_By_PkArgs = {
  _set?: InputMaybe<Auth_Mfa_Factors_Set_Input>;
  pk_columns: Auth_Mfa_Factors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Mfa_Factors_ManyArgs = {
  updates: Array<Auth_Mfa_Factors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_TokensArgs = {
  _inc?: InputMaybe<Auth_Refresh_Tokens_Inc_Input>;
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Auth_Refresh_Tokens_Inc_Input>;
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  pk_columns: Auth_Refresh_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_ManyArgs = {
  updates: Array<Auth_Refresh_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Saml_ProvidersArgs = {
  _append?: InputMaybe<Auth_Saml_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Saml_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Saml_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Saml_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Saml_Providers_Prepend_Input>;
  _set?: InputMaybe<Auth_Saml_Providers_Set_Input>;
  where: Auth_Saml_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Saml_Providers_By_PkArgs = {
  _append?: InputMaybe<Auth_Saml_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Saml_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Saml_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Saml_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Saml_Providers_Prepend_Input>;
  _set?: InputMaybe<Auth_Saml_Providers_Set_Input>;
  pk_columns: Auth_Saml_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Saml_Providers_ManyArgs = {
  updates: Array<Auth_Saml_Providers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Saml_Relay_StatesArgs = {
  _set?: InputMaybe<Auth_Saml_Relay_States_Set_Input>;
  where: Auth_Saml_Relay_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Saml_Relay_States_By_PkArgs = {
  _set?: InputMaybe<Auth_Saml_Relay_States_Set_Input>;
  pk_columns: Auth_Saml_Relay_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Saml_Relay_States_ManyArgs = {
  updates: Array<Auth_Saml_Relay_States_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Schema_MigrationsArgs = {
  _set?: InputMaybe<Auth_Schema_Migrations_Set_Input>;
  where: Auth_Schema_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Schema_Migrations_By_PkArgs = {
  _set?: InputMaybe<Auth_Schema_Migrations_Set_Input>;
  pk_columns: Auth_Schema_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Schema_Migrations_ManyArgs = {
  updates: Array<Auth_Schema_Migrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_SessionsArgs = {
  _set?: InputMaybe<Auth_Sessions_Set_Input>;
  where: Auth_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sessions_By_PkArgs = {
  _set?: InputMaybe<Auth_Sessions_Set_Input>;
  pk_columns: Auth_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sessions_ManyArgs = {
  updates: Array<Auth_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sso_DomainsArgs = {
  _set?: InputMaybe<Auth_Sso_Domains_Set_Input>;
  where: Auth_Sso_Domains_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sso_Domains_By_PkArgs = {
  _set?: InputMaybe<Auth_Sso_Domains_Set_Input>;
  pk_columns: Auth_Sso_Domains_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sso_Domains_ManyArgs = {
  updates: Array<Auth_Sso_Domains_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sso_ProvidersArgs = {
  _set?: InputMaybe<Auth_Sso_Providers_Set_Input>;
  where: Auth_Sso_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sso_Providers_By_PkArgs = {
  _set?: InputMaybe<Auth_Sso_Providers_Set_Input>;
  pk_columns: Auth_Sso_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Sso_Providers_ManyArgs = {
  updates: Array<Auth_Sso_Providers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_UsersArgs = {
  _append?: InputMaybe<Auth_Users_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Users_Delete_Key_Input>;
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  _prepend?: InputMaybe<Auth_Users_Prepend_Input>;
  _set?: InputMaybe<Auth_Users_Set_Input>;
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_By_PkArgs = {
  _append?: InputMaybe<Auth_Users_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Users_Delete_Key_Input>;
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  _prepend?: InputMaybe<Auth_Users_Prepend_Input>;
  _set?: InputMaybe<Auth_Users_Set_Input>;
  pk_columns: Auth_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_ManyArgs = {
  updates: Array<Auth_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _set?: InputMaybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _set?: InputMaybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_ManyArgs = {
  updates: Array<Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Official_QuestionsArgs = {
  _append?: InputMaybe<Official_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Official_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Official_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Official_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Official_Questions_Inc_Input>;
  _prepend?: InputMaybe<Official_Questions_Prepend_Input>;
  _set?: InputMaybe<Official_Questions_Set_Input>;
  where: Official_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Official_Questions_By_PkArgs = {
  _append?: InputMaybe<Official_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Official_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Official_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Official_Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Official_Questions_Inc_Input>;
  _prepend?: InputMaybe<Official_Questions_Prepend_Input>;
  _set?: InputMaybe<Official_Questions_Set_Input>;
  pk_columns: Official_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Official_Questions_ManyArgs = {
  updates: Array<Official_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentsArgs = {
  _set?: InputMaybe<Payments_Set_Input>;
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payments_By_PkArgs = {
  _set?: InputMaybe<Payments_Set_Input>;
  pk_columns: Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payments_ManyArgs = {
  updates: Array<Payments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Purchased_QuestionsArgs = {
  _inc?: InputMaybe<Purchased_Questions_Inc_Input>;
  _set?: InputMaybe<Purchased_Questions_Set_Input>;
  where: Purchased_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Purchased_Questions_By_PkArgs = {
  _inc?: InputMaybe<Purchased_Questions_Inc_Input>;
  _set?: InputMaybe<Purchased_Questions_Set_Input>;
  pk_columns: Purchased_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Purchased_Questions_ManyArgs = {
  updates: Array<Purchased_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Question_StatusesArgs = {
  _set?: InputMaybe<Question_Statuses_Set_Input>;
  where: Question_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Statuses_By_PkArgs = {
  _set?: InputMaybe<Question_Statuses_Set_Input>;
  pk_columns: Question_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Question_Statuses_ManyArgs = {
  updates: Array<Question_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _append?: InputMaybe<Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Questions_Inc_Input>;
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _append?: InputMaybe<Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  _inc?: InputMaybe<Questions_Inc_Input>;
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_ManyArgs = {
  updates: Array<Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RatingsArgs = {
  _set?: InputMaybe<Ratings_Set_Input>;
  where: Ratings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ratings_By_PkArgs = {
  _set?: InputMaybe<Ratings_Set_Input>;
  pk_columns: Ratings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ratings_ManyArgs = {
  updates: Array<Ratings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: Array<Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
  updates: Array<User_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fromUserID: Scalars['uuid'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  /** An object relationship */
  question: Questions;
  questionID: Scalars['uuid'];
  status: Scalars['Boolean'];
  toUserID: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByTouserid: Users;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notifications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fromUserID?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionID?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  toUserID?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByTouserid?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fromUserID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionID?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  toUserID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByTouserid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fromUserID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  questionID?: Maybe<Scalars['uuid']>;
  toUserID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  fromUserID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  toUserID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fromUserID?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  questionID?: Maybe<Scalars['uuid']>;
  toUserID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  fromUserID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  toUserID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  fromUserID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionID?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  toUserID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByTouserid?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FromUserId = 'fromUserID',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  Status = 'status',
  /** column name */
  ToUserId = 'toUserID',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "notifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Status = 'status'
}

/** select "notifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fromUserID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  toUserID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fromUserID?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['Boolean']>;
  toUserID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FromUserId = 'fromUserID',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  Status = 'status',
  /** column name */
  ToUserId = 'toUserID',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "official_questions" */
export type Official_Questions = {
  __typename?: 'official_questions';
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hashtags?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  images?: Maybe<Scalars['jsonb']>;
  isPublic: Scalars['Boolean'];
  price: Scalars['numeric'];
  /** An array relationship */
  purchased_questions: Array<Purchased_Questions>;
  /** An aggregate relationship */
  purchased_questions_aggregate: Purchased_Questions_Aggregate;
  /** An object relationship */
  question_status: Question_Statuses;
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "official_questions" */
export type Official_QuestionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "official_questions" */
export type Official_QuestionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "official_questions" */
export type Official_QuestionsHashtagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "official_questions" */
export type Official_QuestionsImagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "official_questions" */
export type Official_QuestionsPurchased_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


/** columns and relationships of "official_questions" */
export type Official_QuestionsPurchased_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};

/** aggregated selection of "official_questions" */
export type Official_Questions_Aggregate = {
  __typename?: 'official_questions_aggregate';
  aggregate?: Maybe<Official_Questions_Aggregate_Fields>;
  nodes: Array<Official_Questions>;
};

export type Official_Questions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Official_Questions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Official_Questions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Official_Questions_Aggregate_Bool_Exp_Count>;
};

export type Official_Questions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Official_Questions_Select_Column_Official_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Official_Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Official_Questions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Official_Questions_Select_Column_Official_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Official_Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Official_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Official_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Official_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "official_questions" */
export type Official_Questions_Aggregate_Fields = {
  __typename?: 'official_questions_aggregate_fields';
  avg?: Maybe<Official_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Official_Questions_Max_Fields>;
  min?: Maybe<Official_Questions_Min_Fields>;
  stddev?: Maybe<Official_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Official_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Official_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Official_Questions_Sum_Fields>;
  var_pop?: Maybe<Official_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Official_Questions_Var_Samp_Fields>;
  variance?: Maybe<Official_Questions_Variance_Fields>;
};


/** aggregate fields of "official_questions" */
export type Official_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Official_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "official_questions" */
export type Official_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Official_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Official_Questions_Max_Order_By>;
  min?: InputMaybe<Official_Questions_Min_Order_By>;
  stddev?: InputMaybe<Official_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Official_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Official_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Official_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Official_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Official_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Official_Questions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Official_Questions_Append_Input = {
  hashtags?: InputMaybe<Scalars['jsonb']>;
  images?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "official_questions" */
export type Official_Questions_Arr_Rel_Insert_Input = {
  data: Array<Official_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Official_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Official_Questions_Avg_Fields = {
  __typename?: 'official_questions_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "official_questions" */
export type Official_Questions_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "official_questions". All fields are combined with a logical 'AND'. */
export type Official_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Official_Questions_Bool_Exp>>;
  _not?: InputMaybe<Official_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Official_Questions_Bool_Exp>>;
  answers?: InputMaybe<Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Bool_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hashtags?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  images?: InputMaybe<Jsonb_Comparison_Exp>;
  isPublic?: InputMaybe<Boolean_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  purchased_questions?: InputMaybe<Purchased_Questions_Bool_Exp>;
  purchased_questions_aggregate?: InputMaybe<Purchased_Questions_Aggregate_Bool_Exp>;
  question_status?: InputMaybe<Question_Statuses_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "official_questions" */
export enum Official_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  OfficialQuestionsPkey = 'official_questions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Official_Questions_Delete_At_Path_Input = {
  hashtags?: InputMaybe<Array<Scalars['String']>>;
  images?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Official_Questions_Delete_Elem_Input = {
  hashtags?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Official_Questions_Delete_Key_Input = {
  hashtags?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "official_questions" */
export type Official_Questions_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "official_questions" */
export type Official_Questions_Insert_Input = {
  answers?: InputMaybe<Answers_Arr_Rel_Insert_Input>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  hashtags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['numeric']>;
  purchased_questions?: InputMaybe<Purchased_Questions_Arr_Rel_Insert_Input>;
  question_status?: InputMaybe<Question_Statuses_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Official_Questions_Max_Fields = {
  __typename?: 'official_questions_max_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "official_questions" */
export type Official_Questions_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Official_Questions_Min_Fields = {
  __typename?: 'official_questions_min_fields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "official_questions" */
export type Official_Questions_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "official_questions" */
export type Official_Questions_Mutation_Response = {
  __typename?: 'official_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Official_Questions>;
};

/** input type for inserting object relation for remote table "official_questions" */
export type Official_Questions_Obj_Rel_Insert_Input = {
  data: Official_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Official_Questions_On_Conflict>;
};

/** on_conflict condition type for table "official_questions" */
export type Official_Questions_On_Conflict = {
  constraint: Official_Questions_Constraint;
  update_columns?: Array<Official_Questions_Update_Column>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "official_questions". */
export type Official_Questions_Order_By = {
  answers_aggregate?: InputMaybe<Answers_Aggregate_Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  hashtags?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  images?: InputMaybe<Order_By>;
  isPublic?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  purchased_questions_aggregate?: InputMaybe<Purchased_Questions_Aggregate_Order_By>;
  question_status?: InputMaybe<Question_Statuses_Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: official_questions */
export type Official_Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Official_Questions_Prepend_Input = {
  hashtags?: InputMaybe<Scalars['jsonb']>;
  images?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "official_questions" */
export enum Official_Questions_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Price = 'price',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "official_questions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "official_questions" */
export enum Official_Questions_Select_Column_Official_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic'
}

/** select "official_questions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "official_questions" */
export enum Official_Questions_Select_Column_Official_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic'
}

/** input type for updating data in table "official_questions" */
export type Official_Questions_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  hashtags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Official_Questions_Stddev_Fields = {
  __typename?: 'official_questions_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "official_questions" */
export type Official_Questions_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Official_Questions_Stddev_Pop_Fields = {
  __typename?: 'official_questions_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "official_questions" */
export type Official_Questions_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Official_Questions_Stddev_Samp_Fields = {
  __typename?: 'official_questions_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "official_questions" */
export type Official_Questions_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "official_questions" */
export type Official_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Official_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Official_Questions_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  hashtags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Official_Questions_Sum_Fields = {
  __typename?: 'official_questions_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "official_questions" */
export type Official_Questions_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "official_questions" */
export enum Official_Questions_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Price = 'price',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Official_Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Official_Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Official_Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Official_Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Official_Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Official_Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Official_Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Official_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Official_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Official_Questions_Var_Pop_Fields = {
  __typename?: 'official_questions_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "official_questions" */
export type Official_Questions_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Official_Questions_Var_Samp_Fields = {
  __typename?: 'official_questions_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "official_questions" */
export type Official_Questions_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Official_Questions_Variance_Fields = {
  __typename?: 'official_questions_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "official_questions" */
export type Official_Questions_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payments" */
export type Payments = {
  __typename?: 'payments';
  answererUserID: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  question: Questions;
  questionID: Scalars['uuid'];
  questionerUserID: Scalars['uuid'];
  stripePaymentID: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByQuestioneruserid: Users;
};

/** aggregated selection of "payments" */
export type Payments_Aggregate = {
  __typename?: 'payments_aggregate';
  aggregate?: Maybe<Payments_Aggregate_Fields>;
  nodes: Array<Payments>;
};

export type Payments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Payments_Aggregate_Bool_Exp_Count>;
};

export type Payments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payments" */
export type Payments_Aggregate_Fields = {
  __typename?: 'payments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payments_Max_Fields>;
  min?: Maybe<Payments_Min_Fields>;
};


/** aggregate fields of "payments" */
export type Payments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payments" */
export type Payments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Payments_Max_Order_By>;
  min?: InputMaybe<Payments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "payments" */
export type Payments_Arr_Rel_Insert_Input = {
  data: Array<Payments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type Payments_Bool_Exp = {
  _and?: InputMaybe<Array<Payments_Bool_Exp>>;
  _not?: InputMaybe<Payments_Bool_Exp>;
  _or?: InputMaybe<Array<Payments_Bool_Exp>>;
  answererUserID?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionID?: InputMaybe<Uuid_Comparison_Exp>;
  questionerUserID?: InputMaybe<Uuid_Comparison_Exp>;
  stripePaymentID?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByQuestioneruserid?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "payments" */
export enum Payments_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentsPkey = 'payments_pkey',
  /** unique or primary key constraint on columns "stripePaymentID" */
  PaymentsStripePaymentIdKey = 'payments_stripePaymentID_key'
}

/** input type for inserting data into table "payments" */
export type Payments_Insert_Input = {
  answererUserID?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionID?: InputMaybe<Scalars['uuid']>;
  questionerUserID?: InputMaybe<Scalars['uuid']>;
  stripePaymentID?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByQuestioneruserid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Payments_Max_Fields = {
  __typename?: 'payments_max_fields';
  answererUserID?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionID?: Maybe<Scalars['uuid']>;
  questionerUserID?: Maybe<Scalars['uuid']>;
  stripePaymentID?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payments" */
export type Payments_Max_Order_By = {
  answererUserID?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  questionerUserID?: InputMaybe<Order_By>;
  stripePaymentID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payments_Min_Fields = {
  __typename?: 'payments_min_fields';
  answererUserID?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionID?: Maybe<Scalars['uuid']>;
  questionerUserID?: Maybe<Scalars['uuid']>;
  stripePaymentID?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payments" */
export type Payments_Min_Order_By = {
  answererUserID?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  questionerUserID?: InputMaybe<Order_By>;
  stripePaymentID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payments" */
export type Payments_Mutation_Response = {
  __typename?: 'payments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payments>;
};

/** on_conflict condition type for table "payments" */
export type Payments_On_Conflict = {
  constraint: Payments_Constraint;
  update_columns?: Array<Payments_Update_Column>;
  where?: InputMaybe<Payments_Bool_Exp>;
};

/** Ordering options when selecting data from "payments". */
export type Payments_Order_By = {
  answererUserID?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionID?: InputMaybe<Order_By>;
  questionerUserID?: InputMaybe<Order_By>;
  stripePaymentID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByQuestioneruserid?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: payments */
export type Payments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payments" */
export enum Payments_Select_Column {
  /** column name */
  AnswererUserId = 'answererUserID',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  QuestionerUserId = 'questionerUserID',
  /** column name */
  StripePaymentId = 'stripePaymentID',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "payments" */
export type Payments_Set_Input = {
  answererUserID?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  questionerUserID?: InputMaybe<Scalars['uuid']>;
  stripePaymentID?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "payments" */
export type Payments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payments_Stream_Cursor_Value_Input = {
  answererUserID?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  questionerUserID?: InputMaybe<Scalars['uuid']>;
  stripePaymentID?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "payments" */
export enum Payments_Update_Column {
  /** column name */
  AnswererUserId = 'answererUserID',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  QuestionerUserId = 'questionerUserID',
  /** column name */
  StripePaymentId = 'stripePaymentID',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Payments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payments_Bool_Exp;
};

/** columns and relationships of "purchased_questions" */
export type Purchased_Questions = {
  __typename?: 'purchased_questions';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  officialQuestionID?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  official_question?: Maybe<Official_Questions>;
  price: Scalars['numeric'];
  /** An object relationship */
  question?: Maybe<Questions>;
  questionID?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  userID?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "purchased_questions" */
export type Purchased_Questions_Aggregate = {
  __typename?: 'purchased_questions_aggregate';
  aggregate?: Maybe<Purchased_Questions_Aggregate_Fields>;
  nodes: Array<Purchased_Questions>;
};

export type Purchased_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Purchased_Questions_Aggregate_Bool_Exp_Count>;
};

export type Purchased_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Purchased_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "purchased_questions" */
export type Purchased_Questions_Aggregate_Fields = {
  __typename?: 'purchased_questions_aggregate_fields';
  avg?: Maybe<Purchased_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Purchased_Questions_Max_Fields>;
  min?: Maybe<Purchased_Questions_Min_Fields>;
  stddev?: Maybe<Purchased_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Purchased_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Purchased_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Purchased_Questions_Sum_Fields>;
  var_pop?: Maybe<Purchased_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Purchased_Questions_Var_Samp_Fields>;
  variance?: Maybe<Purchased_Questions_Variance_Fields>;
};


/** aggregate fields of "purchased_questions" */
export type Purchased_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "purchased_questions" */
export type Purchased_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Purchased_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Purchased_Questions_Max_Order_By>;
  min?: InputMaybe<Purchased_Questions_Min_Order_By>;
  stddev?: InputMaybe<Purchased_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Purchased_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Purchased_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Purchased_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Purchased_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Purchased_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Purchased_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "purchased_questions" */
export type Purchased_Questions_Arr_Rel_Insert_Input = {
  data: Array<Purchased_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Purchased_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Purchased_Questions_Avg_Fields = {
  __typename?: 'purchased_questions_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "purchased_questions" */
export type Purchased_Questions_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "purchased_questions". All fields are combined with a logical 'AND'. */
export type Purchased_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Purchased_Questions_Bool_Exp>>;
  _not?: InputMaybe<Purchased_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Purchased_Questions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  officialQuestionID?: InputMaybe<Uuid_Comparison_Exp>;
  official_question?: InputMaybe<Official_Questions_Bool_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionID?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userID?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchased_questions" */
export enum Purchased_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchasedQuestionsPkey = 'purchased_questions_pkey'
}

/** input type for incrementing numeric columns in table "purchased_questions" */
export type Purchased_Questions_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "purchased_questions" */
export type Purchased_Questions_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  officialQuestionID?: InputMaybe<Scalars['uuid']>;
  official_question?: InputMaybe<Official_Questions_Obj_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['numeric']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userID?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Purchased_Questions_Max_Fields = {
  __typename?: 'purchased_questions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  officialQuestionID?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  questionID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userID?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "purchased_questions" */
export type Purchased_Questions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  officialQuestionID?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userID?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Purchased_Questions_Min_Fields = {
  __typename?: 'purchased_questions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  officialQuestionID?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  questionID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userID?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "purchased_questions" */
export type Purchased_Questions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  officialQuestionID?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userID?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "purchased_questions" */
export type Purchased_Questions_Mutation_Response = {
  __typename?: 'purchased_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Purchased_Questions>;
};

/** on_conflict condition type for table "purchased_questions" */
export type Purchased_Questions_On_Conflict = {
  constraint: Purchased_Questions_Constraint;
  update_columns?: Array<Purchased_Questions_Update_Column>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "purchased_questions". */
export type Purchased_Questions_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  officialQuestionID?: InputMaybe<Order_By>;
  official_question?: InputMaybe<Official_Questions_Order_By>;
  price?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userID?: InputMaybe<Order_By>;
};

/** primary key columns input for table: purchased_questions */
export type Purchased_Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "purchased_questions" */
export enum Purchased_Questions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  OfficialQuestionId = 'officialQuestionID',
  /** column name */
  Price = 'price',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userID'
}

/** input type for updating data in table "purchased_questions" */
export type Purchased_Questions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  officialQuestionID?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['numeric']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userID?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Purchased_Questions_Stddev_Fields = {
  __typename?: 'purchased_questions_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "purchased_questions" */
export type Purchased_Questions_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Purchased_Questions_Stddev_Pop_Fields = {
  __typename?: 'purchased_questions_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "purchased_questions" */
export type Purchased_Questions_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Purchased_Questions_Stddev_Samp_Fields = {
  __typename?: 'purchased_questions_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "purchased_questions" */
export type Purchased_Questions_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "purchased_questions" */
export type Purchased_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Purchased_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Purchased_Questions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  officialQuestionID?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['numeric']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userID?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Purchased_Questions_Sum_Fields = {
  __typename?: 'purchased_questions_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "purchased_questions" */
export type Purchased_Questions_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "purchased_questions" */
export enum Purchased_Questions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  OfficialQuestionId = 'officialQuestionID',
  /** column name */
  Price = 'price',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userID'
}

export type Purchased_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Purchased_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Purchased_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Purchased_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Purchased_Questions_Var_Pop_Fields = {
  __typename?: 'purchased_questions_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "purchased_questions" */
export type Purchased_Questions_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Purchased_Questions_Var_Samp_Fields = {
  __typename?: 'purchased_questions_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "purchased_questions" */
export type Purchased_Questions_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Purchased_Questions_Variance_Fields = {
  __typename?: 'purchased_questions_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "purchased_questions" */
export type Purchased_Questions_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "_prisma_migrations" */
  _prisma_migrations: Array<_Prisma_Migrations>;
  /** fetch aggregated fields from the table: "_prisma_migrations" */
  _prisma_migrations_aggregate: _Prisma_Migrations_Aggregate;
  /** fetch data from the table: "_prisma_migrations" using primary key columns */
  _prisma_migrations_by_pk?: Maybe<_Prisma_Migrations>;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<Answers>;
  /** fetch data from the table: "auth.audit_log_entries" */
  auth_audit_log_entries: Array<Auth_Audit_Log_Entries>;
  /** fetch aggregated fields from the table: "auth.audit_log_entries" */
  auth_audit_log_entries_aggregate: Auth_Audit_Log_Entries_Aggregate;
  /** fetch data from the table: "auth.audit_log_entries" using primary key columns */
  auth_audit_log_entries_by_pk?: Maybe<Auth_Audit_Log_Entries>;
  /** fetch data from the table: "auth.identities" */
  auth_identities: Array<Auth_Identities>;
  /** fetch aggregated fields from the table: "auth.identities" */
  auth_identities_aggregate: Auth_Identities_Aggregate;
  /** fetch data from the table: "auth.identities" using primary key columns */
  auth_identities_by_pk?: Maybe<Auth_Identities>;
  /** fetch data from the table: "auth.instances" */
  auth_instances: Array<Auth_Instances>;
  /** fetch aggregated fields from the table: "auth.instances" */
  auth_instances_aggregate: Auth_Instances_Aggregate;
  /** fetch data from the table: "auth.instances" using primary key columns */
  auth_instances_by_pk?: Maybe<Auth_Instances>;
  /** fetch data from the table: "auth.mfa_amr_claims" */
  auth_mfa_amr_claims: Array<Auth_Mfa_Amr_Claims>;
  /** fetch aggregated fields from the table: "auth.mfa_amr_claims" */
  auth_mfa_amr_claims_aggregate: Auth_Mfa_Amr_Claims_Aggregate;
  /** fetch data from the table: "auth.mfa_amr_claims" using primary key columns */
  auth_mfa_amr_claims_by_pk?: Maybe<Auth_Mfa_Amr_Claims>;
  /** fetch data from the table: "auth.mfa_challenges" */
  auth_mfa_challenges: Array<Auth_Mfa_Challenges>;
  /** fetch aggregated fields from the table: "auth.mfa_challenges" */
  auth_mfa_challenges_aggregate: Auth_Mfa_Challenges_Aggregate;
  /** fetch data from the table: "auth.mfa_challenges" using primary key columns */
  auth_mfa_challenges_by_pk?: Maybe<Auth_Mfa_Challenges>;
  /** fetch data from the table: "auth.mfa_factors" */
  auth_mfa_factors: Array<Auth_Mfa_Factors>;
  /** fetch aggregated fields from the table: "auth.mfa_factors" */
  auth_mfa_factors_aggregate: Auth_Mfa_Factors_Aggregate;
  /** fetch data from the table: "auth.mfa_factors" using primary key columns */
  auth_mfa_factors_by_pk?: Maybe<Auth_Mfa_Factors>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.saml_providers" */
  auth_saml_providers: Array<Auth_Saml_Providers>;
  /** fetch aggregated fields from the table: "auth.saml_providers" */
  auth_saml_providers_aggregate: Auth_Saml_Providers_Aggregate;
  /** fetch data from the table: "auth.saml_providers" using primary key columns */
  auth_saml_providers_by_pk?: Maybe<Auth_Saml_Providers>;
  /** fetch data from the table: "auth.saml_relay_states" */
  auth_saml_relay_states: Array<Auth_Saml_Relay_States>;
  /** fetch aggregated fields from the table: "auth.saml_relay_states" */
  auth_saml_relay_states_aggregate: Auth_Saml_Relay_States_Aggregate;
  /** fetch data from the table: "auth.saml_relay_states" using primary key columns */
  auth_saml_relay_states_by_pk?: Maybe<Auth_Saml_Relay_States>;
  /** fetch data from the table: "auth.schema_migrations" */
  auth_schema_migrations: Array<Auth_Schema_Migrations>;
  /** fetch aggregated fields from the table: "auth.schema_migrations" */
  auth_schema_migrations_aggregate: Auth_Schema_Migrations_Aggregate;
  /** fetch data from the table: "auth.schema_migrations" using primary key columns */
  auth_schema_migrations_by_pk?: Maybe<Auth_Schema_Migrations>;
  /** fetch data from the table: "auth.sessions" */
  auth_sessions: Array<Auth_Sessions>;
  /** fetch aggregated fields from the table: "auth.sessions" */
  auth_sessions_aggregate: Auth_Sessions_Aggregate;
  /** fetch data from the table: "auth.sessions" using primary key columns */
  auth_sessions_by_pk?: Maybe<Auth_Sessions>;
  /** fetch data from the table: "auth.sso_domains" */
  auth_sso_domains: Array<Auth_Sso_Domains>;
  /** fetch aggregated fields from the table: "auth.sso_domains" */
  auth_sso_domains_aggregate: Auth_Sso_Domains_Aggregate;
  /** fetch data from the table: "auth.sso_domains" using primary key columns */
  auth_sso_domains_by_pk?: Maybe<Auth_Sso_Domains>;
  /** fetch data from the table: "auth.sso_providers" */
  auth_sso_providers: Array<Auth_Sso_Providers>;
  /** fetch aggregated fields from the table: "auth.sso_providers" */
  auth_sso_providers_aggregate: Auth_Sso_Providers_Aggregate;
  /** fetch data from the table: "auth.sso_providers" using primary key columns */
  auth_sso_providers_by_pk?: Maybe<Auth_Sso_Providers>;
  /** fetch data from the table: "auth.users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "auth.users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** An array relationship */
  official_questions: Array<Official_Questions>;
  /** An aggregate relationship */
  official_questions_aggregate: Official_Questions_Aggregate;
  /** fetch data from the table: "official_questions" using primary key columns */
  official_questions_by_pk?: Maybe<Official_Questions>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** An array relationship */
  purchased_questions: Array<Purchased_Questions>;
  /** An aggregate relationship */
  purchased_questions_aggregate: Purchased_Questions_Aggregate;
  /** fetch data from the table: "purchased_questions" using primary key columns */
  purchased_questions_by_pk?: Maybe<Purchased_Questions>;
  /** fetch data from the table: "question_statuses" */
  question_statuses: Array<Question_Statuses>;
  /** fetch aggregated fields from the table: "question_statuses" */
  question_statuses_aggregate: Question_Statuses_Aggregate;
  /** fetch data from the table: "question_statuses" using primary key columns */
  question_statuses_by_pk?: Maybe<Question_Statuses>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** An array relationship */
  ratings: Array<Ratings>;
  /** An aggregate relationship */
  ratings_aggregate: Ratings_Aggregate;
  /** fetch data from the table: "ratings" using primary key columns */
  ratings_by_pk?: Maybe<Ratings>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_Root_Prisma_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<_Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Prisma_Migrations_Order_By>>;
  where?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
};


export type Query_Root_Prisma_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Prisma_Migrations_Order_By>>;
  where?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
};


export type Query_Root_Prisma_Migrations_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Query_RootAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Query_RootAnswers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Audit_Log_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
};


export type Query_RootAuth_Audit_Log_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
};


export type Query_RootAuth_Audit_Log_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_IdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Identities_Order_By>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


export type Query_RootAuth_Identities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Identities_Order_By>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


export type Query_RootAuth_Identities_By_PkArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};


export type Query_RootAuth_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Instances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Instances_Order_By>>;
  where?: InputMaybe<Auth_Instances_Bool_Exp>;
};


export type Query_RootAuth_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Instances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Instances_Order_By>>;
  where?: InputMaybe<Auth_Instances_Bool_Exp>;
};


export type Query_RootAuth_Instances_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Mfa_Amr_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


export type Query_RootAuth_Mfa_Amr_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


export type Query_RootAuth_Mfa_Amr_Claims_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Mfa_ChallengesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Challenges_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};


export type Query_RootAuth_Mfa_Challenges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Challenges_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};


export type Query_RootAuth_Mfa_Challenges_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Mfa_FactorsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Factors_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


export type Query_RootAuth_Mfa_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Factors_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


export type Query_RootAuth_Mfa_Factors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Query_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Query_RootAuth_Refresh_Tokens_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAuth_Saml_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Providers_Order_By>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


export type Query_RootAuth_Saml_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Providers_Order_By>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


export type Query_RootAuth_Saml_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Saml_Relay_StatesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Relay_States_Order_By>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


export type Query_RootAuth_Saml_Relay_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Relay_States_Order_By>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


export type Query_RootAuth_Saml_Relay_States_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Schema_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Schema_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Schema_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
};


export type Query_RootAuth_Schema_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Schema_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Schema_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
};


export type Query_RootAuth_Schema_Migrations_By_PkArgs = {
  version: Scalars['String'];
};


export type Query_RootAuth_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sessions_Order_By>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};


export type Query_RootAuth_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sessions_Order_By>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};


export type Query_RootAuth_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Sso_DomainsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Domains_Order_By>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};


export type Query_RootAuth_Sso_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Domains_Order_By>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};


export type Query_RootAuth_Sso_Domains_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Sso_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Providers_Order_By>>;
  where?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
};


export type Query_RootAuth_Sso_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Providers_Order_By>>;
  where?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
};


export type Query_RootAuth_Sso_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Query_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOfficial_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Official_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Official_Questions_Order_By>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


export type Query_RootOfficial_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Official_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Official_Questions_Order_By>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


export type Query_RootOfficial_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


export type Query_RootPayments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPurchased_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


export type Query_RootPurchased_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


export type Query_RootPurchased_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestion_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Question_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Statuses_Order_By>>;
  where?: InputMaybe<Question_Statuses_Bool_Exp>;
};


export type Query_RootQuestion_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Statuses_Order_By>>;
  where?: InputMaybe<Question_Statuses_Bool_Exp>;
};


export type Query_RootQuestion_Statuses_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRatingsArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


export type Query_RootRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


export type Query_RootRatings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "question_statuses" */
export type Question_Statuses = {
  __typename?: 'question_statuses';
  id: Scalars['String'];
  /** An array relationship */
  official_questions: Array<Official_Questions>;
  /** An aggregate relationship */
  official_questions_aggregate: Official_Questions_Aggregate;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
};


/** columns and relationships of "question_statuses" */
export type Question_StatusesOfficial_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Official_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Official_Questions_Order_By>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


/** columns and relationships of "question_statuses" */
export type Question_StatusesOfficial_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Official_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Official_Questions_Order_By>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


/** columns and relationships of "question_statuses" */
export type Question_StatusesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "question_statuses" */
export type Question_StatusesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** aggregated selection of "question_statuses" */
export type Question_Statuses_Aggregate = {
  __typename?: 'question_statuses_aggregate';
  aggregate?: Maybe<Question_Statuses_Aggregate_Fields>;
  nodes: Array<Question_Statuses>;
};

/** aggregate fields of "question_statuses" */
export type Question_Statuses_Aggregate_Fields = {
  __typename?: 'question_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Question_Statuses_Max_Fields>;
  min?: Maybe<Question_Statuses_Min_Fields>;
};


/** aggregate fields of "question_statuses" */
export type Question_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Question_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "question_statuses". All fields are combined with a logical 'AND'. */
export type Question_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Question_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Question_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Statuses_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  official_questions?: InputMaybe<Official_Questions_Bool_Exp>;
  official_questions_aggregate?: InputMaybe<Official_Questions_Aggregate_Bool_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "question_statuses" */
export enum Question_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionStatusesPkey = 'question_statuses_pkey'
}

/** input type for inserting data into table "question_statuses" */
export type Question_Statuses_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  official_questions?: InputMaybe<Official_Questions_Arr_Rel_Insert_Input>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Question_Statuses_Max_Fields = {
  __typename?: 'question_statuses_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Question_Statuses_Min_Fields = {
  __typename?: 'question_statuses_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question_statuses" */
export type Question_Statuses_Mutation_Response = {
  __typename?: 'question_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Question_Statuses>;
};

/** input type for inserting object relation for remote table "question_statuses" */
export type Question_Statuses_Obj_Rel_Insert_Input = {
  data: Question_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "question_statuses" */
export type Question_Statuses_On_Conflict = {
  constraint: Question_Statuses_Constraint;
  update_columns?: Array<Question_Statuses_Update_Column>;
  where?: InputMaybe<Question_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "question_statuses". */
export type Question_Statuses_Order_By = {
  id?: InputMaybe<Order_By>;
  official_questions_aggregate?: InputMaybe<Official_Questions_Aggregate_Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
};

/** primary key columns input for table: question_statuses */
export type Question_Statuses_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "question_statuses" */
export enum Question_Statuses_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "question_statuses" */
export type Question_Statuses_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "question_statuses" */
export type Question_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Question_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Question_Statuses_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "question_statuses" */
export enum Question_Statuses_Update_Column {
  /** column name */
  Id = 'id'
}

export type Question_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Question_Statuses_Bool_Exp;
};

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  /** An object relationship */
  answerer: Users;
  answererID: Scalars['uuid'];
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hashtags?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  images?: Maybe<Scalars['jsonb']>;
  isPublic: Scalars['Boolean'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  price: Scalars['numeric'];
  /** An array relationship */
  purchased_questions: Array<Purchased_Questions>;
  /** An aggregate relationship */
  purchased_questions_aggregate: Purchased_Questions_Aggregate;
  /** An object relationship */
  question_status: Question_Statuses;
  questionerID: Scalars['uuid'];
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByQuestionerid: Users;
};


/** columns and relationships of "questions" */
export type QuestionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsHashtagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "questions" */
export type QuestionsImagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "questions" */
export type QuestionsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsPurchased_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsPurchased_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

export type Questions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Questions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Questions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Questions_Aggregate_Bool_Exp_Count>;
};

export type Questions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
  stddev?: Maybe<Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questions_Sum_Fields>;
  var_pop?: Maybe<Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questions_Var_Samp_Fields>;
  variance?: Maybe<Questions_Variance_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Max_Order_By>;
  min?: InputMaybe<Questions_Min_Order_By>;
  stddev?: InputMaybe<Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Questions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questions_Append_Input = {
  hashtags?: InputMaybe<Scalars['jsonb']>;
  images?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Questions_Avg_Fields = {
  __typename?: 'questions_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "questions" */
export type Questions_Avg_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  answerer?: InputMaybe<Users_Bool_Exp>;
  answererID?: InputMaybe<Uuid_Comparison_Exp>;
  answers?: InputMaybe<Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Bool_Exp>;
  canceledAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hashtags?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  images?: InputMaybe<Jsonb_Comparison_Exp>;
  isPublic?: InputMaybe<Boolean_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  payments?: InputMaybe<Payments_Bool_Exp>;
  payments_aggregate?: InputMaybe<Payments_Aggregate_Bool_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  purchased_questions?: InputMaybe<Purchased_Questions_Bool_Exp>;
  purchased_questions_aggregate?: InputMaybe<Purchased_Questions_Aggregate_Bool_Exp>;
  question_status?: InputMaybe<Question_Statuses_Bool_Exp>;
  questionerID?: InputMaybe<Uuid_Comparison_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByQuestionerid?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questions_Delete_At_Path_Input = {
  hashtags?: InputMaybe<Array<Scalars['String']>>;
  images?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questions_Delete_Elem_Input = {
  hashtags?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questions_Delete_Key_Input = {
  hashtags?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "questions" */
export type Questions_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  answerer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  answererID?: InputMaybe<Scalars['uuid']>;
  answers?: InputMaybe<Answers_Arr_Rel_Insert_Input>;
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  hashtags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  payments?: InputMaybe<Payments_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['numeric']>;
  purchased_questions?: InputMaybe<Purchased_Questions_Arr_Rel_Insert_Input>;
  question_status?: InputMaybe<Question_Statuses_Obj_Rel_Insert_Input>;
  questionerID?: InputMaybe<Scalars['uuid']>;
  reports?: InputMaybe<Reports_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByQuestionerid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  answererID?: Maybe<Scalars['uuid']>;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  questionerID?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  answererID?: InputMaybe<Order_By>;
  canceledAt?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  questionerID?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  answererID?: Maybe<Scalars['uuid']>;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  questionerID?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  answererID?: InputMaybe<Order_By>;
  canceledAt?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  questionerID?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  answerer?: InputMaybe<Users_Order_By>;
  answererID?: InputMaybe<Order_By>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Order_By>;
  canceledAt?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  hashtags?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  images?: InputMaybe<Order_By>;
  isPublic?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  payments_aggregate?: InputMaybe<Payments_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  purchased_questions_aggregate?: InputMaybe<Purchased_Questions_Aggregate_Order_By>;
  question_status?: InputMaybe<Question_Statuses_Order_By>;
  questionerID?: InputMaybe<Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByQuestionerid?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questions_Prepend_Input = {
  hashtags?: InputMaybe<Scalars['jsonb']>;
  images?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  AnswererId = 'answererID',
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Price = 'price',
  /** column name */
  QuestionerId = 'questionerID',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "questions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questions" */
export enum Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic'
}

/** select "questions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questions" */
export enum Questions_Select_Column_Questions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  answererID?: InputMaybe<Scalars['uuid']>;
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  hashtags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['numeric']>;
  questionerID?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Questions_Stddev_Fields = {
  __typename?: 'questions_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "questions" */
export type Questions_Stddev_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questions_Stddev_Pop_Fields = {
  __typename?: 'questions_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "questions" */
export type Questions_Stddev_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questions_Stddev_Samp_Fields = {
  __typename?: 'questions_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "questions" */
export type Questions_Stddev_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "questions" */
export type Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Stream_Cursor_Value_Input = {
  answererID?: InputMaybe<Scalars['uuid']>;
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  hashtags?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  images?: InputMaybe<Scalars['jsonb']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['numeric']>;
  questionerID?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Questions_Sum_Fields = {
  __typename?: 'questions_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "questions" */
export type Questions_Sum_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  AnswererId = 'answererID',
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Price = 'price',
  /** column name */
  QuestionerId = 'questionerID',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questions_Var_Pop_Fields = {
  __typename?: 'questions_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "questions" */
export type Questions_Var_Pop_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questions_Var_Samp_Fields = {
  __typename?: 'questions_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "questions" */
export type Questions_Var_Samp_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questions_Variance_Fields = {
  __typename?: 'questions_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "questions" */
export type Questions_Variance_Order_By = {
  price?: InputMaybe<Order_By>;
};

/** columns and relationships of "ratings" */
export type Ratings = {
  __typename?: 'ratings';
  /** An object relationship */
  answer: Answers;
  answerID: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  raterID: Scalars['uuid'];
  rating: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "ratings" */
export type Ratings_Aggregate = {
  __typename?: 'ratings_aggregate';
  aggregate?: Maybe<Ratings_Aggregate_Fields>;
  nodes: Array<Ratings>;
};

export type Ratings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ratings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ratings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ratings_Aggregate_Bool_Exp_Count>;
};

export type Ratings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ratings_Select_Column_Ratings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ratings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ratings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ratings_Select_Column_Ratings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ratings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ratings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ratings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ratings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ratings" */
export type Ratings_Aggregate_Fields = {
  __typename?: 'ratings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ratings_Max_Fields>;
  min?: Maybe<Ratings_Min_Fields>;
};


/** aggregate fields of "ratings" */
export type Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ratings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ratings" */
export type Ratings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ratings_Max_Order_By>;
  min?: InputMaybe<Ratings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ratings" */
export type Ratings_Arr_Rel_Insert_Input = {
  data: Array<Ratings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ratings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ratings". All fields are combined with a logical 'AND'. */
export type Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Ratings_Bool_Exp>>;
  _not?: InputMaybe<Ratings_Bool_Exp>;
  _or?: InputMaybe<Array<Ratings_Bool_Exp>>;
  answer?: InputMaybe<Answers_Bool_Exp>;
  answerID?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  raterID?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "ratings" */
export enum Ratings_Constraint {
  /** unique or primary key constraint on columns "id" */
  RatingsPkey = 'ratings_pkey'
}

/** input type for inserting data into table "ratings" */
export type Ratings_Insert_Input = {
  answer?: InputMaybe<Answers_Obj_Rel_Insert_Input>;
  answerID?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  raterID?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ratings_Max_Fields = {
  __typename?: 'ratings_max_fields';
  answerID?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  raterID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ratings" */
export type Ratings_Max_Order_By = {
  answerID?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  raterID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ratings_Min_Fields = {
  __typename?: 'ratings_min_fields';
  answerID?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  raterID?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ratings" */
export type Ratings_Min_Order_By = {
  answerID?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  raterID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ratings" */
export type Ratings_Mutation_Response = {
  __typename?: 'ratings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ratings>;
};

/** on_conflict condition type for table "ratings" */
export type Ratings_On_Conflict = {
  constraint: Ratings_Constraint;
  update_columns?: Array<Ratings_Update_Column>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};

/** Ordering options when selecting data from "ratings". */
export type Ratings_Order_By = {
  answer?: InputMaybe<Answers_Order_By>;
  answerID?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  raterID?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: ratings */
export type Ratings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ratings" */
export enum Ratings_Select_Column {
  /** column name */
  AnswerId = 'answerID',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  RaterId = 'raterID',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "ratings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ratings" */
export enum Ratings_Select_Column_Ratings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Rating = 'rating'
}

/** select "ratings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ratings" */
export enum Ratings_Select_Column_Ratings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Rating = 'rating'
}

/** input type for updating data in table "ratings" */
export type Ratings_Set_Input = {
  answerID?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  raterID?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ratings" */
export type Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ratings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ratings_Stream_Cursor_Value_Input = {
  answerID?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  raterID?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ratings" */
export enum Ratings_Update_Column {
  /** column name */
  AnswerId = 'answerID',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  RaterId = 'raterID',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Ratings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ratings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ratings_Bool_Exp;
};

/** columns and relationships of "reports" */
export type Reports = {
  __typename?: 'reports';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  question: Questions;
  questionID: Scalars['uuid'];
  reason: Scalars['String'];
  reporterID: Scalars['uuid'];
  subject: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "reports" */
export type Reports_Aggregate = {
  __typename?: 'reports_aggregate';
  aggregate?: Maybe<Reports_Aggregate_Fields>;
  nodes: Array<Reports>;
};

export type Reports_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reports_Aggregate_Bool_Exp_Count>;
};

export type Reports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reports" */
export type Reports_Aggregate_Fields = {
  __typename?: 'reports_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reports_Max_Fields>;
  min?: Maybe<Reports_Min_Fields>;
};


/** aggregate fields of "reports" */
export type Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reports" */
export type Reports_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reports_Max_Order_By>;
  min?: InputMaybe<Reports_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reports" */
export type Reports_Arr_Rel_Insert_Input = {
  data: Array<Reports_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reports_Bool_Exp>>;
  _not?: InputMaybe<Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reports_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  questionID?: InputMaybe<Uuid_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  reporterID?: InputMaybe<Uuid_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  questionID?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  reporterID?: InputMaybe<Scalars['uuid']>;
  subject?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Reports_Max_Fields = {
  __typename?: 'reports_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionID?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  reporterID?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reports" */
export type Reports_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  reporterID?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reports_Min_Fields = {
  __typename?: 'reports_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  questionID?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  reporterID?: Maybe<Scalars['uuid']>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reports" */
export type Reports_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionID?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  reporterID?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  constraint: Reports_Constraint;
  update_columns?: Array<Reports_Update_Column>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  questionID?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  reporterID?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReporterId = 'reporterID',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  reporterID?: InputMaybe<Scalars['uuid']>;
  subject?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionID?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  reporterID?: InputMaybe<Scalars['uuid']>;
  subject?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'questionID',
  /** column name */
  Reason = 'reason',
  /** column name */
  ReporterId = 'reporterID',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Reports_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reports_Bool_Exp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "_prisma_migrations" */
  _prisma_migrations: Array<_Prisma_Migrations>;
  /** fetch aggregated fields from the table: "_prisma_migrations" */
  _prisma_migrations_aggregate: _Prisma_Migrations_Aggregate;
  /** fetch data from the table: "_prisma_migrations" using primary key columns */
  _prisma_migrations_by_pk?: Maybe<_Prisma_Migrations>;
  /** fetch data from the table in a streaming manner: "_prisma_migrations" */
  _prisma_migrations_stream: Array<_Prisma_Migrations>;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  /** fetch data from the table: "answers" using primary key columns */
  answers_by_pk?: Maybe<Answers>;
  /** fetch data from the table in a streaming manner: "answers" */
  answers_stream: Array<Answers>;
  /** fetch data from the table: "auth.audit_log_entries" */
  auth_audit_log_entries: Array<Auth_Audit_Log_Entries>;
  /** fetch aggregated fields from the table: "auth.audit_log_entries" */
  auth_audit_log_entries_aggregate: Auth_Audit_Log_Entries_Aggregate;
  /** fetch data from the table: "auth.audit_log_entries" using primary key columns */
  auth_audit_log_entries_by_pk?: Maybe<Auth_Audit_Log_Entries>;
  /** fetch data from the table in a streaming manner: "auth.audit_log_entries" */
  auth_audit_log_entries_stream: Array<Auth_Audit_Log_Entries>;
  /** fetch data from the table: "auth.identities" */
  auth_identities: Array<Auth_Identities>;
  /** fetch aggregated fields from the table: "auth.identities" */
  auth_identities_aggregate: Auth_Identities_Aggregate;
  /** fetch data from the table: "auth.identities" using primary key columns */
  auth_identities_by_pk?: Maybe<Auth_Identities>;
  /** fetch data from the table in a streaming manner: "auth.identities" */
  auth_identities_stream: Array<Auth_Identities>;
  /** fetch data from the table: "auth.instances" */
  auth_instances: Array<Auth_Instances>;
  /** fetch aggregated fields from the table: "auth.instances" */
  auth_instances_aggregate: Auth_Instances_Aggregate;
  /** fetch data from the table: "auth.instances" using primary key columns */
  auth_instances_by_pk?: Maybe<Auth_Instances>;
  /** fetch data from the table in a streaming manner: "auth.instances" */
  auth_instances_stream: Array<Auth_Instances>;
  /** fetch data from the table: "auth.mfa_amr_claims" */
  auth_mfa_amr_claims: Array<Auth_Mfa_Amr_Claims>;
  /** fetch aggregated fields from the table: "auth.mfa_amr_claims" */
  auth_mfa_amr_claims_aggregate: Auth_Mfa_Amr_Claims_Aggregate;
  /** fetch data from the table: "auth.mfa_amr_claims" using primary key columns */
  auth_mfa_amr_claims_by_pk?: Maybe<Auth_Mfa_Amr_Claims>;
  /** fetch data from the table in a streaming manner: "auth.mfa_amr_claims" */
  auth_mfa_amr_claims_stream: Array<Auth_Mfa_Amr_Claims>;
  /** fetch data from the table: "auth.mfa_challenges" */
  auth_mfa_challenges: Array<Auth_Mfa_Challenges>;
  /** fetch aggregated fields from the table: "auth.mfa_challenges" */
  auth_mfa_challenges_aggregate: Auth_Mfa_Challenges_Aggregate;
  /** fetch data from the table: "auth.mfa_challenges" using primary key columns */
  auth_mfa_challenges_by_pk?: Maybe<Auth_Mfa_Challenges>;
  /** fetch data from the table in a streaming manner: "auth.mfa_challenges" */
  auth_mfa_challenges_stream: Array<Auth_Mfa_Challenges>;
  /** fetch data from the table: "auth.mfa_factors" */
  auth_mfa_factors: Array<Auth_Mfa_Factors>;
  /** fetch aggregated fields from the table: "auth.mfa_factors" */
  auth_mfa_factors_aggregate: Auth_Mfa_Factors_Aggregate;
  /** fetch data from the table: "auth.mfa_factors" using primary key columns */
  auth_mfa_factors_by_pk?: Maybe<Auth_Mfa_Factors>;
  /** fetch data from the table in a streaming manner: "auth.mfa_factors" */
  auth_mfa_factors_stream: Array<Auth_Mfa_Factors>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  auth_refresh_tokens_stream: Array<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.saml_providers" */
  auth_saml_providers: Array<Auth_Saml_Providers>;
  /** fetch aggregated fields from the table: "auth.saml_providers" */
  auth_saml_providers_aggregate: Auth_Saml_Providers_Aggregate;
  /** fetch data from the table: "auth.saml_providers" using primary key columns */
  auth_saml_providers_by_pk?: Maybe<Auth_Saml_Providers>;
  /** fetch data from the table in a streaming manner: "auth.saml_providers" */
  auth_saml_providers_stream: Array<Auth_Saml_Providers>;
  /** fetch data from the table: "auth.saml_relay_states" */
  auth_saml_relay_states: Array<Auth_Saml_Relay_States>;
  /** fetch aggregated fields from the table: "auth.saml_relay_states" */
  auth_saml_relay_states_aggregate: Auth_Saml_Relay_States_Aggregate;
  /** fetch data from the table: "auth.saml_relay_states" using primary key columns */
  auth_saml_relay_states_by_pk?: Maybe<Auth_Saml_Relay_States>;
  /** fetch data from the table in a streaming manner: "auth.saml_relay_states" */
  auth_saml_relay_states_stream: Array<Auth_Saml_Relay_States>;
  /** fetch data from the table: "auth.schema_migrations" */
  auth_schema_migrations: Array<Auth_Schema_Migrations>;
  /** fetch aggregated fields from the table: "auth.schema_migrations" */
  auth_schema_migrations_aggregate: Auth_Schema_Migrations_Aggregate;
  /** fetch data from the table: "auth.schema_migrations" using primary key columns */
  auth_schema_migrations_by_pk?: Maybe<Auth_Schema_Migrations>;
  /** fetch data from the table in a streaming manner: "auth.schema_migrations" */
  auth_schema_migrations_stream: Array<Auth_Schema_Migrations>;
  /** fetch data from the table: "auth.sessions" */
  auth_sessions: Array<Auth_Sessions>;
  /** fetch aggregated fields from the table: "auth.sessions" */
  auth_sessions_aggregate: Auth_Sessions_Aggregate;
  /** fetch data from the table: "auth.sessions" using primary key columns */
  auth_sessions_by_pk?: Maybe<Auth_Sessions>;
  /** fetch data from the table in a streaming manner: "auth.sessions" */
  auth_sessions_stream: Array<Auth_Sessions>;
  /** fetch data from the table: "auth.sso_domains" */
  auth_sso_domains: Array<Auth_Sso_Domains>;
  /** fetch aggregated fields from the table: "auth.sso_domains" */
  auth_sso_domains_aggregate: Auth_Sso_Domains_Aggregate;
  /** fetch data from the table: "auth.sso_domains" using primary key columns */
  auth_sso_domains_by_pk?: Maybe<Auth_Sso_Domains>;
  /** fetch data from the table in a streaming manner: "auth.sso_domains" */
  auth_sso_domains_stream: Array<Auth_Sso_Domains>;
  /** fetch data from the table: "auth.sso_providers" */
  auth_sso_providers: Array<Auth_Sso_Providers>;
  /** fetch aggregated fields from the table: "auth.sso_providers" */
  auth_sso_providers_aggregate: Auth_Sso_Providers_Aggregate;
  /** fetch data from the table: "auth.sso_providers" using primary key columns */
  auth_sso_providers_by_pk?: Maybe<Auth_Sso_Providers>;
  /** fetch data from the table in a streaming manner: "auth.sso_providers" */
  auth_sso_providers_stream: Array<Auth_Sso_Providers>;
  /** fetch data from the table: "auth.users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "auth.users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** fetch data from the table in a streaming manner: "auth.users" */
  auth_users_stream: Array<Auth_Users>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table in a streaming manner: "contacts" */
  contacts_stream: Array<Contacts>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** An array relationship */
  official_questions: Array<Official_Questions>;
  /** An aggregate relationship */
  official_questions_aggregate: Official_Questions_Aggregate;
  /** fetch data from the table: "official_questions" using primary key columns */
  official_questions_by_pk?: Maybe<Official_Questions>;
  /** fetch data from the table in a streaming manner: "official_questions" */
  official_questions_stream: Array<Official_Questions>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table in a streaming manner: "payments" */
  payments_stream: Array<Payments>;
  /** An array relationship */
  purchased_questions: Array<Purchased_Questions>;
  /** An aggregate relationship */
  purchased_questions_aggregate: Purchased_Questions_Aggregate;
  /** fetch data from the table: "purchased_questions" using primary key columns */
  purchased_questions_by_pk?: Maybe<Purchased_Questions>;
  /** fetch data from the table in a streaming manner: "purchased_questions" */
  purchased_questions_stream: Array<Purchased_Questions>;
  /** fetch data from the table: "question_statuses" */
  question_statuses: Array<Question_Statuses>;
  /** fetch aggregated fields from the table: "question_statuses" */
  question_statuses_aggregate: Question_Statuses_Aggregate;
  /** fetch data from the table: "question_statuses" using primary key columns */
  question_statuses_by_pk?: Maybe<Question_Statuses>;
  /** fetch data from the table in a streaming manner: "question_statuses" */
  question_statuses_stream: Array<Question_Statuses>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** An array relationship */
  ratings: Array<Ratings>;
  /** An aggregate relationship */
  ratings_aggregate: Ratings_Aggregate;
  /** fetch data from the table: "ratings" using primary key columns */
  ratings_by_pk?: Maybe<Ratings>;
  /** fetch data from the table in a streaming manner: "ratings" */
  ratings_stream: Array<Ratings>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_Root_Prisma_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<_Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Prisma_Migrations_Order_By>>;
  where?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
};


export type Subscription_Root_Prisma_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<_Prisma_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<_Prisma_Migrations_Order_By>>;
  where?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
};


export type Subscription_Root_Prisma_Migrations_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_Root_Prisma_Migrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<_Prisma_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<_Prisma_Migrations_Bool_Exp>;
};


export type Subscription_RootAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAnswers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAnswers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


export type Subscription_RootAuth_Audit_Log_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
};


export type Subscription_RootAuth_Audit_Log_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
};


export type Subscription_RootAuth_Audit_Log_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Audit_Log_Entries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Audit_Log_Entries_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Audit_Log_Entries_Bool_Exp>;
};


export type Subscription_RootAuth_IdentitiesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Identities_Order_By>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


export type Subscription_RootAuth_Identities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Identities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Identities_Order_By>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


export type Subscription_RootAuth_Identities_By_PkArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};


export type Subscription_RootAuth_Identities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Identities_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Identities_Bool_Exp>;
};


export type Subscription_RootAuth_InstancesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Instances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Instances_Order_By>>;
  where?: InputMaybe<Auth_Instances_Bool_Exp>;
};


export type Subscription_RootAuth_Instances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Instances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Instances_Order_By>>;
  where?: InputMaybe<Auth_Instances_Bool_Exp>;
};


export type Subscription_RootAuth_Instances_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Instances_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Instances_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Instances_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Amr_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Amr_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Amr_Claims_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Amr_Claims_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Mfa_Amr_Claims_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Mfa_Amr_Claims_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Mfa_Amr_Claims_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_ChallengesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Challenges_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Challenges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Challenges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Challenges_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Challenges_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Mfa_Challenges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Mfa_Challenges_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Mfa_Challenges_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_FactorsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Factors_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Mfa_Factors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Mfa_Factors_Order_By>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


export type Subscription_RootAuth_Mfa_Factors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Mfa_Factors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Mfa_Factors_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Mfa_Factors_Bool_Exp>;
};


export type Subscription_RootAuth_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Refresh_Tokens_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Refresh_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Refresh_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Saml_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Providers_Order_By>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Saml_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Providers_Order_By>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Saml_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Saml_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Saml_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Saml_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Saml_Relay_StatesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Relay_States_Order_By>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


export type Subscription_RootAuth_Saml_Relay_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Saml_Relay_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Saml_Relay_States_Order_By>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


export type Subscription_RootAuth_Saml_Relay_States_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Saml_Relay_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Saml_Relay_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Saml_Relay_States_Bool_Exp>;
};


export type Subscription_RootAuth_Schema_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Schema_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Schema_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
};


export type Subscription_RootAuth_Schema_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Schema_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Schema_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
};


export type Subscription_RootAuth_Schema_Migrations_By_PkArgs = {
  version: Scalars['String'];
};


export type Subscription_RootAuth_Schema_Migrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Schema_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Schema_Migrations_Bool_Exp>;
};


export type Subscription_RootAuth_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sessions_Order_By>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};


export type Subscription_RootAuth_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sessions_Order_By>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};


export type Subscription_RootAuth_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Sessions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Sessions_Bool_Exp>;
};


export type Subscription_RootAuth_Sso_DomainsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Domains_Order_By>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};


export type Subscription_RootAuth_Sso_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Domains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Domains_Order_By>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};


export type Subscription_RootAuth_Sso_Domains_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Sso_Domains_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Sso_Domains_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Sso_Domains_Bool_Exp>;
};


export type Subscription_RootAuth_Sso_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Providers_Order_By>>;
  where?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Sso_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Sso_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Sso_Providers_Order_By>>;
  where?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Sso_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Sso_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Sso_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Sso_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootContactsArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contacts_Order_By>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Contacts_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOfficial_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Official_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Official_Questions_Order_By>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


export type Subscription_RootOfficial_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Official_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Official_Questions_Order_By>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


export type Subscription_RootOfficial_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOfficial_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Official_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Official_Questions_Bool_Exp>;
};


export type Subscription_RootPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


export type Subscription_RootPayments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payments_Stream_Cursor_Input>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


export type Subscription_RootPurchased_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


export type Subscription_RootPurchased_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


export type Subscription_RootPurchased_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPurchased_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Purchased_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


export type Subscription_RootQuestion_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Question_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Statuses_Order_By>>;
  where?: InputMaybe<Question_Statuses_Bool_Exp>;
};


export type Subscription_RootQuestion_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Question_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Question_Statuses_Order_By>>;
  where?: InputMaybe<Question_Statuses_Bool_Exp>;
};


export type Subscription_RootQuestion_Statuses_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootQuestion_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Question_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Statuses_Bool_Exp>;
};


export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootRatingsArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


export type Subscription_RootRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


export type Subscription_RootRatings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRatings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ratings_Stream_Cursor_Input>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: 'user_roles';
  id: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "user_roles" */
export type User_RolesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "user_roles" */
export type User_RolesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: 'user_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};


/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey'
}

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: 'user_roles_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: 'user_roles_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: 'user_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** input type for inserting object relation for remote table "user_roles" */
export type User_Roles_Obj_Rel_Insert_Input = {
  data: User_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Id = 'id'
}

export type User_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Roles_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  accountName: Scalars['String'];
  answererApplicationStatus?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  answers: Array<Answers>;
  /** An aggregate relationship */
  answers_aggregate: Answers_Aggregate;
  approvedAt?: Maybe<Scalars['timestamptz']>;
  bio?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  followers?: Maybe<Scalars['Int']>;
  hasBankInfo?: Maybe<Scalars['Boolean']>;
  hasCardInfo?: Maybe<Scalars['Boolean']>;
  hasIdentityInfo?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  isIdentitySubmitted?: Maybe<Scalars['Boolean']>;
  isIdentityVerified?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An array relationship */
  notificationsByTouserid: Array<Notifications>;
  /** An aggregate relationship */
  notificationsByTouserid_aggregate: Notifications_Aggregate;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An array relationship */
  payments: Array<Payments>;
  /** An array relationship */
  paymentsByQuestioneruserid: Array<Payments>;
  /** An aggregate relationship */
  paymentsByQuestioneruserid_aggregate: Payments_Aggregate;
  /** An aggregate relationship */
  payments_aggregate: Payments_Aggregate;
  priceSetting: Scalars['numeric'];
  provider?: Maybe<Scalars['String']>;
  /** An array relationship */
  purchased_questions: Array<Purchased_Questions>;
  /** An aggregate relationship */
  purchased_questions_aggregate: Purchased_Questions_Aggregate;
  /** An array relationship */
  questions: Array<Questions>;
  /** An array relationship */
  questionsByQuestionerid: Array<Questions>;
  /** An aggregate relationship */
  questionsByQuestionerid_aggregate: Questions_Aggregate;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  ratings: Array<Ratings>;
  /** An aggregate relationship */
  ratings_aggregate: Ratings_Aggregate;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  role: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  stripeAccountID?: Maybe<Scalars['String']>;
  stripeCustomerID?: Maybe<Scalars['String']>;
  twitterMetadata?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user_role: User_Roles;
  username: Scalars['String'];
};


/** columns and relationships of "users" */
export type UsersAnswersArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Answers_Order_By>>;
  where?: InputMaybe<Answers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsByTouseridArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotificationsByTouserid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPaymentsByQuestioneruseridArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPaymentsByQuestioneruserid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payments_Order_By>>;
  where?: InputMaybe<Payments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPurchased_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPurchased_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchased_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchased_Questions_Order_By>>;
  where?: InputMaybe<Purchased_Questions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersQuestionsByQuestioneridArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersQuestionsByQuestionerid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRatingsArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ratings_Order_By>>;
  where?: InputMaybe<Ratings_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTwitterMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  twitterMetadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  accountName?: InputMaybe<String_Comparison_Exp>;
  answererApplicationStatus?: InputMaybe<Boolean_Comparison_Exp>;
  answers?: InputMaybe<Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Bool_Exp>;
  approvedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  bio?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  followers?: InputMaybe<Int_Comparison_Exp>;
  hasBankInfo?: InputMaybe<Boolean_Comparison_Exp>;
  hasCardInfo?: InputMaybe<Boolean_Comparison_Exp>;
  hasIdentityInfo?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  isIdentitySubmitted?: InputMaybe<Boolean_Comparison_Exp>;
  isIdentityVerified?: InputMaybe<Boolean_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notificationsByTouserid?: InputMaybe<Notifications_Bool_Exp>;
  notificationsByTouserid_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  payments?: InputMaybe<Payments_Bool_Exp>;
  paymentsByQuestioneruserid?: InputMaybe<Payments_Bool_Exp>;
  paymentsByQuestioneruserid_aggregate?: InputMaybe<Payments_Aggregate_Bool_Exp>;
  payments_aggregate?: InputMaybe<Payments_Aggregate_Bool_Exp>;
  priceSetting?: InputMaybe<Numeric_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  purchased_questions?: InputMaybe<Purchased_Questions_Bool_Exp>;
  purchased_questions_aggregate?: InputMaybe<Purchased_Questions_Aggregate_Bool_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questionsByQuestionerid?: InputMaybe<Questions_Bool_Exp>;
  questionsByQuestionerid_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  ratings?: InputMaybe<Ratings_Bool_Exp>;
  ratings_aggregate?: InputMaybe<Ratings_Aggregate_Bool_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stripeAccountID?: InputMaybe<String_Comparison_Exp>;
  stripeCustomerID?: InputMaybe<String_Comparison_Exp>;
  twitterMetadata?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_role?: InputMaybe<User_Roles_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "stripeAccountID" */
  UsersStripeAccountIdKey = 'users_stripeAccountID_key',
  /** unique or primary key constraint on columns "stripeCustomerID" */
  UsersStripeCustomerIdKey = 'users_stripeCustomerID_key',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  twitterMetadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  twitterMetadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  twitterMetadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  followers?: InputMaybe<Scalars['Int']>;
  priceSetting?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  accountName?: InputMaybe<Scalars['String']>;
  answererApplicationStatus?: InputMaybe<Scalars['Boolean']>;
  answers?: InputMaybe<Answers_Arr_Rel_Insert_Input>;
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  bio?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<Scalars['Int']>;
  hasBankInfo?: InputMaybe<Scalars['Boolean']>;
  hasCardInfo?: InputMaybe<Scalars['Boolean']>;
  hasIdentityInfo?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isIdentitySubmitted?: InputMaybe<Scalars['Boolean']>;
  isIdentityVerified?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  notificationsByTouserid?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  payments?: InputMaybe<Payments_Arr_Rel_Insert_Input>;
  paymentsByQuestioneruserid?: InputMaybe<Payments_Arr_Rel_Insert_Input>;
  priceSetting?: InputMaybe<Scalars['numeric']>;
  provider?: InputMaybe<Scalars['String']>;
  purchased_questions?: InputMaybe<Purchased_Questions_Arr_Rel_Insert_Input>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  questionsByQuestionerid?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  ratings?: InputMaybe<Ratings_Arr_Rel_Insert_Input>;
  reports?: InputMaybe<Reports_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripeAccountID?: InputMaybe<Scalars['String']>;
  stripeCustomerID?: InputMaybe<Scalars['String']>;
  twitterMetadata?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user_role?: InputMaybe<User_Roles_Obj_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  accountName?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['timestamptz']>;
  bio?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  priceSetting?: Maybe<Scalars['numeric']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripeAccountID?: Maybe<Scalars['String']>;
  stripeCustomerID?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  accountName?: InputMaybe<Order_By>;
  approvedAt?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  followers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripeAccountID?: InputMaybe<Order_By>;
  stripeCustomerID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  accountName?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['timestamptz']>;
  bio?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  priceSetting?: Maybe<Scalars['numeric']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripeAccountID?: Maybe<Scalars['String']>;
  stripeCustomerID?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  accountName?: InputMaybe<Order_By>;
  approvedAt?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  followers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripeAccountID?: InputMaybe<Order_By>;
  stripeCustomerID?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  accountName?: InputMaybe<Order_By>;
  answererApplicationStatus?: InputMaybe<Order_By>;
  answers_aggregate?: InputMaybe<Answers_Aggregate_Order_By>;
  approvedAt?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  followers?: InputMaybe<Order_By>;
  hasBankInfo?: InputMaybe<Order_By>;
  hasCardInfo?: InputMaybe<Order_By>;
  hasIdentityInfo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  isIdentitySubmitted?: InputMaybe<Order_By>;
  isIdentityVerified?: InputMaybe<Order_By>;
  notificationsByTouserid_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  paymentsByQuestioneruserid_aggregate?: InputMaybe<Payments_Aggregate_Order_By>;
  payments_aggregate?: InputMaybe<Payments_Aggregate_Order_By>;
  priceSetting?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  purchased_questions_aggregate?: InputMaybe<Purchased_Questions_Aggregate_Order_By>;
  questionsByQuestionerid_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  ratings_aggregate?: InputMaybe<Ratings_Aggregate_Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripeAccountID?: InputMaybe<Order_By>;
  stripeCustomerID?: InputMaybe<Order_By>;
  twitterMetadata?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_role?: InputMaybe<User_Roles_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  twitterMetadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AccountName = 'accountName',
  /** column name */
  AnswererApplicationStatus = 'answererApplicationStatus',
  /** column name */
  ApprovedAt = 'approvedAt',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Followers = 'followers',
  /** column name */
  HasBankInfo = 'hasBankInfo',
  /** column name */
  HasCardInfo = 'hasCardInfo',
  /** column name */
  HasIdentityInfo = 'hasIdentityInfo',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsIdentitySubmitted = 'isIdentitySubmitted',
  /** column name */
  IsIdentityVerified = 'isIdentityVerified',
  /** column name */
  PriceSetting = 'priceSetting',
  /** column name */
  Provider = 'provider',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  StripeAccountId = 'stripeAccountID',
  /** column name */
  StripeCustomerId = 'stripeCustomerID',
  /** column name */
  TwitterMetadata = 'twitterMetadata',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AnswererApplicationStatus = 'answererApplicationStatus',
  /** column name */
  HasBankInfo = 'hasBankInfo',
  /** column name */
  HasCardInfo = 'hasCardInfo',
  /** column name */
  HasIdentityInfo = 'hasIdentityInfo',
  /** column name */
  IsIdentitySubmitted = 'isIdentitySubmitted',
  /** column name */
  IsIdentityVerified = 'isIdentityVerified'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AnswererApplicationStatus = 'answererApplicationStatus',
  /** column name */
  HasBankInfo = 'hasBankInfo',
  /** column name */
  HasCardInfo = 'hasCardInfo',
  /** column name */
  HasIdentityInfo = 'hasIdentityInfo',
  /** column name */
  IsIdentitySubmitted = 'isIdentitySubmitted',
  /** column name */
  IsIdentityVerified = 'isIdentityVerified'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  accountName?: InputMaybe<Scalars['String']>;
  answererApplicationStatus?: InputMaybe<Scalars['Boolean']>;
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  bio?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<Scalars['Int']>;
  hasBankInfo?: InputMaybe<Scalars['Boolean']>;
  hasCardInfo?: InputMaybe<Scalars['Boolean']>;
  hasIdentityInfo?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isIdentitySubmitted?: InputMaybe<Scalars['Boolean']>;
  isIdentityVerified?: InputMaybe<Scalars['Boolean']>;
  priceSetting?: InputMaybe<Scalars['numeric']>;
  provider?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripeAccountID?: InputMaybe<Scalars['String']>;
  stripeCustomerID?: InputMaybe<Scalars['String']>;
  twitterMetadata?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  accountName?: InputMaybe<Scalars['String']>;
  answererApplicationStatus?: InputMaybe<Scalars['Boolean']>;
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  bio?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  followers?: InputMaybe<Scalars['Int']>;
  hasBankInfo?: InputMaybe<Scalars['Boolean']>;
  hasCardInfo?: InputMaybe<Scalars['Boolean']>;
  hasIdentityInfo?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  isIdentitySubmitted?: InputMaybe<Scalars['Boolean']>;
  isIdentityVerified?: InputMaybe<Scalars['Boolean']>;
  priceSetting?: InputMaybe<Scalars['numeric']>;
  provider?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripeAccountID?: InputMaybe<Scalars['String']>;
  stripeCustomerID?: InputMaybe<Scalars['String']>;
  twitterMetadata?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  followers?: Maybe<Scalars['Int']>;
  priceSetting?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AccountName = 'accountName',
  /** column name */
  AnswererApplicationStatus = 'answererApplicationStatus',
  /** column name */
  ApprovedAt = 'approvedAt',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Followers = 'followers',
  /** column name */
  HasBankInfo = 'hasBankInfo',
  /** column name */
  HasCardInfo = 'hasCardInfo',
  /** column name */
  HasIdentityInfo = 'hasIdentityInfo',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsIdentitySubmitted = 'isIdentitySubmitted',
  /** column name */
  IsIdentityVerified = 'isIdentityVerified',
  /** column name */
  PriceSetting = 'priceSetting',
  /** column name */
  Provider = 'provider',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  StripeAccountId = 'stripeAccountID',
  /** column name */
  StripeCustomerId = 'stripeCustomerID',
  /** column name */
  TwitterMetadata = 'twitterMetadata',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  followers?: Maybe<Scalars['Float']>;
  priceSetting?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  followers?: InputMaybe<Order_By>;
  priceSetting?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type GetUserNotificationsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserNotificationsQuery = { __typename?: 'query_root', notifications: Array<{ __typename?: 'notifications', id: any, createdAt: any, message: string, status: boolean, updatedAt: any, question: { __typename?: 'questions', id: any, price: any, answerer: { __typename?: 'users', accountName: string }, userByQuestionerid: { __typename?: 'users', accountName: string } }, user: { __typename?: 'users', accountName: string, image?: string | null, email: string, bio?: string | null, id: any, status?: string | null, role: string, username: string, twitterMetadata?: any | null } }> };

export type HasNewNotificationsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type HasNewNotificationsQuery = { __typename?: 'query_root', notifications: Array<{ __typename?: 'notifications', id: any }> };

export type PaymentEnabledStatusQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PaymentEnabledStatusQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', hasBankInfo?: boolean | null, hasCardInfo?: boolean | null, hasIdentityInfo?: boolean | null, isIdentitySubmitted?: boolean | null, isIdentityVerified?: boolean | null } | null };

export type AnswerersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type AnswerersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, email: string, image?: string | null, accountName: string, bio?: string | null, createdAt: any, provider?: string | null, username: string, twitterMetadata?: any | null, answers_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, ratings_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null } }> };

export type AnswerersExcludingMeQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type AnswerersExcludingMeQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, email: string, image?: string | null, accountName: string, bio?: string | null, createdAt: any, provider?: string | null, username: string, twitterMetadata?: any | null, answers_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, ratings_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null } }> };

export type MyStatsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MyStatsQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: any, email: string, image?: string | null, accountName: string, bio?: string | null, createdAt: any, provider?: string | null, username: string, answers_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, ratings_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null } } | null };

export type OfficialQuestionsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type OfficialQuestionsQuery = { __typename?: 'query_root', official_questions: Array<{ __typename?: 'official_questions', id: any, status: string, updatedAt: any, isPublic: boolean, hashtags?: any | null, createdAt: any, content: string, price: any, answers: Array<{ __typename?: 'answers', id: any, createdAt: any }> }> };

export type OfficialQuestionDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type OfficialQuestionDetailQuery = { __typename?: 'query_root', official_questions_by_pk?: { __typename?: 'official_questions', id: any, status: string, updatedAt: any, isPublic: boolean, hashtags?: any | null, createdAt: any, content: string, price: any, purchased_questions: Array<{ __typename?: 'purchased_questions', id: any }>, answers: Array<{ __typename?: 'answers', content: string, id: any, createdAt: any, images?: any | null, user: { __typename?: 'users', id: any, accountName: string, username: string, image?: string | null, twitterMetadata?: any | null } }> } | null };

export type AnsweredOfficialQuestionsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type AnsweredOfficialQuestionsQuery = { __typename?: 'query_root', official_questions: Array<{ __typename?: 'official_questions', answers: Array<{ __typename?: 'answers', official_question?: { __typename?: 'official_questions', id: any, content: string, hashtags?: any | null, createdAt: any, isPublic: boolean, images?: any | null } | null }> }> };

export type PriceSettingQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PriceSettingQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', priceSetting: any }> };

export type PublicQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicQuestionsQuery = { __typename?: 'query_root', questions: Array<{ __typename?: 'questions', id: any, createdAt: any, status: string, updatedAt: any, content: string, isPublic: boolean, price: any, hashtags?: any | null, user: { __typename?: 'users', image?: string | null, id: any, bio?: string | null, accountName: string, username: string } }> };

export type QuestionDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type QuestionDetailsQuery = { __typename?: 'query_root', questions_by_pk?: { __typename?: 'questions', id: any, content: string, createdAt: any, deletedAt?: any | null, hashtags?: any | null, images?: any | null, isPublic: boolean, price: any, status: string, updatedAt: any, user: { __typename?: 'users', accountName: string, id: any, image?: string | null, username: string, deletedAt?: any | null, twitterMetadata?: any | null }, userByQuestionerid: { __typename?: 'users', id: any, accountName: string, image?: string | null, twitterMetadata?: any | null }, answers: Array<{ __typename?: 'answers', content: string, createdAt: any, id: any, images?: any | null, ratings: Array<{ __typename?: 'ratings', rating: boolean, message?: string | null }> }>, purchased_questions: Array<{ __typename?: 'purchased_questions', id: any }> } | null };

export type PublicQuestionDetailQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PublicQuestionDetailQuery = { __typename?: 'query_root', questions_by_pk?: { __typename?: 'questions', id: any, content: string, createdAt: any, deletedAt?: any | null, hashtags?: any | null, images?: any | null, isPublic: boolean, price: any, status: string, updatedAt: any, user: { __typename?: 'users', accountName: string, id: any, image?: string | null, username: string, deletedAt?: any | null, twitterMetadata?: any | null }, userByQuestionerid: { __typename?: 'users', id: any, accountName: string, image?: string | null, twitterMetadata?: any | null }, answers: Array<{ __typename?: 'answers', content: string, createdAt: any, id: any, images?: any | null, ratings: Array<{ __typename?: 'ratings', rating: boolean, message?: string | null }> }> } | null };

export type PurchasedQuestionQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type PurchasedQuestionQuery = { __typename?: 'query_root', purchased_questions: Array<{ __typename?: 'purchased_questions', id: any, createdAt: any, price: any, question?: { __typename?: 'questions', id: any, content: string, hashtags?: any | null, user: { __typename?: 'users', accountName: string, username: string, image?: string | null, id: any, twitterMetadata?: any | null } } | null }> };

export type PurchasedQuestionStatusQueryVariables = Exact<{
  questionId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type PurchasedQuestionStatusQuery = { __typename?: 'query_root', purchased_questions: Array<{ __typename?: 'purchased_questions', id: any }> };

export type QuestionForMeQueryVariables = Exact<{
  id: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
}>;


export type QuestionForMeQuery = { __typename?: 'query_root', questions: Array<{ __typename?: 'questions', id: any, content: string, price: any, createdAt: any, canceledAt?: any | null, userByQuestionerid: { __typename?: 'users', accountName: string, image?: string | null, username: string, twitterMetadata?: any | null }, answers: Array<{ __typename?: 'answers', createdAt: any, id: any }> }> };

export type QuestionAskedQueryVariables = Exact<{
  id: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
}>;


export type QuestionAskedQuery = { __typename?: 'query_root', questions: Array<{ __typename?: 'questions', id: any, createdAt: any, status: string, updatedAt: any, content: string, isPublic: boolean, user: { __typename?: 'users', image?: string | null, id: any, email: string, createdAt: any, bio?: string | null, accountName: string, username: string, twitterMetadata?: any | null } }> };

export type UserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: any, email: string, image?: string | null, accountName: string, bio?: string | null, createdAt: any, provider?: string | null, username: string, priceSetting: any, twitterMetadata?: any | null } | null };

export type UserProfilePublicQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserProfilePublicQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', accountName: string, image?: string | null, bio?: string | null, id: any, deletedAt?: any | null, twitterMetadata?: any | null, username: string, answers_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, ratings_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, questions: Array<{ __typename?: 'questions', id: any, content: string, price: any, hashtags?: any | null, userByQuestionerid: { __typename?: 'users', id: any, accountName: string, image?: string | null, twitterMetadata?: any | null }, user: { __typename?: 'users', id: any, accountName: string }, answers: Array<{ __typename?: 'answers', content: string }> }> } | null };

export type UserViewProfilePublicQueryVariables = Exact<{
  id: Scalars['uuid'];
  currentUserID: Scalars['uuid'];
}>;


export type UserViewProfilePublicQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', accountName: string, image?: string | null, bio?: string | null, id: any, deletedAt?: any | null, twitterMetadata?: any | null, username: string, answers_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, ratings_count: { __typename?: 'answers_aggregate', aggregate?: { __typename?: 'answers_aggregate_fields', count: number } | null }, questions: Array<{ __typename?: 'questions', id: any, content: string, price: any, hashtags?: any | null, userByQuestionerid: { __typename?: 'users', id: any, accountName: string, image?: string | null, twitterMetadata?: any | null }, user: { __typename?: 'users', id: any, accountName: string }, purchased_questions: Array<{ __typename?: 'purchased_questions', id: any }>, answers: Array<{ __typename?: 'answers', content: string }> }> } | null };

export type UserProfileQueryVariables = Exact<{
  id: Scalars['uuid'];
  questioner: Scalars['uuid'];
}>;


export type UserProfileQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', accountName: string, image?: string | null, bio?: string | null, id: any, deletedAt?: any | null, twitterMetadata?: any | null, username: string, questions: Array<{ __typename?: 'questions', id: any, content: string, price: any, hashtags?: any | null, userByQuestionerid: { __typename?: 'users', accountName: string, image?: string | null, twitterMetadata?: any | null } }> } | null };

export type UserIdByUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UserIdByUsernameQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any }> };

export type RatingListQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RatingListQuery = { __typename?: 'query_root', ratings: Array<{ __typename?: 'ratings', message?: string | null, rating: boolean, id: any, createdAt: any, answer: { __typename?: 'answers', question?: { __typename?: 'questions', id: any, userByQuestionerid: { __typename?: 'users', accountName: string, username: string, image?: string | null, twitterMetadata?: any | null } } | null } }> };

export type AnswerRatingQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AnswerRatingQuery = { __typename?: 'query_root', ratings: Array<{ __typename?: 'ratings', message?: string | null, rating: boolean, id: any }> };


export const GetUserNotificationsDocument = gql`
    query getUserNotifications($id: uuid!) {
  notifications(
    where: {toUserID: {_eq: $id}, question: {userByQuestionerid: {}}}
    order_by: {createdAt: desc}
  ) {
    id
    createdAt
    message
    status
    updatedAt
    question {
      id
      price
      answerer {
        accountName
      }
      userByQuestionerid {
        accountName
      }
    }
    user {
      accountName
      image
      email
      bio
      id
      status
      role
      username
      twitterMetadata
    }
  }
}
    `;
export const HasNewNotificationsDocument = gql`
    query hasNewNotifications($id: uuid!) {
  notifications(where: {toUserID: {_eq: $id}, status: {_eq: false}}) {
    id
  }
}
    `;
export const PaymentEnabledStatusDocument = gql`
    query PaymentEnabledStatus($id: uuid!) {
  users_by_pk(id: $id) {
    hasBankInfo
    hasCardInfo
    hasIdentityInfo
    isIdentitySubmitted
    isIdentityVerified
  }
}
    `;
export const AnswerersDocument = gql`
    query Answerers($limit: Int, $offset: Int) {
  users(
    where: {role: {_eq: "answerer"}, answererApplicationStatus: {_is_null: false}}
    order_by: {approvedAt: desc}
    limit: $limit
    offset: $offset
  ) {
    id
    email
    image
    accountName
    bio
    createdAt
    provider
    username
    twitterMetadata
    answers_count: answers_aggregate {
      aggregate {
        count
      }
    }
    ratings_count: answers_aggregate(where: {ratings: {rating: {_eq: true}}}) {
      aggregate {
        count
      }
    }
  }
}
    `;
export const AnswerersExcludingMeDocument = gql`
    query AnswerersExcludingMe($id: uuid!, $limit: Int, $offset: Int) {
  users(
    where: {role: {_eq: "answerer"}, answererApplicationStatus: {_is_null: false}, id: {_neq: $id}}
    order_by: {approvedAt: desc}
    limit: $limit
    offset: $offset
  ) {
    id
    email
    image
    accountName
    bio
    createdAt
    provider
    username
    twitterMetadata
    answers_count: answers_aggregate {
      aggregate {
        count
      }
    }
    ratings_count: answers_aggregate(where: {ratings: {rating: {_eq: true}}}) {
      aggregate {
        count
      }
    }
  }
}
    `;
export const MyStatsDocument = gql`
    query MyStats($id: uuid!) {
  users_by_pk(id: $id) {
    id
    email
    image
    accountName
    bio
    createdAt
    provider
    username
    answers_count: answers_aggregate {
      aggregate {
        count
      }
    }
    ratings_count: answers_aggregate(where: {ratings: {rating: {_eq: true}}}) {
      aggregate {
        count
      }
    }
  }
}
    `;
export const OfficialQuestionsDocument = gql`
    query OfficialQuestions($userId: uuid!) {
  official_questions {
    id
    status
    updatedAt
    isPublic
    hashtags
    createdAt
    content
    price
    answers(where: {user: {id: {_eq: $userId}}}) {
      id
      createdAt
    }
  }
}
    `;
export const OfficialQuestionDetailDocument = gql`
    query OfficialQuestionDetail($id: uuid!, $userId: uuid!) {
  official_questions_by_pk(id: $id) {
    id
    status
    updatedAt
    isPublic
    hashtags
    createdAt
    content
    price
    purchased_questions(where: {userID: {_eq: $userId}, questionID: {_eq: $id}}) {
      id
    }
    answers(where: {user: {id: {_eq: $userId}}}) {
      content
      id
      createdAt
      images
      user {
        id
        accountName
        username
        image
        twitterMetadata
      }
    }
  }
}
    `;
export const AnsweredOfficialQuestionsDocument = gql`
    query AnsweredOfficialQuestions($userId: uuid!) {
  official_questions {
    answers(where: {user: {id: {_eq: $userId}}}) {
      official_question {
        id
        content
        hashtags
        createdAt
        isPublic
        images
      }
    }
  }
}
    `;
export const PriceSettingDocument = gql`
    query PriceSetting($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    priceSetting
  }
}
    `;
export const PublicQuestionsDocument = gql`
    query PublicQuestions {
  questions(
    where: {isPublic: {_eq: true}, status: {_eq: "answered"}}
    order_by: {createdAt: desc}
  ) {
    id
    createdAt
    status
    updatedAt
    content
    isPublic
    user {
      image
      id
      bio
      accountName
      username
    }
    price
    hashtags
  }
}
    `;
export const QuestionDetailsDocument = gql`
    query QuestionDetails($id: uuid!, $userId: uuid!) {
  questions_by_pk(id: $id) {
    id
    content
    createdAt
    deletedAt
    hashtags
    images
    isPublic
    price
    status
    updatedAt
    user {
      accountName
      id
      image
      username
      deletedAt
      twitterMetadata
    }
    userByQuestionerid {
      id
      accountName
      image
      twitterMetadata
    }
    answers(where: {questionID: {_eq: $id}}) {
      content
      createdAt
      id
      images
      ratings {
        rating
        message
      }
    }
    purchased_questions(where: {userID: {_eq: $userId}, questionID: {_eq: $id}}) {
      id
    }
  }
}
    `;
export const PublicQuestionDetailDocument = gql`
    query PublicQuestionDetail($id: uuid!) {
  questions_by_pk(id: $id) {
    id
    content
    createdAt
    deletedAt
    hashtags
    images
    isPublic
    price
    status
    updatedAt
    user {
      accountName
      id
      image
      username
      deletedAt
      twitterMetadata
    }
    userByQuestionerid {
      id
      accountName
      image
      twitterMetadata
    }
    answers(where: {questionID: {_eq: $id}}) {
      content
      createdAt
      id
      images
      ratings {
        rating
        message
      }
    }
  }
}
    `;
export const PurchasedQuestionDocument = gql`
    query PurchasedQuestion($userId: uuid!) {
  purchased_questions(
    where: {userID: {_eq: $userId}, officialQuestionID: {_is_null: true}}
    order_by: {createdAt: desc}
  ) {
    id
    createdAt
    price
    question {
      id
      content
      hashtags
      user {
        accountName
        username
        image
        id
        twitterMetadata
      }
    }
  }
}
    `;
export const PurchasedQuestionStatusDocument = gql`
    query PurchasedQuestionStatus($questionId: uuid!, $userId: uuid!) {
  purchased_questions(
    where: {questionID: {_eq: $questionId}, _and: {userID: {_eq: $userId}}}
  ) {
    id
  }
}
    `;
export const QuestionForMeDocument = gql`
    query QuestionForMe($id: uuid!, $status: String) {
  questions(
    where: {answererID: {_eq: $id}, status: {_eq: $status}}
    order_by: {createdAt: desc}
  ) {
    id
    content
    price
    createdAt
    canceledAt
    userByQuestionerid {
      accountName
      image
      username
      twitterMetadata
    }
    answers {
      createdAt
      id
    }
  }
}
    `;
export const QuestionAskedDocument = gql`
    query QuestionAsked($id: uuid!, $status: String) {
  questions(
    where: {questionerID: {_eq: $id}, status: {_eq: $status}}
    order_by: {createdAt: desc}
  ) {
    id
    createdAt
    status
    updatedAt
    content
    isPublic
    user {
      image
      id
      email
      createdAt
      bio
      accountName
      username
      twitterMetadata
    }
  }
}
    `;
export const UserDocument = gql`
    query User($id: uuid!) {
  users_by_pk(id: $id) {
    id
    email
    image
    accountName
    bio
    createdAt
    provider
    username
    priceSetting
    twitterMetadata
  }
}
    `;
export const UserProfilePublicDocument = gql`
    query UserProfilePublic($id: uuid!) {
  users_by_pk(id: $id) {
    accountName
    image
    bio
    id
    deletedAt
    twitterMetadata
    username
    answers_count: answers_aggregate {
      aggregate {
        count
      }
    }
    ratings_count: answers_aggregate(where: {ratings: {rating: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    questions(
      where: {answererID: {_eq: $id}, _and: {isPublic: {_eq: true}, status: {_eq: "answered"}, answers: {isPublic: {_eq: true}}}}
      order_by: {createdAt: desc}
    ) {
      id
      content
      price
      hashtags
      userByQuestionerid {
        id
        accountName
        image
        twitterMetadata
      }
      user {
        id
        accountName
      }
      answers(where: {question: {price: {_eq: 0}}}) {
        content
      }
    }
  }
}
    `;
export const UserViewProfilePublicDocument = gql`
    query UserViewProfilePublic($id: uuid!, $currentUserID: uuid!) {
  users_by_pk(id: $id) {
    accountName
    image
    bio
    id
    deletedAt
    twitterMetadata
    username
    answers_count: answers_aggregate {
      aggregate {
        count
      }
    }
    ratings_count: answers_aggregate(where: {ratings: {rating: {_eq: true}}}) {
      aggregate {
        count
      }
    }
    questions(
      where: {answererID: {_eq: $id}, _and: {isPublic: {_eq: true}, status: {_eq: "answered"}, answers: {isPublic: {_eq: true}}}}
      order_by: {createdAt: desc}
    ) {
      id
      content
      price
      hashtags
      userByQuestionerid {
        id
        accountName
        image
        id
        twitterMetadata
      }
      user {
        id
        accountName
      }
      purchased_questions(where: {userID: {_eq: $currentUserID}}) {
        id
      }
      answers(where: {question: {price: {_eq: 0}}}) {
        content
      }
    }
  }
}
    `;
export const UserProfileDocument = gql`
    query UserProfile($id: uuid!, $questioner: uuid!) {
  users_by_pk(id: $id) {
    accountName
    image
    bio
    id
    deletedAt
    twitterMetadata
    username
    questions(
      where: {answererID: {_eq: $id}, _and: {isPublic: {_eq: true}}, _or: {questionerID: {_eq: $questioner}}}
      order_by: {createdAt: desc}
    ) {
      id
      content
      price
      hashtags
      userByQuestionerid {
        accountName
        image
        twitterMetadata
      }
    }
  }
}
    `;
export const UserIdByUsernameDocument = gql`
    query UserIdByUsername($username: String!) {
  users(where: {username: {_ilike: $username}}) {
    id
  }
}
    `;
export const RatingListDocument = gql`
    query RatingList($id: uuid!) {
  ratings(where: {answer: {answererID: {_eq: $id}}}, order_by: {createdAt: desc}) {
    message
    rating
    id
    createdAt
    answer {
      question {
        id
        userByQuestionerid {
          accountName
          username
          image
          twitterMetadata
        }
      }
    }
  }
}
    `;
export const AnswerRatingDocument = gql`
    query AnswerRating($id: uuid!) {
  ratings(where: {answerID: {_eq: $id}}) {
    message
    rating
    id
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getUserNotifications(variables: GetUserNotificationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetUserNotificationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserNotificationsQuery>(GetUserNotificationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserNotifications', 'query');
    },
    hasNewNotifications(variables: HasNewNotificationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HasNewNotificationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HasNewNotificationsQuery>(HasNewNotificationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'hasNewNotifications', 'query');
    },
    PaymentEnabledStatus(variables: PaymentEnabledStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PaymentEnabledStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaymentEnabledStatusQuery>(PaymentEnabledStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaymentEnabledStatus', 'query');
    },
    Answerers(variables?: AnswerersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnswerersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnswerersQuery>(AnswerersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Answerers', 'query');
    },
    AnswerersExcludingMe(variables: AnswerersExcludingMeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnswerersExcludingMeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnswerersExcludingMeQuery>(AnswerersExcludingMeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnswerersExcludingMe', 'query');
    },
    MyStats(variables: MyStatsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MyStatsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MyStatsQuery>(MyStatsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MyStats', 'query');
    },
    OfficialQuestions(variables: OfficialQuestionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OfficialQuestionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OfficialQuestionsQuery>(OfficialQuestionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OfficialQuestions', 'query');
    },
    OfficialQuestionDetail(variables: OfficialQuestionDetailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OfficialQuestionDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OfficialQuestionDetailQuery>(OfficialQuestionDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OfficialQuestionDetail', 'query');
    },
    AnsweredOfficialQuestions(variables: AnsweredOfficialQuestionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnsweredOfficialQuestionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnsweredOfficialQuestionsQuery>(AnsweredOfficialQuestionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnsweredOfficialQuestions', 'query');
    },
    PriceSetting(variables: PriceSettingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PriceSettingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PriceSettingQuery>(PriceSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PriceSetting', 'query');
    },
    PublicQuestions(variables?: PublicQuestionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PublicQuestionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicQuestionsQuery>(PublicQuestionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublicQuestions', 'query');
    },
    QuestionDetails(variables: QuestionDetailsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<QuestionDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<QuestionDetailsQuery>(QuestionDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionDetails', 'query');
    },
    PublicQuestionDetail(variables: PublicQuestionDetailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PublicQuestionDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PublicQuestionDetailQuery>(PublicQuestionDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublicQuestionDetail', 'query');
    },
    PurchasedQuestion(variables: PurchasedQuestionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PurchasedQuestionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PurchasedQuestionQuery>(PurchasedQuestionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PurchasedQuestion', 'query');
    },
    PurchasedQuestionStatus(variables: PurchasedQuestionStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PurchasedQuestionStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PurchasedQuestionStatusQuery>(PurchasedQuestionStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PurchasedQuestionStatus', 'query');
    },
    QuestionForMe(variables: QuestionForMeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<QuestionForMeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<QuestionForMeQuery>(QuestionForMeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionForMe', 'query');
    },
    QuestionAsked(variables: QuestionAskedQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<QuestionAskedQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<QuestionAskedQuery>(QuestionAskedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionAsked', 'query');
    },
    User(variables: UserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserQuery>(UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'User', 'query');
    },
    UserProfilePublic(variables: UserProfilePublicQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserProfilePublicQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserProfilePublicQuery>(UserProfilePublicDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserProfilePublic', 'query');
    },
    UserViewProfilePublic(variables: UserViewProfilePublicQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserViewProfilePublicQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserViewProfilePublicQuery>(UserViewProfilePublicDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserViewProfilePublic', 'query');
    },
    UserProfile(variables: UserProfileQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserProfileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserProfileQuery>(UserProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserProfile', 'query');
    },
    UserIdByUsername(variables: UserIdByUsernameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserIdByUsernameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserIdByUsernameQuery>(UserIdByUsernameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserIdByUsername', 'query');
    },
    RatingList(variables: RatingListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RatingListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RatingListQuery>(RatingListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RatingList', 'query');
    },
    AnswerRating(variables: AnswerRatingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AnswerRatingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnswerRatingQuery>(AnswerRatingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnswerRating', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;