import { Box, Flex, Link } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { theme } from '../../../constants';
import { Text, Avatar } from '../../atoms';
import { format } from 'date-fns';
import DefaultImage from '@packages/ui/assets/default-user.png';
export interface RatingItemProps {
  query: any;
  bordered?: boolean;
}

export const RatingListItem = ({ query, bordered }: RatingItemProps) => {
  const { t } = useTranslation();
  const { createdAt, image, message, questionerName, questionerUsername, answer } = query;

  return (
    <Box
      borderBottom={bordered ? '1px solid' : ''}
      borderBottomColor="background.gray"
      paddingX="28px"
      paddingY="18.5px"
    >
      <Flex direction="row" gap="17px" alignItems="center">
        <Avatar height="52px" width="52px" src={image || DefaultImage?.src} />
        <Box>
          <Text size={15} text={questionerName} />
          {questionerUsername && <Text size={10} text={`@${questionerUsername}`} />}
        </Box>
      </Flex>

      <Text mt="4.5px" weight={500} text={message} />

      <Flex
        marginTop="8px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="full"
      >
        <Text
          text={format(new Date(createdAt), 'yyyy/MM/dd hh:mm')}
          color={theme.colors.background.subtext}
          size={10}
        />
        <Link
          href={`/report/${answer?.question?.id}`}
          fontSize={10}
          color={theme.colors.background.subtext}
          textDecoration="underline"
          whiteSpace="nowrap"
        >
          {t('report')}
        </Link>
      </Flex>
    </Box>
  );
};
