import { Avatar, Box, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import CameraIcon from '@packages/ui/assets/icons/camera.svg';
import AddIcon from '@packages/ui/assets/icons/add.svg';
import { theme } from '../../../constants';
import { Alert, IAlert } from '../Alert';
import { useTranslation } from 'next-i18next';
import imageCompression from 'browser-image-compression';
import DefaultImage from '@packages/ui/assets/default-user.png';
import Image from 'next/image';
export interface IImageUploadCommonProps {
  height?: string;
  width?: string;
  backgroundColor?: string;
  name?: string;
  isReferenceImage?: boolean;
  isError?: boolean;
  handleImageChange?: (image: ImageListType) => void;
  image?: ImageListType;
  setErrorMessage?: (error: string) => void;
  cancellable?: boolean;
}

export type ImageUploadProps = IImageUploadCommonProps;

const options = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const ImageUpload = ({
  height = '80px',
  width = '80px',
  name = '',
  isError,
  isReferenceImage,
  image,
  handleImageChange,
  setErrorMessage,
  cancellable = true,
}: ImageUploadProps) => {
  const [images, setImages] = useState<ImageType[]>(image ? image : []);
  const [alert, setAlert] = useState<IAlert | null>(null);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const onChange = async (item: ImageListType) => {
    if (item?.[0]?.file) {
      try {
        const compressedFile = await imageCompression(item?.[0].file, options);
        item[0].file = compressedFile;
        item[0].data_url = await imageCompression.getDataUrlFromFile(compressedFile);
      } catch (error) {
        console.log(error);
      }
    }
    setImages(item);
    handleImageChange?.(item);
  };

  useEffect(() => {
    if (image && image[0]?.data_url !== null) setImages?.(image);
  }, [image]);

  useEffect(() => {
    if (error?.length) {
      setAlert({ status: 'error', message: error });
      if (setErrorMessage) {
        setErrorMessage(error);
      }
    } else {
      setAlert(null);
      if (setErrorMessage) {
        setErrorMessage(error);
      }
    }
  }, [error]);

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      acceptType={['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG']}
      dataURLKey="data_url"
    >
      {({ onImageUpload, onImageUpdate, dragProps, errors, onImageRemove }) => (
        <>
          <>
            {errors?.acceptType
              ? setError(t('Image must be of type jpeg, jpg or png'))
              : errors?.maxFileSize
                ? setError(t('Image size must be less than 2MB'))
                : errors?.maxNumber
                  ? setError(t(`maxImageLimit`, { imageLimit: 1 }))
                  : setError('')}
          </>

          {alert && !setErrorMessage && (
            <Box marginBottom="14px">
              <Alert status={alert.status} message={alert.message} />
            </Box>
          )}

          {images.length > 0 && (images?.[0]?.file || images?.[0]?.data_url) ? (
            <div className="relative max-w-max">
              {cancellable && (
                <div
                  className="bg-brand-300 absolute -top-2 -right-2 z-50 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-white"
                  onClick={() => onImageRemove(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-3 w-3"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
              )}
              {!isReferenceImage ? (
                <Avatar
                  className="cursor-pointer"
                  borderRadius={'50%'}
                  name={name}
                  src={images[0]['data_url'] || DefaultImage?.src}
                  height={height}
                  width={width}
                  style={{ background: 'white', borderRadius: '7px' }}
                  onClick={() => onImageUpdate(0)}
                />)
                :
                (
                  <Avatar
                    height={'62.5px'}
                    width={'62.5px'}
                    onClick={() => onImageUpdate(0)}
                  >
                    <Image
                      src={images[0]['data_url'] || DefaultImage?.src}
                      alt={name}
                      layout="fill"
                      objectFit="cover"
                      loading="lazy"
                      style={{ borderRadius: '7px' , background:"white"}}
                      unoptimized
                    />
                  </Avatar>
                )
              }
            </div>
          ) : (
            <Button
              borderWidth="1px"
              borderStyle={isReferenceImage && !isError ? 'none' : 'solid'}
              borderRadius={isReferenceImage ? '7px' : '50%'}
              position="relative"
              height={isReferenceImage ? '62.5px' : height}
              width={isReferenceImage ? '62.5px' : width}
              onClick={onImageUpload}
              borderColor={isError ? theme.colors.background.border : 'transparent'}
              _before={
                isReferenceImage
                  ? {}
                  : {
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: '-1',
                    margin: '-2px',
                    borderRadius: 'inherit',
                    background: theme.gradient.primary,
                  }
              }
              _focus={{
                outline: 'none',
              }}
              bg="white"
              {...dragProps}
            >
              {isReferenceImage ? <AddIcon /> : <CameraIcon />}
            </Button>
          )}
        </>
      )}
    </ImageUploading>
  );
};
