import React from 'react';
import { Text } from '@packages/ui/components';
import Social from '@packages/ui/assets/icons/social.svg';
import { useTranslation } from 'next-i18next';
import { Flex } from '@chakra-ui/react';

interface INoDataProps {
  color?: string;
  className?: string;
  purchased?: boolean;
}

export const NoData = ({ color, className, purchased }: INoDataProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      className={className}
      marginTop="28px"
      gap="35px"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Social />
      <Text
        center
        color={color}
        text={purchased ? t('No questions purchased!') : t('No data available!')}
      />
    </Flex>
  );
};
