import { API } from '@/lib/API';

interface UserServiceProps {
  email: string;
  password: string;
  accountName: string;
  image?: File;
}

const registerUser = async ({ email, password, accountName, image }: UserServiceProps) => {
  try {
    const formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);
    formData.append('accountName', accountName);
    image && formData.append('image', image);

    const { data: response } = await API.post('/api/register-user', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw error
  }
};

interface InviteEmailProps {
  email: string;
}

interface RegisterUserTwitterParams {
  email: string;
  username: string;
  provider: string;
  accountName: string;
  twitterMetadata?: any;
}

const resetPassword = async (data: InviteEmailProps) => {
  const { data: response } = await API.post('/api/reset-password', data);
  return response;
};

const updatePassword = async (data: { newPassword: string }) => {
  let access_token = localStorage?.getItem('supabase.auth.token');
  if (access_token) {
    access_token = JSON.parse(access_token).currentSession.access_token as string;
  }

  const { data: response } = await API.post('/api/update-password', data, {
    headers: {
      access_token: access_token as string,
    },
  });
  return response;
};

const updatePriceSetting = async (price: number | string) => {
  const { data } = await API.post('/api/price-settings', price);
  return data;
}

const registerUserTwitter = async (userDetails: RegisterUserTwitterParams) => {
  const { data } = await API.post('/api/register-user-twitter', userDetails)
  return data;
}

export { registerUser, resetPassword, updatePassword, registerUserTwitter, updatePriceSetting };
