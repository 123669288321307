import { createClient } from "@supabase/supabase-js"

import { error } from "@packages/utils"

const supabaseUrl =
  process.env.NEXT_PUBLIC_SUPABASE_URL ?? error("NEXT_PUBLIC_SUPABASE_URL required")
const supabaseAnonKey =
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? error("NEXT_PUBLIC_SUPABASE_ANON_KEY required")
const supabaseServiceRoleKey =
  process.env.NEXT_PUBLIC_SUPABASE_SERVICE_ROLE_KEY ?? error("NEXT_PUBLIC_SUPABASE_SERVICE_ROLE_KEY required")

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {})

export const supabaseServer = createClient(supabaseUrl, supabaseServiceRoleKey)
