import React, { useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { useRouter } from 'next/router';
import { Flex, Spinner } from '@chakra-ui/react';
import { useSession } from '@/features/auth/api/getSession';
import { getCookie } from 'cookies-next';

function PrivateRoute({ children }: any) {
  const router = useRouter();

  const { isAuthenticated, isLoading, isAnswerer } = useContext(AuthContext);

  const authCookie = getCookie('auth-session');

  const { data: session } = useSession();

  const protectedRoutes = [
    '/register-account-details',
    '/change-email',
    '/reset-password',
    '/reset-password-done',
  ];

  const publicRoutes = [
    '/login-email',
    '/register-email',
    '/login',
    '/register',
    '/forgot-password',
    '/forgot-password-done',
    // '/asq_top_page_renamed_not_to_be_accessed',
    '/request-change-password-complete',
    '/',
  ];

  const publicPages = [
    '/asq_top_page_renamed_not_to_be_accessed',
    '/contact',
    '/influencers',
    '/influencers/[id]',
    '/questions-list',
    '/question-detail/[id]',
    // '/asq_top_page_renamed_not_to_be_accessed',
    '/official-question-detail/[id]',
  ];

  useEffect(() => {
    if (
      isAuthenticated == null &&
      !authCookie &&
      !publicRoutes.includes(router.pathname) &&
      !protectedRoutes.includes(router.pathname) &&
      !publicPages.includes(router.pathname)
    ) {
      router.replace('/login');
    }

    if (authCookie && typeof authCookie === 'string') {
      const parsedCookie = JSON.parse(authCookie);
      const status = parsedCookie?.user
        ? parsedCookie?.user?.user_metadata?.status
        : parsedCookie?.user_metadata?.status;
      const provider = parsedCookie?.user
        ? parsedCookie?.user?.app_metadata?.provider
        : parsedCookie?.app_metadata?.provider;
      if ((!status || status !== 'active') && !['twitter', 'email'].includes(provider)) {
        router.replace('/register-account-details');
      }
    }

    if (
      authCookie &&
      publicRoutes.includes(router.pathname) &&
      !protectedRoutes.includes(router.pathname) &&
      !publicPages.includes(router.pathname)
    ) {
      router.replace('/');
    }
  }, [session, isAnswerer, isAuthenticated, authCookie, router.pathname]);

  if (isLoading) {
    return (
      <Flex className="loader" height="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  }
  return <>{children}</>;
}

export default PrivateRoute;
