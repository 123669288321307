import { Avatar as ChakraAvatar, Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { theme } from '../../../constants';
import defaultImage from '@packages/ui/assets/icons/default.png';
import { PreviewImage, Text, Avatar } from '../../atoms';
import DefaultUserImage from '@packages/ui/assets/default-user.png';
import Image from 'next/image';
interface Answer {
  id: string;
  ans?: string;
  username?: string;
  images?: string[];
  profileImage?: string;
}

export interface AnswerCardProps {
  answer: Answer;
}

export const AnswerCard = ({ answer }: AnswerCardProps) => {
  const { profileImage, images, ans } = answer;
  const [previewImage, setPreviewImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openPreviewImage = (index: number) => {
    setPreviewImage(true);
    setImageIndex(index);
  };

  return (
    <Box
      bgGradient={theme.gradient.primary}
      color={theme.colors.background.white}
      borderRadius="12px"
      padding="25px"
      marginTop="25px"
    >
      <Flex direction="column">
        <Flex width="full" justifyContent="center" marginBottom="18px">
          <Avatar height="55px" width="55px"
            src={profileImage || DefaultUserImage?.src || defaultImage?.src}
            alt={"user image"}
          />
        </Flex>
        <Flex direction="column" className="mt-2">
          <Text weight={500} text={ans} color="text-white" className="whitespace-pre-line" />
        </Flex>
      </Flex>
      <Flex gap="9.45px" width="fit-content" margin="0 auto" marginTop="24px">
        {images?.map((image: string, idx: number) => (
          <ChakraAvatar
            onClick={() => openPreviewImage(idx)}
            key={image}
            height="62.5px"
            width="62.5px"
          >
            <Image
              src={image || DefaultUserImage?.src || defaultImage?.src}
              alt={'answer-images'}
              layout="fill"
              objectFit="cover"
              loading="lazy"
              style={{ borderRadius: '7px', background:"white" }}
              unoptimized
            />
          </ChakraAvatar>
        ))}
      </Flex>
      {
        images && images?.length > 0 && (
          <PreviewImage
            open={previewImage}
            onClose={() => setPreviewImage(false)}
            images={images}
            currIndex={imageIndex}
          />
        )
      }
    </Box >
  );
};
