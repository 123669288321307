module.exports = {
  content: ['./**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      fontFamily: {
        sans: ["'Noto Sans JP'", "'sans-serif'"],
      },
    },
    colors: {
      brand: {
        100: '#f7fafc',
        900: '#1a202c',
        300: '#FF6A88',
      },
      dark: '#333333',
      gray: '#CBCBCB',
      darkGray: '#989898',
      textGray: '#C4C4C4',
      red: '#DC4E41',
      blue: '#00ACEE',
      placeholder: '#E5E5E5',
      white: '#fff',
      subtext: '#989898',
      border: '#FF6A88',
      info: '#FF97AA',
      footer: '#FFBCC8',
      success: '#4CF453',
      successBorder: '#35DD3C',
      imageBackground: '#D9D9D9',
      checked: '#FFEEF3',
      highlight: '#F3F4F6',
    },
  },
};
