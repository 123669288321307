import { Avatar as ChakraAvatar } from "@chakra-ui/react";
import Image from "next/image";
import { forwardRef } from 'react';

interface AvatarProps {
    height: string;
    width: string;
    src: string;
    alt?: string;
    borderRadius?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const Avatar = forwardRef<HTMLImageElement, AvatarProps>(({
    height,
    width,
    src,
    alt,
    borderRadius,
    onClick,
}: AvatarProps, ref) => {
    return (
        <ChakraAvatar height={height} width={width} onClick={onClick} borderRadius={borderRadius} ref={ref}>
            <Image
                src={src}
                layout="fill"
                objectFit="cover"
                loading="lazy"
                alt={alt}
                className="cursor-pointer rounded-full"
                style={{ background: 'white' }}
                unoptimized
            />
        </ChakraAvatar>
    )
});