import { Alert as ChakraAlert, AlertTitle, Flex } from '@chakra-ui/react';
import { theme } from '../../../constants';
import AlertIcon from '@packages/ui/assets/icons/alert.svg';
import { forwardRef } from 'react';

export interface IAlert {
  status?: 'error' | 'success';
  message?: string;
  className?: string;
}

export const Alert = forwardRef<HTMLDivElement, IAlert>(({ message, status, className }, ref) => {
  return (
    <ChakraAlert
      width="full"
      ref={ref}
      marginTop="35px"
      padding={'7px 10px'}
      minHeight="29px"
      status={status}
      borderRadius="3px"
      background={
        status === 'error' ? theme.colors.background.info : theme.colors.background.success
      }
      border="1px"
      borderColor={
        status === 'error' ? theme.colors.background.border : theme.colors.background.successBorder
      }
      className={className ?? ''}
    >
      <Flex gap="10px">
        <div className="whitespace-nowrap">
          <AlertIcon />
        </div>
        <div>
          <AlertTitle
            fontSize="10px"
            fontWeight={500}
            lineHeight="15px"
            letterSpacing="0.23px"
            color={theme.colors.background.white}
          >
            {message}
          </AlertTitle>
        </div>
      </Flex>
    </ChakraAlert>
  );
});
