import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { theme } from '../../../constants';
import { useTranslation } from 'next-i18next';
import OfficialUser from '@/public/assets/icons/official-user.svg';
import DefaultImage from '@packages/ui/assets/default-user.png';
import { Avatar } from '../../atoms';
interface QueriesFromUserProps {
  id: string;
  profileImage: string;
  name: string;
  amount: number;
  questionDate: string;
  cancelInDays: number;
  username?: string;
  onClick: () => void;
  activeTab: string;
  answeredDate?: string | null;
  canceledDate?: string | null;
  isOfficial?: boolean;
  question?: string;
}

export const QueriesFromUser = ({
  question,
  profileImage,
  name,
  amount,
  questionDate,
  cancelInDays,
  username = '',
  answeredDate,
  canceledDate,
  activeTab,
  onClick,
  isOfficial,
}: QueriesFromUserProps) => {
  const { t } = useTranslation();
  const [isLessThan350] = useMediaQuery('(max-width: 350px)');

  return (
    <Box
      margin="15px -28px 0 -28px"
      borderBottom="1px solid"
      borderBottomColor="background.gray"
      onClick={onClick}
      cursor="pointer"
      paddingBottom="15px"
    >
      <Box margin="0 28px 0 28px">
        <Flex direction="row" gap={isLessThan350 ? '9px' : '18px'} alignItems="center">
          {isOfficial ? (
            <Box height="55px" width="55px">
              <OfficialUser />
            </Box>
          ) : (
            <Avatar
              alt={name || username}
              src={profileImage || DefaultImage?.src}
              height="55px"
              width="55px"
            />

          )}

          <Flex justifyContent="space-between" className="w-full" alignItems="center">
            <Flex
              color="background.dark"
              fontSize="13px"
              fontWeight={700}
              lineHeight="24px"
              overflowWrap="anywhere"
            >
              <div>
                {name}
                {username && (
                  <Text
                    color="background.dark"
                    fontSize="10px"
                    fontWeight={700}
                    lineHeight="24px"
                    overflowWrap="anywhere"
                    marginTop={isLessThan350 ? '2px' : '-4px'}
                  >
                    @{username}
                  </Text>
                )}
                {isOfficial && (
                  <Text noOfLines={3} fontSize={13} fontWeight={500} whiteSpace="pre-line">
                    {question && question?.length > 20 ? question?.slice(0, 20) + '...' : question}
                  </Text>
                )}
              </div>
            </Flex>
            {!isOfficial && (
              <Flex direction="column">
                <Box
                  bgGradient={theme.gradient.primary}
                  color="white"
                  height="24px"
                  borderRadius="12px"
                  width="fit-content"
                  alignSelf="flex-end"
                >
                  <Text fontSize="10px" lineHeight="24px" fontWeight={700} paddingX="24px">
                    ¥{new Intl.NumberFormat('ja-JP').format(amount)}
                  </Text>
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
      <Flex
        marginRight="28px"
        fontSize="8px"
        fontWeight={700}
        lineHeight="15px"
        textAlign={isLessThan350 ? 'center' : 'right'}
        gap="10px"
        justifyContent="flex-end"
        marginTop={isLessThan350 ? '2px' : '-4px'}
        letterSpacing="0.23px"
      >
        {activeTab === 'created' && (
          <>
            <Text>{t('unanswered.queries.asked.at', { date: questionDate })}</Text>
            {!isOfficial && <Text>{t('unanswered.queries.revenue', { status: 'ON' })}</Text>}
            {!isOfficial && (
              <Text>{t('unanswered.queries.canceled.in', { days: cancelInDays })}</Text>
            )}
          </>
        )}
        {activeTab === 'answered' && (
          <>
            <Text>{t('unanswered.queries.answered.at', { date: answeredDate })}</Text>
            {!isOfficial && <Text>{t('unanswered.queries.revenue', { status: 'ON' })}</Text>}
          </>
        )}
        {activeTab === 'canceled' && (
          <>
            <Text>{t('unanswered.queries.canceled.at', { date: canceledDate })}</Text>
          </>
        )}
      </Flex>
    </Box>
  );
};

{
  /* <Box>
<Text
  fontSize="8px"
  fontWeight={700}
  lineHeight="15px"
  marginTop="3px"
  marginBottom="-15px"
>
  {t('unanswered.queries.subtitle', { date: questionDate, status: 'ON', days: cancelInDays })}
</Text>
</Box> */
}
