import axios from 'axios';
import { supabase } from './supabaseClient';

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(
  async (config: any) => {
    if (typeof window !== 'undefined') {
      const token = supabase.auth.session()?.access_token;
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['x-access-token'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      await supabase.auth.signOut();
      window.location.pathname = '/login';
    }
    return Promise.reject(error.response);
  },
);

export { API };
