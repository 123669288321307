import { As, Heading, useMediaQuery } from '@chakra-ui/react';
import { theme } from '../../../constants';

export interface ITextHeader {
  text: string;
  isWhite?: boolean;
  className?: string;
  as?: As;
  center?: boolean;
}

export const TextHeader = ({ text, isWhite, className, as, center }: ITextHeader) => {
  const [isSmallerThan350] = useMediaQuery('(max-width: 350px)');

  return (
    <Heading
      as={as || 'h1'}
      className={className}
      color={isWhite ? theme.colors.background.white : theme.colors.background.dark}
      fontWeight={700}
      fontSize={isSmallerThan350 ? '24px' : '34px'}
      lineHeight={'54px'}
      letterSpacing={'0.23px'}
      textAlign={center ? 'center' : 'left'}
    >
      {text}
    </Heading>
  );
};
