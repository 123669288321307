import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  ModalHeader,
} from '@chakra-ui/react';
import { Button } from '../../atoms';
import { theme } from '../../../constants';
import { useTranslation } from 'next-i18next';

export interface ConfirmationModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = ({
  title,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mt={'50%'}>
        <ModalHeader>
          <Text fontSize={'16px'} color={theme.colors.background.white}>
            {t(title)}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            width="full"
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="mt-[10px]"
          >
            <Button rounded width="98%" variant="dark" onClick={onConfirm}>
              {t('Yes')}
            </Button>
            <Button
              rounded
              width="98%"
              variant="white"
              className="mt-[16px] mb-[20px]"
              onClick={onClose}
            >
              <Text color={'black'}>{t('No')}</Text>
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
