import { Flex, Text, Textarea as ChakraTextArea } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { theme } from '../../../constants';
import { useTranslation } from 'next-i18next';

export interface TextAreaProps {
  placeholder?: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  register: () => UseFormRegisterReturn;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  isRequired?: boolean;
  height?: string;
  isGray?: boolean;
  label?: string;
  maxLength?: number;
  length?: number;
  className?: string;
  rounded?: boolean;
  errorMessage?: string;
  value?: string;
  disabled?: boolean;
  id?: string;
}

export const TextArea = ({
  size,
  register,
  placeholder,
  variant,
  isRequired,
  height,
  isGray,
  label,
  maxLength,
  length,
  className,
  rounded,
  errorMessage,
  value,
  disabled,
  id,
}: TextAreaProps) => {
  const { t } = useTranslation();
  return (
    <Flex direction="column" className={className}>
      <Flex
        color={!isGray ? theme.colors.background.white : theme.colors.background.dark}
        width={'full'}
        justifyContent={'space-between'}
        marginBottom={'10px'}
        position={'relative'}
      >
        <Flex alignContent="center" gap="7px">
          {label && (
            <Text fontSize={13} fontWeight={700}>
              {label}
            </Text>
          )}
          {isRequired && (
            <Text
              paddingTop="1px"
              fontSize={14}
              fontWeight={700}
              bg={theme.gradient.primary}
              bgClip="text"
            >
              ＊
            </Text>
          )}
        </Flex>
        {maxLength && (
          <Text
            css={{ position: 'absolute', right: 0, top: 0 }}
            fontSize={isGray ? 10 : 13}
            fontWeight={700}
          >
            {length || 0}/{maxLength}
          </Text>
        )}
      </Flex>
      <ChakraTextArea
        id={id}
        disabled={disabled}
        defaultValue={value}
        color="background.dark"
        padding={1}
        _focus={{}}
        _hover={{}}
        fontSize={13}
        fontWeight={700}
        maxLength={maxLength}
        {...(register ? register() : {})}
        size={size}
        placeholder={placeholder}
        height={height || 'auto'}
        resize={'none'}
        borderRadius={rounded ? '7px' : ''}
        borderColor={variant != 'flushed' ? 'transparent' : ''}
        variant={variant}
        background={
          isGray && variant != 'flushed'
            ? theme?.colors?.background.placeholder
            : theme.colors.background.white
        }
      />
      {errorMessage && (
        <Text
          fontWeight={500}
          lineHeight="15px"
          fontSize="10px"
          color="background.border"
          marginTop="2px"
        >
          {t(errorMessage)}
        </Text>
      )}
    </Flex>
  );
};
