import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import ReactSelect from 'react-select';
import { theme } from '../../../constants';

interface SelectProps {
  label?: string;
  isRequired?: boolean;
  errorMessage?: string;
  options: {
    value: string;
    label: string;
  }[];
  register?: () => UseFormRegisterReturn;
  value?: any;
  placeholder?: string;
  isLoading?: boolean;
}

export const SearchableSelect = ({
  label,
  isRequired,
  errorMessage,
  options,
  register,
  placeholder = '',
  value = '',
  isLoading,
}: SelectProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text
        display="flex"
        alignItems="center"
        as="label"
        fontSize={13}
        fontWeight={700}
        gap={'8px'}
      >
        {label}
        {isRequired && (
          <Text
            paddingTop="1px"
            fontSize={12}
            fontWeight={700}
            bg={theme.gradient.primary}
            bgClip="text"
          >
            ＊
          </Text>
        )}
      </Text>
      <ReactSelect
        className="react-select-container !border-0"
        options={options}
        classNamePrefix="react-select"
        placeholder={placeholder}
        value={value}
        {...(register ? register() : {})}
        onChange={(e: any) =>
          register?.()?.onChange({
            target: { ...register(), value: e },
          })
        }
        name={register?.()?.name}
        isLoading={isLoading}
      />

      {errorMessage && (
        <Text fontWeight={500} lineHeight="15px" fontSize="10px" color="background.border">
          {t(errorMessage)}
        </Text>
      )}
    </>
  );
};
