import { QueryClient, UseQueryOptions, UseMutationOptions } from 'react-query';

export const queryClient = new QueryClient();

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<
  MutationFnType extends (...args: any) => any,
  ErrorType = Error,
> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  ErrorType,
  Parameters<MutationFnType>[0]
>;
