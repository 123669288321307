import { Box, Divider, Flex, useMediaQuery, Text as ChakraText, Center } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { theme } from '../../../constants';
import { Avatar, Button, Text } from '../../atoms';
import { IUserProfileStatProps } from '../../organisms';
import DefaultImage from '@packages/ui/assets/default-user.png';
import Link from 'next/link';
interface Query {
  id: string;
  accountName: string;
  username?: string;
  profileImage: string;
  good: number;
  answers: number;
  bio?: string;
}

interface UserCardProps {
  query: Query;
  hasShadow?: boolean;
  mb?: string;
}

const UserProfileStats = ({ count, label }: IUserProfileStatProps) => (
  <div>
    <Text center size="15.42px" text={count.toString()} />
    <Text size="7.01" center text={label} weight={500} />
  </div>
);

export const UserCard = ({ query, hasShadow, mb }: UserCardProps) => {
  const { t } = useTranslation();
  const [isSmallerThan360] = useMediaQuery('(max-width: 360px)');

  const [show, setShow] = React.useState<boolean>(false);
  const [showSeeMoreButton, setShowSeeMoreButton] = useState(false);

  const { id, accountName, profileImage, username, good, bio, answers } = query;

  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      if (node.clientHeight > 96) {
        setShowSeeMoreButton(true);
      } else {
        setShowSeeMoreButton(false);
      }
    }
  }, []);

  return (
    <Box
      boxShadow={hasShadow ? `0px 3px 15px rgba(0, 0, 0, 0.25)` : ''}
      bgColor={theme.colors.white}
      borderRadius="12px"
      padding="24px 25.76px 20px 22px"
      marginBottom={mb || '39px'}
    >
      <Flex
        direction={isSmallerThan360 ? 'column' : 'row'}
        gap="8px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex gap="15.24px" flex={1}>
          <Link href={id ? `/influencers/${username}` : '/'}>
            <Avatar
              height="52px"
              width="52px"
              src={profileImage || DefaultImage?.src}
              alt={username}
            />
          </Link>
          <div>
            <Text overflowWrap="anywhere" size="11.26px" text={accountName} />
            {username && (
              <Text overflowWrap="anywhere" weight={500} size="8.61px" text={`@${username}`} />
            )}
          </div>
        </Flex>
        <Flex>
          <UserProfileStats key="answer" label={t('Answers')} count={answers || 0} />
          <Divider
            orientation="vertical"
            borderColor={theme.colors.background.dark}
            opacity={1}
            height="13.31px"
            alignSelf="center"
            margin="0 19.62px"
          />
          <UserProfileStats key="good" label={t('GOOD')} count={good || 0} />
        </Flex>
      </Flex>
      <div className="mt-[8px]">
        {bio && (
          <ChakraText
            fontSize={13}
            fontWeight={500}
            whiteSpace="pre-line"
            lineHeight="24px"
            ref={measuredRef}
            {...(showSeeMoreButton && {
              noOfLines: show ? undefined : 3,
            })}
          >
            {bio}
          </ChakraText>
        )}
        {showSeeMoreButton && (
          <p
            color="background.darkGray"
            className="text-subtext mt-[15px] cursor-pointer !text-right text-[13px] !font-[500]"
            onClick={(e) => {
              e.stopPropagation();
              setShow(!show);
            }}
          >
            {!show ? t('See more') : t('Show less')}
          </p>
        )}
        <Center>
          <Link href={`/influencers/${username}`} passHref>
            <Button
              className="mt-[25px]"
              rounded
              variant="primary"
              height="24px"
              width="82.06%"
              fontSize="10px"
            >
              {t('See Influencer Page')}
            </Button>
          </Link>
        </Center>
      </div>
    </Box>
  );
};
