import { getSdk } from '@/generated/graphql';
import { GraphQLClient } from 'graphql-request';
import { supabase } from './supabaseClient';

export const getAccessToken = () => {
  const session = supabase.auth.session();

  return session?.access_token;
};

const gqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL!, {
  headers: {
    'Authorization': "Bearer " + process.env.NEXT_PUBLIC_JWT_TOKEN,
  },
});

export const gqlSdk = getSdk(gqlClient);
