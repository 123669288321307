import { Link } from '@chakra-ui/react';
import { theme } from '../../../constants';

export const Text10Gradient = ({
  text,
  mt,
  size,
  className,
  onClick,
  underline,
  href,
  align,
  isExternal,
}: {
  text: string;
  mt?: string;
  size?: string | number;
  className?: string;
  onClick?: () => void;
  underline?: boolean;
  href?: string;
  align?: 'left' | 'right';
  isExternal?: boolean;
}) => {
  return (
    <Link
      as="a"
      className={className}
      isExternal={isExternal}
      marginTop={mt}
      bgGradient={theme.gradient.primary}
      fontSize={size || 10}
      fontWeight={700}
      textAlign={align || 'center'}
      href={href}
      onClick={onClick}
      cursor="pointer"
      bgClip="text"
      textDecoration={underline ? 'underline' : 'none'}
      textDecorationColor={'red'}
    >
      {text}
    </Link>
  );
};
