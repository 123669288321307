import { Tabs as ChakraTabWrapper } from '@chakra-ui/react';
import { theme } from '../../../constants';

export interface IChakraTabWrapperProps {
  children: React.ReactNode;
  backgroundColor?: string;
  className?: string;
  onChange?: (i: number) => void;
  defaultIndex?: number;
  index?: number;
}

export const Tabs = ({
  children,
  backgroundColor = theme.colors.background.dark,
  className,
  onChange,
  defaultIndex = 0,
  index,
}: IChakraTabWrapperProps) => {
  return (
    <ChakraTabWrapper
      isLazy
      className={className}
      isFitted
      variant="unstyled"
      bg={backgroundColor}
      onChange={onChange}
      defaultIndex={defaultIndex}
      index={index}
    >
      {children}
    </ChakraTabWrapper>
  );
};
