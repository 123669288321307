import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { theme } from '../../../constants';
import { Avatar, Button } from '../../atoms';
import OfficialUser from '@/public/assets/icons/official-user.svg';
import { format } from 'date-fns';
import DefaultImage from '@/public/assets/default-user.png';
import Link from 'next/link';
interface Query {
  id: string;
  amount: number;
  accountName: string;
  username?: string;
  profileImage: string;
  question: string;
  hashtags: string[];
  date?: Date;
  isPurchased?: boolean;
  answererID?: string;
  questionType?: string;
  viewAnswer?: boolean;
  showAskedPrice?: boolean;
  answer?: Answer[];
  isUserLoggedIn: boolean;
}

interface Answer {
  content: string;
}

export interface QueryCardProps {
  query: Query;
  hasShadow?: boolean;
  mb?: string;
  isOfficial?: boolean;
}

export const QueryCard = ({ query, hasShadow, mb, isOfficial }: QueryCardProps) => {
  const { t } = useTranslation();
  const [isSmallerThan375] = useMediaQuery('(max-width: 375px)');
  const [isSmallerThan420] = useMediaQuery('(max-width: 420px)');

  const [show, setShow] = React.useState<boolean>(false);
  const [showSeeMoreButton, setShowSeeMoreButton] = useState(false);
  const [viewAnswerState, setViewAnswerState] = useState<boolean>();
  const [amountState, setAmountState] = useState(0);
  const {
    amount,
    accountName,
    profileImage,
    question,
    hashtags,
    date,
    isPurchased,
    username,
    answererID,
    questionType,
    viewAnswer,
    showAskedPrice,
    answer,
    isUserLoggedIn,
  } = query;

  useEffect(() => {
    setViewAnswerState(viewAnswer)
  }, [viewAnswer])

  useEffect(()=>{
    setAmountState(amount)
  }, [amount])

  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      if (node.clientHeight > 72) {
        setShowSeeMoreButton(true);
      } else {
        setShowSeeMoreButton(false);
      }
    }
  }, []);

  return (
    <Box
      boxShadow={hasShadow ? `0px 3px 15px rgba(0, 0, 0, 0.25)` : ''}
      bgColor={theme.colors.white}
      borderRadius="12px"
      padding="20px 27px"
      marginBottom={mb || '35px'}
    >
      <Flex direction="column">
        <Flex justifyContent={date ? 'space-between' : 'flex-end'}>
          {date && (
            <Text fontSize={13} fontWeight={700}>
              {format(new Date(date), 'yyyy/MM/dd ')}
            </Text>
          )}
          {!isOfficial && amountState > 0 && showAskedPrice && (
            <Text
              borderBottom={`3px solid`}
              fontSize={13}
              fontWeight={700}
              className="border-gradient-primary pb-[4px] text-right"
            >
              {t('asked.for', { amount: new Intl.NumberFormat('ja-JP').format(amount) })}
            </Text>
          )}
        </Flex>
        <Flex gap={17} alignItems="center" marginTop="12px">
          {isOfficial ? (
            <Box height="52px" width="52px">
              <OfficialUser />
            </Box>
          ) : (
            <Link href={answererID ? `/influencers/${username}` : '/'}>
              <Avatar
                height="52px"
                width="52px"
                src={profileImage || DefaultImage?.src}
                alt={username}
              />
            </Link>
          )}
          <Flex direction="column" gap="1px">
            <Link href={answererID ? `/influencers/${username}` : '/'}>
              <div>
                <Text overflowWrap="anywhere" fontSize={15} fontWeight={700}>
                  {accountName}
                </Text>
                {username && (
                  <Text overflowWrap="anywhere" fontSize={10} fontWeight={700}>
                    @{username}
                  </Text>
                )}
              </div>
            </Link>
            <Flex flexWrap={'wrap'} gap="7px">
              {hashtags?.map((hashtag: string, index: number) => {
                if (hashtag) {
                  return (
                    <Text
                      wordBreak="break-all"
                      overflowWrap="anywhere"
                      key={`${hashtag}-${index}`}
                      fontWeight={700}
                      fontSize={13}
                      bgGradient={theme.gradient.primary}
                      bgClip="text"
                      lineHeight="24px"
                    >
                      #{hashtag}
                    </Text>
                  );
                }
              })}
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" className="mt-[12px]">
          {isPurchased ? (
            <Text noOfLines={3} fontSize={13} fontWeight={500} whiteSpace="pre-line">
              {question}
            </Text>
          ) : (
            <Text
              fontSize={13}
              fontWeight={500}
              whiteSpace="pre-line"
              ref={measuredRef}
              {...(showSeeMoreButton && {
                noOfLines: show ? undefined : 3,
              })}
            >
              {question}
            </Text>
          )}
          {!isPurchased && showSeeMoreButton && (
            <p
              color="background.darkGray"
              className="text-subtext cursor-pointer pb-[15px] !text-right text-[13px] !font-[500]"
              onClick={(e) => {
                e.stopPropagation();
                setShow(!show);
              }}
            >
              {!show ? t('See more') : t('Show less')}
            </p>
          )}
        </Flex>
        {amountState === 0 && answer && answer[0]?.content.length >= 120  && (
          <>
            <Text
              fontSize={15}
              fontWeight={700}
              className="mt-[12px] mb-[12px]"
            >
              Answer
            </Text>
            <Text
              fontSize={13}
              fontWeight={500}
              whiteSpace="pre-line"
              noOfLines={3}
            >
              {answer[0].content}
            </Text>
          </> 
        )}
        {isPurchased ? (
          <Box marginTop="12px">
            <Link
              href={
                isOfficial
                  ? `/official-question-detail/${query?.id}?ofUser=${answererID}`
                  : `/question-detail/${query?.id}`
              }
            >
              <a>
                <Button width="full" height="50px" rounded variant="primary" size="large">
                  {t('View Question Details')}
                </Button>
              </a>
            </Link>
          </Box>
        ) : (
          <Box
            marginTop="12px"
            borderRadius={'7px'}
            padding={18}
            bgGradient={theme.gradient.primary}
            textColor={theme.white}
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              wordBreak="keep-all"
              wrap="wrap"
                {...(((!viewAnswerState && amountState !== 0) ? isSmallerThan420 : isSmallerThan375) && {
                direction: 'column',
                gap: '13px',
              })}
            >
                {amountState > 0 ? (
                <Flex direction="column" alignItems="center">
                  <Text
                    wordBreak="keep-all"
                    color={theme.colors.white}
                    fontSize={13}
                    fontWeight={700}
                    flex={1}
                  >
                      {t('view.fee', { amount: new Intl.NumberFormat('ja-JP').format(amount) })}
                  </Text>
                  {viewAnswer && (
                    <Text
                      wordBreak="keep-all"
                      color={theme.colors.white}
                      fontSize={13}
                      fontWeight={700}
                      flex={1}
                    >
                      {t('Purchased')}
                    </Text>
                  )}
                </Flex>
              ) : (
                <Text
                  wordBreak="keep-all"
                  color={theme.colors.white}
                  fontSize={13}
                  fontWeight={700}
                  flex={1}
                >
                  {t('View Fee')+ " | " +t('Free')}
                </Text>
              )}
              <Link
                  href={
                    isUserLoggedIn || amount!==0 ?
                      questionType === 'official' ?
                        `/official-question-detail/${query?.id}?ofUser=${answererID}`
                        : `/question-detail/${query?.id}`
                      : `/login`
                }
              >
                <a>
                  <Button rounded variant="white" height="34px">
                    <Text margin={0} fontWeight={700} fontSize={13} color="brand.300" flex={1}>
                        {viewAnswerState || amountState === 0 ? t('View Answer') : t('Buy and View')}
                    </Text>
                  </Button>
                </a>
              </Link>
            </Flex>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
