import React from 'react';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import Logo from '@packages/ui/assets/lp-logo.svg';
import { theme } from '../../../constants';
import { ButtonLink } from '../../atoms';
import { useTranslation } from 'next-i18next';

const footerLinks = [
  {
    text: 'Top',
    href: '/',
  },
  {
    text: 'FAQ',
    href: 'https://wesionary-team.notion.site/Question-Market-FAQ-b3d8e4cf77144660a9802d8ead319eea',
    isExternal: true,
  },
  {
    text: 'Contact',
    href: '/contact',
  },
  {
    text: 'Terms of Use',
    href: 'https://wesionary-team.notion.site/Question-Market-0e5a5c746b774e468e33d12c49883006',
    isExternal: true,
  },
  {
    text: 'Privacy Policy',
    href: 'https://wesionary-team.notion.site/Question-Market-86057abbb1104f628de15a47a5c5a3ce',
    isExternal: true,
  },
  {
    text: 'Commercial Law',
    href: 'https://wesionary-team.notion.site/Question-Market-1dcec253387a462db2eb1846ee4d81f5',
    isExternal: true,
  },
  {
    text: 'About Company',
    href: 'https://pyxis.company/company/',
    isExternal: true,
  },
];

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box bg={theme.colors.background.footer}>
      <Center className="flex flex-col" padding="56px 67px 0 67px">
        <Logo />
        <Text
          marginTop="18px"
          textAlign="center"
          whiteSpace="pre"
          fontSize="34px"
          fontWeight={700}
          letterSpacing="1px"
          lineHeight={'54px'}
          textShadow={`-2px 1px 0px rgba(255, 255, 255, 1)`}
          style={{
            WebkitTextStroke: `1px ${theme.colors.background.border}`,
            WebkitTextFillColor: `transparent`,
          }}
        >
          {t(`Influencers in\nthe Q&A world`)}
        </Text>
        <Flex direction={'column'} gap="18px" margin="20px 0 56px 0 ">
          {footerLinks.map(({ text, href, isExternal }) => (
            <ButtonLink key={text} href={href} isText isWhite isExternal={isExternal}>
              {t(text)}
            </ButtonLink>
          ))}
        </Flex>
      </Center>
      <Text
        textAlign="center"
        fontSize="10px"
        fontWeight={700}
        color={theme.colors.background.white}
        paddingBottom="28px"
      >
        Copyright © Question Market All Rights Reserved.
      </Text>
    </Box>
  );
};
