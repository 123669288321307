import S3 from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';

import fs from 'fs';
import { API } from '@/lib/API';

interface FileWithPath extends File {
  path: string;
}

const s3 = new S3({
  region: process.env.ASQ_AWS_REGION,
  apiVersion: '2006-03-01',
  credentials: {
    accessKeyId: process.env.ASQ_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.ASQ_AWS_SECRET_ACCESS_KEY!,
  },
});

const uploadImages = async (image: FileWithPath) => {
  const filestream = fs.createReadStream(image.path!);
  return await s3
    .upload({
      Bucket: process.env.ASQ_BUCKET_NAME!,
      Key: `asq-${uuidv4()}`,
      ContentType: image?.type,
      Body: filestream,
    })
    .promise();
};

const removeObject = async (key: string) => {
  s3.deleteObject(
    {
      Bucket: process.env.ASQ_BUCKET_NAME!,
      Key: key,
    },
    (err, data) => {
      console.log({ err, data });
    },
  );
};

const getSignedURL = async (imageKey: string): Promise<string> => {
  const response = await API.post('/api/get-signed-url', imageKey);
  return response?.data;
};

export { s3, uploadImages, getSignedURL, removeObject };
