import { Box, Flex, Image } from '@chakra-ui/react';
import { theme } from '@packages/ui/constants/theme';
import { useTranslation } from 'next-i18next';
import { Text } from '../../atoms';

export interface ShareQuestionProps {
  username?: string;
  accountName?: string;
  isAnswer?: boolean;
  amount?: string | string[];
  serviceCharge?: string | string[];
}

export const CompletionBox = ({
  username,
  accountName,
  isAnswer = false,
  amount,
  serviceCharge,
}: ShareQuestionProps) => {
  const { i18n, t } = useTranslation();
  return (
    <Flex
      marginTop="30px"
      padding="30px"
      bgGradient={theme.gradient.background}
      borderRadius="7px"
      position="relative"
      minHeight="187px"
      color="white"
      justifyContent="space-around"
      alignItems="center"
    >
      <Image
        className="absolute top-1.5 left-0"
        src="/assets/icons/share-question-left.svg"
        width="39px"
        height="118px"
        alt="logo"
      />
      {isAnswer ? (
        <Box>
          <Text size={15} text={t('Thanks for the answer!')} center color="white" />
          {amount && Number(amount) !== 0 && (
            <>
              <Text
                size={34}
                height="54px"
                text={`¥${Number(amount)?.toLocaleString() ?? 0}`}
                center
                color="white"
              />
              <Text
                size={10}
                height="24px"
                text={`(${t('Service Charge')} ¥${serviceCharge?.toLocaleString() ?? 0})`}
                center
                color="white"
              />
            </>
          )}
        </Box>
      ) : (
        <Box>
          {i18n.language === 'en' && (
            <Text size={15} text={'I asked a new question to'} center pre color="white" />
          )}
          {username && (
            <Text size={15} overflowWrap="anywhere" text={`@${username}`} center color="white" />
          )}
          {accountName && accountName !== username && (
            <Text size={15} overflowWrap="anywhere" text={accountName} center color="white" />
          )}
          {i18n.language === 'ja' && (
            <Text size={15} text={t('Asked a new question!')} center pre mb="7px" color="white" />
          )}
        </Box>
      )}

      <Image
        className="absolute bottom-1.5 right-0 rotate-180"
        src="/assets/icons/share-question-left.svg"
        width="39px"
        height="118px"
        alt="logo"
      />
    </Flex>
  );
};
