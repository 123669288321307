import React from 'react';
import { Switch as ChakraSwitch } from '@chakra-ui/react';
import { theme } from '../../../constants';

interface SwitchProps {
  allow: boolean;
  setAllow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Switch = ({ allow, setAllow }: SwitchProps) => {
  return (
    <ChakraSwitch
      sx={{
        '--switch-track-width': '4rem',
      }}
      css={{
        '& .chakra-switch__thumb': {
          background: allow ? theme.gradient.primary : theme.colors.background.gray,
          height: '22px',
          width: '22px',
          marginTop: '1px',
          zIndex: 1,
        },
        '& .chakra-switch__track': {
          width: '4rem',
          background: `${theme.colors.background.white} !important`,
          ':before': {
            content: allow ? "'ON'" : "'OFF'",
            position: 'absolute',
            top: '50%',
            left: allow ? '8px' : undefined,
            right: !allow ? '8px' : undefined,
            fontSize: '15px',
            color: 'black',
            fontWeight: 700,
          },
        },
      }}
      size="lg"
      onChange={() => {
        setAllow((prev) => !prev);
      }}
      isChecked={allow}
    />
  );
};
