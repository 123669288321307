import { Link as ChakraLink } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { theme } from '../../../constants';
import Link from 'next/link';

export interface MenuItemProps {
  title?: string;
  href?: string;
  isExternal?: boolean;
  isGradient?: boolean;
  action?: () => void;
}

export const MenuItem = ({ title, href, isExternal, isGradient, action }: MenuItemProps) => {
  const { t } = useTranslation();

  return (
    <Link href={href || "/"} >
      <ChakraLink
        href={href}
        isExternal={isExternal}
        fontWeight={700}
        fontSize="25px"
        lineHeight="54px"
        textDecoration="none !important"
        onClick={action ? () => action() : () => false}
        _focus={{ boxShadow: 'none !important' }}
        {...(isGradient ? { bgGradient: theme.gradient.primary, bgClip: 'text' } : {})}
      >
        {t(`${title}`)}
      </ChakraLink>
    </Link>
  );
};
