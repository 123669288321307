import React from 'react';
import { Checkbox as ChakraCheckbox, Text } from '@chakra-ui/react';
import { theme } from '../../../constants';

interface ICheckboxProps {
  name: string;
  gradientText: string;
  text: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
}

export const CheckboxInput = ({ gradientText, text, onChange, value }: ICheckboxProps) => {
  return (
    <ChakraCheckbox
      onChange={onChange}
      isChecked={value}
      css={{
        '& .chakra-checkbox__control': {
          backgroundColor: theme.colors.background.gray,
          border: 'none',
        },
        '& .chakra-checkbox__control[data-checked]': {
          backgroundImage: theme.gradient.primary,
          border: 'none',
        },
      }}
    >
      <Text
        as="span"
        fontSize="10px"
        fontWeight={700}
        bgGradient={theme.gradient.primary}
        bgClip="text"
      >
        {gradientText}
      </Text>
      <Text as="span" fontSize="10px" lineHeight="24px" fontWeight={700}>
        {text}
      </Text>
    </ChakraCheckbox>
  );
};
