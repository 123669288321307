import * as React from 'react';
import { Link as ChakraLink } from '@chakra-ui/react';
import { theme } from '@packages/ui/constants';
import Link from 'next/link';

export interface ButtonLinkProps {
  isText?: boolean;
  href?: string;
  children?: string | React.ReactNode;
  rounded?: boolean;
  width?: string;
  height?: string;
  variant?: 'primary' | 'instagram' | 'dark' | 'blue' | 'red' | 'gray' | 'white' | 'transparent';
  size?: 'large' | 'small';
  outlined?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  margin?: string;
  isWhite?: boolean;
  fontSize?: string;
  align?: 'left' | 'right';
  isExternal?: boolean;
  borderBottom?: string;
}

const gradientColors = ['primary', 'instagram'];

export const ButtonLink = ({
  isText,
  width,
  href,
  variant = 'dark',
  rounded,
  outlined,
  children,
  height,
  type,
  className,
  onClick,
  margin,
  isWhite,
  fontSize = '13px',
  align,
  isExternal,
  borderBottom,
}: ButtonLinkProps) => {
  const background = {
    ...theme.gradient,
    ...theme.colors.background,
  };

  if (isText) {
    return (
      <Link href={href || '/'} >
        <ChakraLink
          borderBottom={borderBottom}
          isExternal={isExternal}
          width={width}
          onClick={onClick}
          margin={margin}
          textAlign={align || 'center'}
          fontSize={fontSize}
          fontWeight={700}
          href={href}
          lineHeight="24px"
          textDecoration="underline"
          position="relative"
          className={className}
          color={isWhite ? theme.colors.background.white : theme.colors.background.dark}
          {...(!isWhite &&
            (gradientColors.includes(variant!)
              ? {
                bgGradient: background[variant!],
              }
              : { color: theme.colors.background.dark }))}
          {...(!isWhite && gradientColors.includes(variant!) && { bgClip: 'text' })}
        >
          {children}
        </ChakraLink>
      </Link>
    );
  }

  return (
    <Link href={href || '/'} >
      <ChakraLink
        isExternal={isExternal}
        type={type}
        className={`!h-0  ${outlined
          ? variant === 'white'
            ? '!border-dark !border bg-white'
            : '!border !border-white'
          : ''
          } ${className} flex items-center justify-center`}
        width={width}
        height={`${height} !important`}
        {...(gradientColors.includes(variant!)
          ? { bgGradient: background[variant!] }
          : { backgroundColor: background[variant!] })}
        _hover={{ opacity: 0.7 }}
        color={variant === 'white' ? '#000' : '#fff'}
        href={href}
        borderRadius={`${rounded ? '29.5px' : '7px'}`}
        fontSize={fontSize || '16px'}
        fontWeight={700}
        appearance="none"
      >
        {children}
      </ChakraLink>
    </Link>
  );
};
