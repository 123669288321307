import { Box, Flex, Link } from '@chakra-ui/react';
import { theme } from '../../../constants';
import { Button, Text, Avatar } from '../../atoms';
import { useTranslation } from 'next-i18next';
import DefaultImage from '@packages/ui/assets/default-user.png';
export interface BuyAnswerProps {
  price?: string | number;
  image: string | null | undefined;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  status?: string;
  buyable?: boolean
}

export const BuyAnswerCard = ({
  price,
  image,
  onClick,
  disabled,
  isLoading,
  status,
  buyable,
}: BuyAnswerProps) => {
  const { t } = useTranslation();

  return (
    <Box
      bgGradient={theme.gradient.primary}
      color={theme.colors.background.white}
      borderRadius="7px"
      padding="17px 0"
      marginTop="30px"
    >
      <Flex direction="column" justifyContent="center" alignItems="center" gap={2}>
        <Avatar height="55px" width="55px"
          src={image as string || DefaultImage?.src}
          alt='User'
        />
        <Flex width="full" direction="row" justifyContent="center" alignItems="center">
          <Text color="white" text={t('View Fee')} align="right" />
          <Text color="white" text={` ｜¥${price?.toLocaleString()}`} />
        </Flex>
        {(status?.toLocaleLowerCase() === 'answered' || buyable) && (
          <Button
            rounded
            variant="white"
            onClick={onClick}
            disabled={disabled}
            isLoading={isLoading}
          >
            {t('Buy and See the Answer')}
          </Button>
        )}
        <Link
          isExternal
          href="https://wesionary-team.notion.site/Question-Market-1dcec253387a462db2eb1846ee4d81f5"
          fontSize={10}
          color="white"
          textDecoration="underline"
          fontWeight={700}
        >
          {t('Commercial Law')}
        </Link>
      </Flex>
    </Box>
  );
};
