import { Tab as ChakraTab } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../../constants';

export interface IChakraTabProps {
  children: React.ReactNode | string;
  color?: string;
  fontSize?: string | number;
  lineHeight?: string | number;
  fontWeight?: 500 | 700;
  activeTextColor?: string;
  activeIndicatorColor?: string;
  activeIndicatorHeight?: string | number;
  activeIndicatorWidth?: string | number;
  positionTop?: string | number;
  className?: string;
  activeIndicatorType?: 'dot' | 'line';
  borderedBottom?: boolean;
}

export const Tab = ({
  children,
  color = theme.colors.background.placeholder,
  fontSize = '13px',
  lineHeight = '24px',
  fontWeight = 700,
  activeTextColor = theme.gradient.primary,
  activeIndicatorColor = 'white',
  activeIndicatorHeight = '10px',
  activeIndicatorWidth = '10px',
  className,
  activeIndicatorType = 'dot',
  borderedBottom,
}: IChakraTabProps) => {
  return (
    <ChakraTab
      borderBottom={borderedBottom ? '1px' : ''}
      className={className}
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      _focus={{
        outline: 'none',
      }}
      {...(activeIndicatorType === 'dot' && {
        width: 'fit-content',
        flex: 'unset',
        padding: '0',
        color: `${activeIndicatorColor} !important`,
      })}
      _selected={
        activeIndicatorType === 'dot'
          ? {
              bgGradient: activeTextColor,
              bgClip: 'text',
              position: 'relative',
              _after: {
                content: "''",
                position: 'absolute',
                height: activeIndicatorHeight,
                width: activeIndicatorWidth,
                borderRadius: '50%',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: activeIndicatorColor,
                top: 'calc(100% + 12px)',
                color: `${activeIndicatorColor} !important`,
              },
            }
          : {
              color,
              position: 'relative',
              _after: {
                content: "''",
                position: 'absolute',
                height: '3px',
                width: '100%',
                top: '97%',
                left: 0,
                right: 0,
                bgGradient: theme.gradient.primary,
              },
            }
      }
    >
      {children}
    </ChakraTab>
  );
};
