import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
} from '@chakra-ui/react';

import CloseIcon from '@packages/ui/assets/icons/close.svg';
import Logo from '@packages/ui/assets/logo.svg';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { theme } from '../../../constants';
import { ButtonLink, Text } from '../../atoms';
import { MenuItem, MenuItemProps } from '../../atoms/MenuItem';

const commonMenu = [
  {
    title: 'Contact',
    href: '/contact',
  },
  {
    title: 'FAQ',
    href: 'https://wesionary-team.notion.site/Question-Market-FAQ-b3d8e4cf77144660a9802d8ead319eea',
    isExternal: true,
  },
  {
    title: 'About Company',
    href: 'https://pyxis.company/company/',
    isExternal: true,
  },
  {
    title: 'Privacy Policy',
    href: 'https://wesionary-team.notion.site/Question-Market-86057abbb1104f628de15a47a5c5a3ce',
    isExternal: true,
  },
  {
    title: 'Commercial Law',
    href: 'https://wesionary-team.notion.site/Question-Market-1dcec253387a462db2eb1846ee4d81f5',
    isExternal: true,
  },
  {
    title: 'Terms of Use',
    href: 'https://wesionary-team.notion.site/Question-Market-0e5a5c746b774e468e33d12c49883006',
    isExternal: true,
  },
];

const unAuthMenu = [
  {
    title: 'Create an Account',
    href: '/register',
  },
  {
    title: 'Login',
    href: '/login',
  },
  {
    title: 'About Question Market',
    href: '/',
  },
];

const authMenu = [
  {
    title: 'Purchased Questions',
    href: '/purchased-queries',
  },
  {
    title: 'Created Questions',
    href: '/query-list',
  },
  {
    title: 'Profile Settings',
    href: '/profile-settings',
  },
  {
    title: 'Identity Settings',
    href: '/identity-information',
  },
  {
    title: 'Payment Settings',
    href: '/payment-settings',
  },
  {
    title: 'Bank Account Settings',
    href: '/bank-account-settings',
  },
];

const questionerMenu = {
  title: 'Apply as Answerer',
  href: '/answerer-application',
  isGradient: true,
};

const logoutMenu = {
  title: 'Logout',
  href: '#',
};

const answererMenu = [
  {
    title: 'My Page',
    href: '/my-page',
  },
  {
    title: 'Reviews',
    href: '/answerer/rating',
  },
  {
    title: 'Price Settings',
    href: '/price-settings',
  },
];
export interface HamburgerMenuProps {
  amount: number;
  isOpen: boolean;
  onClose: () => void;
  isAuthenticated?: boolean | null;
  isQuestioner?: boolean | null;
  signOut?: () => void;
  isEmailLogin: boolean;
}

export const HamburgerMenu = ({
  amount,
  isOpen,
  onClose,
  isAuthenticated,
  isQuestioner,
  signOut,
  isEmailLogin,
}: HamburgerMenuProps) => {
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState<MenuItemProps[]>([]);

  useEffect(() => {
    if (isEmailLogin) {
      const found = authMenu.find((menu) => menu.title === 'Change Email');
      if (!found) {
        authMenu.push({
          title: 'Change Email',
          href: '/change-email',
        });
      }
    }
  }, [isEmailLogin]);

  useEffect(() => {
    let menu = [];

    if (!isAuthenticated) {
      menu = unAuthMenu;
    } else if (isQuestioner) {
      menu = authMenu.concat(questionerMenu);
    } else {
      menu = answererMenu.concat(authMenu);
    }
    setMenuList(menu);
  }, [isAuthenticated, isQuestioner]);

  return isOpen ? (
    <Drawer isOpen={isOpen} onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent height="100%">
        <Flex alignItems="center" justifyContent="space-between" paddingX="25px" height="68px">
          <a href={'/'}>
            <Logo />
          </a>
          <CloseIcon onClick={onClose} />
        </Flex>
        <DrawerBody margin="0" paddingX="25px">
          {isAuthenticated && (
            <Box
              bgGradient={theme.gradient.primary}
              paddingX="31px"
              paddingY="17px"
              borderRadius="7px"
              textAlign="center"
              marginBottom="35px"
            >
              <Text text={t('Wallet Balance')} size={17} color="white" center />
              <Text
                text={`¥${amount?.toLocaleString() ?? 0}`}
                size={34}
                height="54px"
                color="white"
                center
              />
              <ButtonLink
                type="button"
                href="/withdrawal-request"
                width="100%"
                variant="transparent"
                outlined
                rounded
                height="50px"
                className="mt-[17px]"
                fontSize="14px"
              >
                {t('Apply for Withdrawal')}
              </ButtonLink>
            </Box>
          )}
          <Stack gap="18px">
            {menuList?.map((menu, idx) => (
              <MenuItem key={`${menu?.title}-${idx}`} {...menu} />
            ))}

            {isAuthenticated && (
              <svg
                width="337"
                height="1"
                viewBox="0 0 337 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line y1="0.5" x2="337" y2="0.5" stroke="#989898" strokeDasharray="4 4" />
              </svg>
            )}
            {commonMenu.map((menu, idx) => (
              <MenuItem key={`${menu?.title}-${idx}`} {...menu} />
            ))}
            {isAuthenticated && <MenuItem {...logoutMenu} action={signOut} />}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : null;
};
